import { actionCreator } from '@/utils/redux/action-creator';
import { TorusCred, TorusLoginType } from '@/model/torus/types';


const creator = actionCreator('TORUS');


export interface StartLoginReq {
  type: TorusLoginType
}

export interface StartTokenLoginReq {
  type: TorusLoginType,
  email: string,
  token: string
}


export const TorusAction = {

  StartLogin:       creator.asyncActionWithPayload<StartLoginReq, void>('START_LOGIN'),
  StartTokenLogin:  creator.syncActionWithPayload<StartTokenLoginReq>('START_TOKEN_LOGIN'),
  SetLoading:       creator.syncActionWithPayload<boolean>('SET_LOADING'),
  SetEntering:      creator.syncActionWithPayload<boolean>('SET_ENTERING'),

  RestoreCred:      creator.asyncAction('RESTORE_CRED'),
  RestoreCredAsync: creator.asyncAction('RESTORE_CRED_ASYNC'),

  SetLastRequestedLoginType: creator.syncActionWithPayload<TorusLoginType>('SET_LAST_REQUESTED_LOGIN_TYPE'),
  SetCred:        creator.syncActionWithPayload<TorusCred|null|undefined>('SET_CRED'),

  UpdateStateId:  creator.syncAction('UPDATE_STATE_ID'),
  LoginByTokenError:  creator.syncAction('LOGIN_BY_TOKEN_ERROR'),
};

