import { all, put } from 'redux-saga/effects';
import { combineReducers, Reducer } from 'redux';
import { setDefaultErrorHandler } from '@/utils/redux/action-creator';
import { Action } from '@/model/actions';
import * as global from './global';
import * as params from './params';
import * as torus from './torus';
import * as inner from './dapp/inner';
import * as wallet from './wallet';
import * as walletInit from './walletInit';
import * as walletProvider from './wallet/provider';
import * as walletSignPay from './wallet/signPay';
import * as walletRestore from './wallet/restore';
import * as onRamp from './onRamp';
import * as binanceWallet from './dapp/binanceWallet';
import * as metamask from './dapp/metamask';
import * as dappCommon from './dapp/common';
import * as moonPay from './onRamp/moonpay';
import * as instaramp from './onRamp/instaramp';
import * as auth from './auth';
import * as swap from './swap';
import * as rewards from './rewards';
import * as emailEnter from './emailEnter';
import { RootState } from './types';

export function* rootSaga(): Generator {
  yield all([
    global.sagas(),
    torus.sagas(),
    wallet.sagas(),
    walletInit.sagas(),
    walletRestore.sagas(),
    inner.sagas(),
    binanceWallet.sagas(),
    metamask.sagas(),
    dappCommon.sagas(),
    moonPay.sagas(),
    instaramp.sagas(),
    onRamp.sagas(),
    params.sagas(),
    auth.sagas(),
    swap.sagas(),
    rewards.sagas(),
    emailEnter.sagas(),
  ]);
}

export const rootReducer = (): Reducer<RootState> =>
  combineReducers<RootState>({
    global: global.reducers,
    params: params.reducers,
    torus: torus.reducers,
    wallet: wallet.reducers,
    init: walletInit.reducers,
    walletProvider: walletProvider.reducers,
    walletSignPay: walletSignPay.reducers,
    walletRestore: walletRestore.reducers,
    onRamp: onRamp.reducers,
    auth: auth.reducers,
    swap: swap.reducers,
    rewards: rewards.reducers,
    emailEnter: emailEnter.reducers,
  });


// eslint-disable-next-line func-names
setDefaultErrorHandler(function* (e: Error){

  yield put(Action.global.AddError({ messageId: 'common.error_info' }).pure);

});


export default {
  rootReducer,
};
