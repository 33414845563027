import { Network, unknownNetwork } from '@/model/common/Network';
import { Binance } from '@/api/blockchain/binance';
import { BaseBlockchainApi } from '@/api/blockchain/common';


export const BlockchainProvider = {

  getApi(network: Network): BaseBlockchainApi {

    if (network.includes('Binance'))
      return Binance;

    throw unknownNetwork(network);
  },
};
