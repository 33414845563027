import { Util } from '@/utils/util';
import { Binance, DappProcessingVersion, DataToSign } from '@/api/blockchain/binance';
import { Erc20ABI, ProcessingABI } from '@/api/abi';
import { TokenDef } from '@/model/common/Assets';
import { BinanceExternalDapp, DappConnectResp, DappListener } from '@/api/dapp/common';
import { WalletProviderStartError } from '@/model/wallet/provider/types';
import { Network, unknownNetwork } from '@/model/common/Network';
import { SignPayErrorCode } from '@/model/wallet/signPay/types';
import { isOutOfGasError, ProcessingVersion } from '@/api/blockchain/common';


const log = Util.getLog('binance_wallet');
const listeners: DappListener[] = [];

let checkEvents = false;
let curAddress: string|undefined;
let curNetwork: Network|undefined;


function getBinanceChain(){
  return (window as any).BinanceChain;
}

function startCheckEvents(){

  // start listen BinanceChain after success address init
  if( !curAddress || checkEvents){
    return;
  }
  checkEvents = true;


  log.info('start monitoring BinanceWallet state...');

  const BinanceChain = getBinanceChain();
  if( ! BinanceChain) {
    log.info('cannot find Binance wallet');
    return;
  }

  BinanceChain.on('accountsChanged', (accounts: string[]|undefined) => {

    const newAddress = accounts && accounts.length>0? accounts[0] : undefined;
    if(curAddress === newAddress)
      return;

    log.info('BinanceWallet state changed');
    curAddress = newAddress;

    listeners.forEach(listener => {
      if(listener.onAddressChanged)
        listener.onAddressChanged(newAddress);
    });
  });

  BinanceChain.on('chainChanged', (chainId: string) => {

    curNetwork = Binance.findNetwork(chainId);

    listeners.forEach(listener => {
      if(listener.onNetworkChanged)
        listener.onNetworkChanged(curNetwork);
    });
  });
}



export const BinanceWalletApi:BinanceExternalDapp = {

  processingVersion(network): ProcessingVersion {
    return DappProcessingVersion[network];
  },

  filterCurrentValidTokens(tokens: TokenDef[]): TokenDef[]{
    const network = BinanceWalletApi.getCurrentNetwork()
    return network? tokens.filter(token => token.network === network) : [];
  },

  hasExternalDapp(){
    return !! getBinanceChain();
  },

  getCurrentNetwork(){
    return curNetwork;
  },

  getAddress(): string|undefined {
    return curAddress;
  },

  getStartError(): WalletProviderStartError {
    return 'need_binance_smart_chain';
  },

  async connect(): Promise<DappConnectResp>{

    const BinanceChain = getBinanceChain();

    try {

      const accounts: string[]|undefined = await BinanceChain.request({ method: 'eth_requestAccounts' });
      curAddress = accounts && accounts.length > 0 ? accounts[0] : undefined;

    } catch (e: any){
      log.warn('cannot get accounts', e);
      return {address: undefined, network: undefined};
    }

    const {chainId} = BinanceChain;
    curNetwork = Binance.findNetwork(chainId);

    startCheckEvents();

    return {address: curAddress, network: curNetwork};
  },

  addListener(listener: DappListener){
    listeners.push(listener);
    startCheckEvents();
  },

  async approveForPay(
    tokenId: string,
    approveAmount: string,
    ownerAddress: string,
  ): Promise<string> {

    if(!curNetwork)
      throw unknownNetwork();

    const processingVersion = BinanceWalletApi.processingVersion(curNetwork);
    const {router} = Binance.processingInfo(curNetwork, processingVersion);

    const txParams: DataToSign = await Binance.prepareToSign(
      curNetwork,
      tokenId,
      Erc20ABI,
      ownerAddress,
      (methods) => methods.approve(router, approveAmount)
    );

    const txHash = await getBinanceChain().request({
      method: 'eth_sendTransaction',
      params: [txParams],
    });

    return txHash;
  },

  async pay(
    path: string[],
    amountInMax: string,
    amountOut: string,
    ownerAddress: string,
    toAddress: string,
    deadline: Date
  ): Promise<string> {

    if(!curNetwork)
      throw unknownNetwork();

    const processingVersion = BinanceWalletApi.processingVersion(curNetwork);
    const {processing} = Binance.processingInfo(curNetwork, processingVersion);
    const deadlineSec = Math.floor(deadline.getTime() / 1000);

    const txParams: DataToSign = await Binance.prepareToSign(
      curNetwork,
      processing,
      ProcessingABI[processingVersion],
      ownerAddress,
      (methods) => methods.payERC(toAddress, path, amountInMax, amountOut, deadlineSec)
    );

    const txHash = await getBinanceChain().request({
      method: 'eth_sendTransaction',
      params: [txParams],
    });

    return txHash;
  },

  getSignPayErrorCode(e: any): SignPayErrorCode {

    const msg = (e.error || e.message || e.toString()) as string;

    let code: SignPayErrorCode|undefined;
    if(msg === 'Rejected by user')
      code = 'SignCanceled';
    else if(msg === 'TRANSACTION_EXPIRATION_ERROR')
      code = 'TransactionExpired';
    else if(isOutOfGasError(msg))
      code = 'LowGasToInvoke'
    else
      code = 'InvalidWalletState';

    return code;
  }
}






