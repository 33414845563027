import { OneMin, OneSec } from '@/utils/date-util';
import { Network } from '@/model/common/Network';



export const DefaultWaitTxInfo = 2 * OneMin;
export const DefaultWaitOnZeroConfirmsTimeout = OneMin;
export const DefaultCheckStep = 3 * OneSec;
export const DefaultWaitConfirmations = 5;


export interface TxInfoReq {
  network: Network,
  txId: string,
  waitTimeout?: number,
  waitOnZeroConfirmsTimeout?: number,
  waitConfirmations?: number,
  checkStep?: number,
}

export interface TxInfo {
  confirmations: number,
  success: boolean,
  error?: string,
  isTimeout?: boolean,
}

export interface ContractTxInfo extends TxInfo {
  contractAddress?: string,
}

export const ZeroAddress = '0x0000000000000000000000000000000000000000';


/**
 * Same addresses can be in different cases:
 * ```
 * example:
 * 0xd570E1ee81A8cA94008e1cF75C72B5E7A7b83Bc5
 * 0xd570e1ee81a8ca94008e1cf75c72b5e7a7b83bc5
 * ```
 */
export function isSameAddress(addressA: string|undefined, addressB: string|undefined): boolean {

  if(addressA === undefined && addressB === undefined)
    return true;

  return !!addressA && !!addressB
    && addressA.toLowerCase() === addressB.toLowerCase();
}
