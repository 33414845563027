import { actionCreator } from '@/utils/redux/action-creator';
import { AuthData } from '@/model/auth/types';


const creator = actionCreator('AUTH');


export const AuthAction = {

  support: {
    SetAuth:  creator.syncActionWithPayload<AuthData>('SUPPORT_SET_AUTH'),
  }
};

