import { actionCreator } from '@/utils/redux/action-creator';
import { WalletProviderStartError, WalletProviderType } from '@/model/wallet/provider/types';
import { BaseDapp } from '@/api/dapp/common';
import { TokenDef } from '@/model/common/Assets';
import { SetSelectedTokenReq } from '@/model/wallet/actions';


const creator = actionCreator('WALLET_PROVIDER');


export const WalletProviderAction = {

  UpdateStateId:  creator.syncAction('UPDATE_STATE_ID'),
  SetType:        creator.syncActionWithPayload<WalletProviderType>('SET_TYPE'),
  SetDapp:        creator.syncActionWithPayload<BaseDapp>('SET_DAPP'),
  SetStartError:  creator.syncActionWithPayload<WalletProviderStartError|undefined>('SET_START_ERROR'),
  SetStarting:    creator.syncActionWithPayload<boolean>('SET_STARTING'),
  SetPayTokens:   creator.syncActionWithPayload<TokenDef[]>('SET_PAY_TOKENS'),
  SelectTokenToPay: creator.syncActionWithPayload<TokenDef>('SELECT_TOKEN_TO_PAY'),
  SetSelectedToken: creator.syncActionWithPayload<SetSelectedTokenReq|undefined>('SET_SELECTED_TOKEN'),
};

