import { put, takeEvery } from 'redux-saga/effects';
import { withErrorHandler } from '@/utils/redux/action-creator';
import { Action } from '@/model/actions';
import { Goal, Metrika } from '@/api/metrika';


export function* processBalanceMetrics(prevBalance: number|undefined, curBalance: number, targetBalance: number){

  if(!prevBalance){
    processCurBalance(curBalance, targetBalance);
  }
  else {

    if(prevBalance !== curBalance){
      processCurBalance(curBalance, targetBalance);
    }

    // got deposit
    if(prevBalance < curBalance){
      yield put(Action.wallet.GotDeposit().pure);
      Metrika.reach(Goal.wallet.GotDeposit);
    }
  }
}


function processCurBalance(balance: number, targetBalance: number){
  if(balance === targetBalance){
    Metrika.reach(Goal.wallet.TargetBalance);
  }
  else if(balance > targetBalance){
    Metrika.reach(Goal.wallet.BigBalance);
  }
  else {
    Metrika.reach(Goal.wallet.SmallBalance);
  }
}

function onWalletLogout(){
  Metrika.reach(Goal.wallet.Logout);
}

export default function* rootSaga(): Generator {

  yield takeEvery(Action.wallet.Logout.type,
    withErrorHandler(
      onWalletLogout
    ));

}
