import React, { useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Action } from '@/model/actions';
import { Util } from '@/utils/util';
import { RootState } from '@/model/types';
import { initTorus } from '@/api/torus';


const withAppState = (Component: React.FC) => (props: any) => {

  const dispatch = useDispatch();
  const cred = useSelector((state: RootState) => state.torus.cred);

  useLayoutEffect(()=>{

    initTorus().catch(Util.onErr);

    // restore any extra data from local storage
    Action.global.RestorePageData()(dispatch);

  }, [dispatch]);

  useLayoutEffect(()=>{
    if(cred === undefined){
      Action.torus.RestoreCredAsync()(dispatch);
    }
  }, [cred, dispatch]);

  return (
    <>
      <Component {...props} />
    </>
  );
}

export default withAppState;
