
export const Color = {
  base: {
    White: '#FFF',
  },
  text: {
    Main: '#1e293b',
    Second: '#98A4B6',
    Third: '#2563EA',
    Fourth: '#1D4ED7',
    Fifth: '#F9FAFC',
    Success: '#17A34A',
  },
  border: {
    Main: '#CBD5E1',
    Solid: 'rgba(0, 0, 0, 0.23)',
    Third: '#EBF0F5',
  },
  back: {
    Body: '#F1F4F9',
    Second: '#F7F8FA',
    Third: '#EFF6FF',
    Fourth: '#94A3B8',
    Fifth: '#29303E',
  },
  selected: {
    Gray1: '#F2F3F4',
    Blue1: '#EDF0F3',
    White1: 'rgba(0, 0, 0, 0.04)',
  },
  link: {
    Main: '#2563EA',
  },
  warning: {
    Text: '#D87706',
    Back: '#FEFBEA',
  },
  error: {
    Text: '#F22462',
    Text2: '#DC2625',
  }
}

export const Style = {
  pack: {
    Title: {
      fontSize: '24px',
      fontWeight: 400,
      color: Color.text.Main,
      lineHeight: '40px',
    },
  },
  border: {
    Main: `1px solid ${Color.border.Main}`,
    Solid: `1px solid ${Color.border.Solid}`,
    Third: '1px solid #E1E8F0',
  }
}

export function important(val: string): string {
  return val.endsWith('!important')? val : `${val} !important`;
}

export const ClassName = {
  SkipOpacity: 'skip-opacity'
}
