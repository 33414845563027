
import * as lscache from 'lscache';
import { Util } from '@/utils/util';

const log = Util.getLog('storage');
let updateTs = false;

export const LocalStorage = {

  getObject(key: string): any {
    try {
      const str = this.getItem(key);
      return str? JSON.parse(str) : null;
    } catch (e: any){
      return null;
    }
  },

  setObject(key: string, obj: any){
    try {
      const str = obj? Util.toJson(obj) : obj;
      this.setItem(key, str);
    } catch (e: any){
      // skip
    }
  },

  getItem(key: string): string | null {
    try {
      return localStorage.getItem(key);
    }
    catch (e: any){
      log.warn('cannot localStorage.getItem', e.message);
      return null;
    }
  },

  setItem(key: string, value: string){
    try {
      localStorage.setItem(key, value);
      setUpdateTs();
    }
    catch (e: any){
      log.warn('cannot localStorage.setItem', e.message);
    }
  },

  removeItem(key: string) {
    try {
      localStorage.removeItem(key);
      setUpdateTs();
    }
    catch (e: any){
      log.warn('cannot localStorage.removeItem', e.message);
    }
  },

  setEnableUpdateTimestamp(val: boolean){
    updateTs = val;
  },

  getLastUpdateTime(): number {
    try {
      return getUpdateTs();
    } catch (e: any){
      log.warn('cannot getLastUpdateTime', e.message);
      return 0;
    }
  }
}

function setUpdateTs(){
  if(updateTs) {
    localStorage.setItem('ts', Date.now().toString());
  }
}

function getUpdateTs(): number {
  return parseInt(localStorage.getItem('ts') || '0', 10);
}


export const SessionStorage = {

  getObject(key: string): any {
    try {
      const str = this.getItem(key);
      return str? JSON.parse(str) : null;
    } catch (e: any){
      return null;
    }
  },

  setObject(key: string, obj: any){
    try {
      const str = obj? Util.toJson(obj) : obj;
      this.setItem(key, str);
    } catch (e: any){
      // skip
    }
  },

  getItem(key: string): string | null {
    try {
      return sessionStorage.getItem(key);
    }
    catch (e: any){
      log.warn('cannot sessionStorage.getItem', e.message);
      return null;
    }
  },

  setItem(key: string, value: string){
    try {
      sessionStorage.setItem(key, value);
    }
    catch (e: any){
      log.warn('cannot sessionStorage.setItem', e.message);
    }
  },

  removeItem(key: string) {
    try {
      sessionStorage.removeItem(key);
    }
    catch (e: any){
      log.warn('cannot sessionStorage.removeItem', e.message);
    }
  }
}

export const LSCache = {

  set(key: string, value: any, time?: number): boolean {
    try {

      if( ! Util.isEmpty(value))
        lscache.set(key, value, time);
      else
        lscache.remove(key);

      setUpdateTs();
      return true;
    }
    catch (e: any){
      log.warn('cannot lscache.set', e.message);
      return false;
    }
  },

  remove(key: string){
    LSCache.set(key, undefined);
    setUpdateTs();
  },

  get(key: string): any {
    try {
      return lscache.get(key);
    }
    catch (e: any){
      log.warn('cannot lscache.get', e.message);
      return undefined;
    }
  },

  refreshTll(key: string, time?: number){
    try {
      const value = lscache.get(key);
      if(value !== undefined){
        lscache.set(key, value, time);
        setUpdateTs();
      }
    }
    catch (e: any){
      log.warn('cannot refreshTll', e.message);
    }
  },

  setBucket(bucket: string){
    try {
      lscache.setBucket(bucket);
    }
    catch (e: any){
      log.warn('cannot lscache.setBucket', e.message);
    }
  },

  setExpiryMilliseconds(milliseconds: number){
    try {
      lscache.setExpiryMilliseconds(milliseconds);
    }
    catch (e: any){
      log.warn('cannot lscache.setExpiryMilliseconds', e.message);
    }
  },

  flushExpired(){
    try {
      lscache.flushExpired();
    }
    catch (e: any){
      log.warn('cannot lscache.flushExpired', e.message);
    }
  },
}



