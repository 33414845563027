import { put, select, takeEvery } from 'redux-saga/effects';
import { withErrorHandler } from '@/utils/redux/action-creator';
import { Action } from '@/model/actions';
import { RootState } from '@/model/types';
import { restoreRestoreData, storeRestoreData } from '@/api/wallet';
import { initialState, RestoreData } from '@/model/wallet/restore/types';
import { Util } from '@/utils/util';


function* saveRestoreData(){

  const rootState = (yield select((state: RootState) => state)) as RootState;
  const data = rootState.walletRestore.data || {};

  storeRestoreData(data);
}

function* loadRestoreData(){

  const rootState = (yield select((state: RootState) => state)) as RootState;
  const demoMode = Util.parseBool(rootState.params['demo-mode']);

  const initData = initialState.data;

  let data:RestoreData = restoreRestoreData() || initData;

  // reset old torus login on demo mode
  if(data.provider === 'inner' && demoMode){
    data = initData;
  }
  // reset old demo wallet access on non-demo mode
  else if(data.provider === 'demoWallet' && ! demoMode){
    data = initData;
  }

  yield put(Action.walletRestore.SetData({
    ...data,
    provider: data.provider || initData.provider
  }).pure);
}

function* onWalletLogout(){
  yield put(Action.walletRestore.SetSuccessProvider('none').pure);
  yield saveRestoreData();
}


export default function* rootSaga(): Generator {

  yield takeEvery(Action.walletRestore.SaveData.type,
    withErrorHandler(
      saveRestoreData
    ));

  yield takeEvery(Action.walletRestore.LoadData.type,
    withErrorHandler(
      loadRestoreData
    ));

  yield takeEvery(Action.wallet.Logout.type,
    withErrorHandler(
      onWalletLogout
    ));
}
