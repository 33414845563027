import { Typography } from '@material-ui/core';
import React from 'react';
import { makeCommonStyles, Space } from '@/components';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import { Color } from '@/components/Style';
import { copyAddressToClipboard } from '@/components/wallet';
import { ReactComponent as CopyIcon } from '@/assets/icons/copy.svg';
import { useI18n } from '@/i18n';
import clsx from 'clsx';

const styles = makeStyles((theme: Theme) => {

  return {
    root: {
      backgroundColor: Color.back.Body,
      padding: '12px 8px',
      cursor: 'pointer',
      borderRadius: '8px',
      alignItems: 'center',
      '&:hover': {
        backgroundColor: Color.selected.Blue1,
      }
    },
    text: {
      color: Color.text.Third,
      flexGrow: 1,
      flexShrink: 1,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      fontSize: '13px',
      lineHeight: '16px',
    },
    icon: {
      margin: '0 4px'
    }
  };
});

const commonStyles = makeCommonStyles();


export interface AddressBlockProps {
  address: string
}

export function AddressBlock(
  {
    address
  }: AddressBlockProps
){

  const i18n = useI18n();
  const classes = styles();
  const commonClasses = commonStyles();

  return (
    <Space
      direction="horizontal"
      className={classes.root}
      title={i18n.m('wallet.address_do_copy')}
      data-test="copy-address"
      size={0.5}
      onClick={()=>{
        return copyAddressToClipboard(address);
      }}
    >

      <Typography data-test="address-short-view" className={classes.text}>
        {address}
      </Typography>

      <CopyIcon className={clsx(commonClasses.icon16, classes.icon)}/>

    </Space>
  )
}
