import { actionCreator } from '@/utils/redux/action-creator';
import { SignPayErrorCode, SignPayState } from '@/model/wallet/signPay/types';


const creator = actionCreator('WALLET_SIGN_PAY');


export const WalletSignPayAction = {

  Reset:        creator.syncAction('RESET'),
  SetState:     creator.syncActionWithPayload<SignPayState>('SET_STATE'),
  SetErrorCode: creator.syncActionWithPayload<SignPayErrorCode|undefined>('SET_ERROR_CODE'),
  SetDataId:    creator.syncActionWithPayload<number>('SET_DATA_ID'),
  SetSignTxId:  creator.syncActionWithPayload<string>('SET_SIGN_TX_ID'),
  SetPayTxId:   creator.syncActionWithPayload<string>('SET_PAY_TX_ID'),
};

