import React, { useEffect, useState } from 'react';

import * as locales from '@material-ui/core/locale';
import { CssBaseline } from '@material-ui/core';
import { Theme, createTheme } from '@material-ui/core/styles';
import { MuiPickersOverrides } from '@material-ui/pickers/typings/overrides';
import { AlertClassKey, AlertTitleClassKey } from '@material-ui/lab';
import { ThemeProvider } from '@material-ui/styles';

import { useSelector } from 'react-redux';
import { green } from '@material-ui/core/colors';
import { RootState } from '@/model/types';
import { AppParams } from '@/model/params/types';
import { Util } from '@/utils/util';
import { Color, important } from '@/components/Style';
import { DefLang, Lang } from '@/model/common/Lang';






const tryLocale = (locale: string): [string, Record<string, string>] | undefined => {
  const foundLocale = (locales as { [k in string]: {} })[locale.replace('-', '')];
  return foundLocale && [locale, foundLocale];
};

const parseLocale = (locale: string): [string, Record<string, string>] =>
  tryLocale(locale) as [string, Record<string, string>];

declare module '@material-ui/core/styles/overrides' {
  export interface ComponentNameToClassKey extends MuiPickersOverrides {
    MuiAlert: AlertClassKey;
    MuiAlertTitle: AlertTitleClassKey;
  }
}



export interface ThemeValues {
  mainColor: string,
  backColor: string,
  font: string,
}

const defaultThemeValues:ThemeValues = {
  mainColor: '#00C489',
  backColor: Color.text.Main,
  font: 'Roboto'
}

let themeValues = defaultThemeValues;

export function getThemeValues() {
  return themeValues;
}

function initThemeValues(params: AppParams):ThemeValues{
  return {
    mainColor: toColor(defaultThemeValues.mainColor),
    backColor: toColor(defaultThemeValues.backColor),
    font: defaultThemeValues.font,
  }
}

function toColor(val?:string):string {
  if( ! val)
    return '';

  if(val.startsWith('#')
      || val.startsWith('rgb'))
    return val;

  return Util.isHexString(val)? `#${val}` : val;
}

const crateTheme = (locale: string): Theme => {

  const values = themeValues;

  const activeElementBackground = '#F2F2F7';
  const errorColor = Color.error.Text;
  const errorBackgroundColor = '#571741';
  const buttonBackgroundColor = '#E9E9F2';
  const bodyColor = '#141233';
  const inputLabelColor = '#8D8EA6';
  const isOtherFont = defaultThemeValues.font !== values.font;

  return createTheme({
      overrides: {
        MuiTypography: {
          colorTextPrimary: {
            color: Color.text.Main,
          },
          colorTextSecondary: {
            color: Color.text.Second
          }
        },
        MuiLinearProgress: {
          colorPrimary: {
            backgroundColor: '#C0BED8',
          },
          barColorPrimary: {
            backgroundColor: '#302E4C',
          },
        },
        MuiAlert: {
          root: {
            borderRadius: '12px',
          },
          filledSuccess: {
            backgroundColor: values.mainColor,
          },
          message: {
            fontWeight: 400,
            fontSize: '15px',
            lineHeight: '18px',
            paddingBottom: '20px',
          },
          standardWarning: {
            '& .MuiAlertTitle-root': {
              color: important('inherit')
            }
          }
        },
        MuiAlertTitle: {
          root: {
            color: 'white',
            fontWeight: 500,
            fontSize: '18px',
            lineHeight: '24px',
          },
        },
        MuiDivider: {
          light: {
            backgroundColor: '#585673',
          },
        },
        MuiFormHelperText: {
          root: {
            '&$error': {
              margin: 0,
              marginTop: '16px',
              fontSize: '15px',
              lineHeight: '19px',
            },
          },
        },
        MuiInputLabel: {
          filled: {
            color: inputLabelColor,
            '&$error': {
              color: errorColor,
            },
          },
        },
        MuiFormLabel: {
          filled: {
            color: inputLabelColor,
            '&$error': {
              color: errorColor,
            },
          },
        },
        MuiInput: {
          root: {
            backgroundColor: Color.back.Body,
            borderRadius: '8px',
            '&$error': {
              borderColor: errorColor,
              borderWidth: '1px',
              borderStyle: 'solid',
              '& > input': {
                padding: '15px',
              },
              '& > .MuiInputAdornment-root > button': {
                color: errorColor,
              },
            },
            '& > .MuiInputAdornment-root > button': {
              color: bodyColor,
            },
          },
          input: {
            padding: '16px',
            color: bodyColor,
            lineHeight: '24px',
            height: '24px',
            fontSize: '16px',
            '&.Mui-disabled': {
              color: important('rgba(0, 0, 0, 0.38)')
            }
          },
          underline: {
            '&::before':{
              display: 'none'
            },
            '&::after':{
              display: 'none'
            }
          }
        },
        MuiFormControl: {
          root: {
            '& .MuiFormHelperText-root.Mui-error': {
              fontSize: '13px',
              marginTop: '8px',
            }
          }
        },
        MuiFilledInput: {
          root: {
            backgroundColor: activeElementBackground,
            borderRadius: '12px',
            borderTopRightRadius: '12px',
            borderTopLeftRadius: '12px',
            '&$focused': {
              backgroundColor: activeElementBackground,
            },
            '&:hover': {
              backgroundColor: activeElementBackground,
            },
            '&$error': {
              '& > .MuiInputAdornment-root > button': {
                color: errorColor,
              },
            },
            '& > .MuiInputAdornment-root > button': {
              color: bodyColor,
            },
          },
          input: {
            color: bodyColor,
            lineHeight: '24px',
          },
        },
        MuiCheckbox: {
          root: {
            color: buttonBackgroundColor,
            '&$checked': {
              color: values.mainColor,
            },
            '& .MuiIconButton-label': {
              position: 'relative',
              zIndex: 0,
            },
            '&:not($checked) .MuiIconButton-label:after': {
              content: '""',
              left: 4,
              top: 4,
              height: 15,
              width: 15,
              position: 'absolute',
              backgroundColor: 'white',
              zIndex: -1,
            },
          },
          colorSecondary: {
            '&$checked': {
              color: values.mainColor,
            },
          },
          colorPrimary: {
            '&$checked': {
              color: values.mainColor,
            },
          },
        },
        MuiLink: {
          root: {
            color: Color.link.Main,
            fontSize: '14px',
          },
        },
        MuiPickersToolbar: {
          toolbar: {
            backgroundColor: '#FFFFFF',
          },
        },
        MuiPickersDay: {
          daySelected: {
            color: '#FFFFFF',
          },
        },
        MuiButtonBase: {
          root: {
            height: '48px',
            borderRadius: important('8px'),
            borderColor: important(Color.border.Main),
            '&.Mui-disabled:not(.skip-opacity) img:not(.skip-opacity)': {
              opacity: '0.5'
            },
            '&.Mui-disabled:not(.skip-opacity) svg:not(.skip-opacity)': {
              opacity: '0.5'
            }
          }
        },
        MuiButton: {
          outlined: {
            '&:hover': {
              backgroundColor: important(Color.back.Second)
            }
          },
          label: {
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '20px',
          },
          containedPrimary: {
            backgroundColor: important(Color.back.Fifth),
            color: important(Color.text.Fifth),
            boxShadow: 'none',
            '&.Mui-disabled': {
              backgroundColor: important(Color.back.Fourth),
              color: important(Color.base.White)
            },
            '&.Mui-disabled.skip-opacity': {
              backgroundColor: important(Color.back.Fifth),
              color: important(Color.text.Fifth)
            }
          }
        },
        MuiListItem: {
          root: {
            borderRadius: important('0px'),
          }
        },
        MuiIconButton: {
          root: {
            height: 'initial',
            '&:hover': {
              backgroundColor: 'inherit',
            }
          }
        },
        MuiChip: {
          root: {
            borderRadius: '4px',
          },
          outlinedPrimary: {
            color: Color.text.Third,
            borderColor: Color.text.Third,
          }
        },
        MuiFormControlLabel: {
          root: {
            '& .MuiSwitch-root': {
              marginRight: '16px'
            }
          }
        },
        MuiSwitch: {
          colorPrimary: {
            '&.Mui-checked:not(.Mui-disabled)': {
              color: Color.text.Third
            },
            '&.Mui-checked + .MuiSwitch-track': {
              backgroundColor: Color.border.Main
            }
          }
        }
      },
      palette: {
        secondary: green,
        error: {
          light: errorBackgroundColor,
          contrastText: errorColor,
          main: errorColor,
          dark: errorBackgroundColor,
        },
      },
      typography: {
        fontFamily: `${values.font}${isOtherFont ? `, ${defaultThemeValues.font}` : ''}, Arial`,
        h3: {
          fontStyle: 'normal',
          fontWeight: 900,
          fontSize: '32px',
          lineHeight: '37px',
          textAlign: 'center',
        },
        h4: {
          fontStyle: 'normal',
          fontWeight: 'bold',
          fontSize: '20px',
          lineHeight: '22px',
          textAlign: 'left',
        },
        h5: {
          fontStyle: 'normal',
          fontWeight: 500,
          fontSize: '20px',
          lineHeight: '26px',
          textAlign: 'left',
        },
        body1: {
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '15px',
          lineHeight: '20px',
          letterSpacing: '0em',
          color: bodyColor,
        },
        body2: {
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '15px',
          lineHeight: '20px',
          color: '#FFFFFF',
        },
        button: {
          width: '100%',
          fontStyle: 'normal',
          fontWeight: 'normal',
          textTransform: 'inherit',
        },
        overline: {
          color: values.mainColor,
        },
      },
    },
    parseLocale(locale),
  );
};

const withTheme = (Component: React.FunctionComponent) => ({
  locale = DefLang,
  ...props
}: {
  locale?: Lang;
  [_: string]: unknown;
}): JSX.Element => {

  const params = useSelector((state: RootState) => state.params);
  const paramsLoaded = params.loaded;
  const [theme, setTheme] = useState<Theme>();

  useEffect(()=>{
    if(paramsLoaded && !theme){

      // init all values
      themeValues = initThemeValues(params);

      // make theme
      const themeInst = crateTheme(locale);
      setTheme(themeInst);
    }
  }, [paramsLoaded, theme, params, locale]);

  // wait for params
  if(!paramsLoaded || !theme){
      return (
        <>
        </>
      );
  }

  const themeVal: any = theme;
  return (
    // MuiThemeProvider makes the theme available down the React tree
    // thanks to React context.
    <ThemeProvider theme={themeVal}>
      {/* Reboot kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <Component {...props} />
    </ThemeProvider>)
}

export default withTheme;
