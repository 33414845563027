import { createReducer } from '@/utils/redux/reducer-util';
import { Action } from '@/model/actions';
import { ActionWithPayload } from '@/utils/redux/action-creator';
import { SetParamPayload } from '@/model/params/actions';
import { AppParams as S, initialState } from '@/model/params/types';


export const reducers = createReducer<S>(initialState, {

  [Action.params.SetAll.type](
    state: S, {payload}: ActionWithPayload<Record<string, any>>
  ): S {
    return {
      ...state,
      ...payload
    } as any;
  },

  [Action.params.SetParam.type](
    state: S, {payload}: ActionWithPayload<SetParamPayload>
  ): S {
    return {
      ...state,
      [payload.key]: payload.value,
    } as any;
  }
});

export default reducers;
