import { createReducer, createStateHandlers } from '@/utils/redux/reducer-util';
import { rewardsInitialState, RewardsState as S } from '@/model/rewards/types';
import { Action } from '@/model/actions';

const { p2f } = createStateHandlers<S>();

export const reducers = createReducer<S>(rewardsInitialState, {

  // p2f<S['']>(''),

  [Action.rewards.support.SetProcessingReward.type]: p2f<S['processingRewardId']>('processingRewardId'),
  [Action.rewards.support.SetList.type]: p2f<S['list']>('list'),

  [Action.rewards.Reset.type](state: S): S {
    return {
      ...rewardsInitialState,
    };
  },

});

export default reducers;
