
import { v4 as v4uuid } from 'uuid';
import * as Sentry from '@sentry/react';
import { Severity } from '@sentry/react';

export const Util = {

    /** support for ...msgs params */
    getLog(loggerName: string){
        return logWrapper(loggerName);
    },

    isEmpty(obj: any){
        return obj === null || obj === undefined;
    },

    isBlank(str: string|undefined|null ) {
        return str === null
          || str === undefined
          || str.length === 0;
    },

    isObject(obj: any){
        return obj !== null && typeof obj === 'object';
    },

    isArray(obj: any){
        return Array.isArray(obj);
    },

    isString(obj: any){
        return ! this.isEmpty(obj) && typeof obj === 'string';
    },

    lastFrom(list?: Array<any>){
        return list && list.length > 0? list[list.length-1] : undefined;
    },


    toJson(obj: any, pretty = false):string {
        return pretty?
            JSON.stringify(obj, null, 2)
            : JSON.stringify(obj);
    },

    toJsonPretty(obj: any):string {
        return Util.toJson(obj, true);
    },

    parseBool(obj: any, defVal?: boolean) : boolean {
        const strVal = obj?.toString().toLowerCase();
        if( this.isEmpty(strVal))
            return defVal || false;
        return strVal === 'true';
    },

    onErr(e: any) {
        // eslint-disable-next-line no-alert
        log.error('async error', e);
    },

    async timeout(ms: number, ...result: any[]): Promise<any> {
        return new Promise(resolve => setTimeout(resolve, ms, ...result));
    },

    hashCode(str: string): string {
        return str.split('').reduce((prevHash, currVal) =>
          // eslint-disable-next-line no-bitwise
          (((prevHash << 5) - prevHash) + currVal.charCodeAt(0))|0, 0).toString(16);
    },

    uuid(): string {
        return v4uuid();
    },

    isHexString(val: string) {
        return /^[0-9a-fA-F]+$/.test(val);
    },

    parseUrl(val: string): URL|null {
        try {
            return val? new URL(val): null;
        } catch (e: any) {
            return null;
        }
    },

    parseInt(obj: any, defaultVal?: number): number|undefined{

        if(this.isEmpty(obj)){
            return defaultVal;
        }

        try {
            const out = parseInt(obj, 10);
            return isNaN(out)? defaultVal : out;
        }catch(e: any){
            return defaultVal;
        }
    },

    parseFloat(obj: any, defaultVal?:number): number|undefined{

        if(this.isEmpty(obj))
            return defaultVal;

        try {
            const out = parseFloat(obj);
            return isNaN(out)? defaultVal : out;
        }catch(e: any){
            return defaultVal;
        }
    },

    ascSort(a: any, b: any): number {
        // eslint-disable-next-line no-nested-ternary
        return a > b ? 1 : (a < b ? -1 : 0);
    },

    descSort(a: any, b: any): number {
        return -1 * Util.ascSort(a, b);
    },

    parseStringType<T extends string>(checkMap: Record<T, any>, value: any, defaultVal: T){
        return checkMap[value as T]? value as T : defaultVal;
    },

    /** Returns an integer random number between min (included) and max (included) */
    randomInt(min: number, max: number){
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }
};


const log = Util.getLog('Util');

function logWrapper(loggerName: string) {

    const prefix = `[${loggerName}] `;

    return {
        info(...msgs: any[]){
            console.info(prefix, ...msgs);
        },
        log(...msgs: any[]){
            console.log(prefix, ...msgs);
        },
        warn(...msgs: any[]){
            console.warn(prefix, ...msgs);
        },
        error(...msgs: any[]){
            console.error(prefix, ...msgs);
            sendLogToSentry(prefix, ...msgs);
        },
    }
}


let useSentry = false;

export function canUseSentryLogs(val: boolean){
    useSentry = val;
}


function sendLogToSentry(prefix: string, ...msgs: any[]){

    if(!useSentry)
        return;

    Sentry.withScope(scope => {

        scope.setLevel(Severity.Error);
        scope.setTags({
            logger: prefix,
        });

        const error = msgs.find(msg => msg instanceof Error);
        if(error){

            const otherMsgs = msgs.filter(msg => msg !== error);
            const extraInfo = dataListToSingleMsg(otherMsgs);
            const extra = extraInfo? {extraInfo} : undefined;

            Sentry.captureException(error, { extra });
        }
        else {
            const singleMsg = dataListToSingleMsg(msgs);
            Sentry.captureMessage(singleMsg);
        }
    });
}

function dataListToSingleMsg(list: any[]) {
    return list
      .map(obj => toMsgPart(obj))
      .filter(str => str.length > 0)
      .join(' ').trim();
}

function toMsgPart(obj: any) {

    if(Util.isEmpty(obj))
        return '';

    if(Util.isString(obj))
        return obj.trim();

    if(Util.isObject(obj) || Util.isArray(obj)){
        try {
            return `\n${Util.toJsonPretty(obj)}\n`;
        } catch (e: any) {
            return obj.toString();
        }
    }

    return obj.toString();
}
