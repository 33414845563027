import { actionCreator } from '@/utils/redux/action-creator';


const creator = actionCreator('DAPP_BINANCE_WALLET');


export const BinanceWalletAction = {

  SetBinanceProvider: creator.asyncAction('SET_BINANCE_PROVIDER'),
  DoPayment:      creator.asyncAction('DO_PAYMENT'),
  AllowPayReq:    creator.asyncActionWithPayload<boolean, void>('ALLOW_PAY_REQ'),
  PayReq:         creator.asyncAction('PAY_REQ'),
};

