import BigNumber from 'bignumber.js';
import bnbLogo from '@/assets/asset/bnb.png';
import busdPngLogo from '@/assets/asset/busd.png';
import usdtPngLogo from '@/assets/asset/usdt.png';
import mnxLogo from '@/assets/asset/mnx.svg';
import mnxPngLogo from '@/assets/asset/mnx.png';
import { Currency, currLabel, Money } from '@/model/common/Money';
import { Network } from '@/model/common/Network';
import { Util } from '@/utils/util';
import { Config } from '@/config';
import { isSameAddress } from '@/model/common/Blockchain';


export type TokenFamily = 'MnxE' | 'BUSD' | 'USDT';

// need to be only png! Metamask do not recognize svg icon!
export const ExternalWalletLogos: Record<TokenFamily, string> = {
  MnxE: mnxPngLogo,
  BUSD: busdPngLogo,
  USDT: usdtPngLogo,
}

export interface TokenBase {
  network: Network,
  tokenId: string,
  decimals: number,
  abbr: string,
  logo: string,
  fullName?: string,
  globalOrder?: number,
}

export interface TokenDef extends TokenBase {
  native?: boolean,
  altCoin?: boolean,
  currency: Currency,
  family?: TokenFamily,
  familyOrder?: number,
}

export interface TokenState extends TokenDef {
  balance: number,
}

export const EmptyTokenId = '_';

const isProd = Config.IsProd;

const tokens: TokenDef[] = [
  // native
  {
    currency: 'bBNB',
    network: 'BinanceMainNet',
    native: true,
    tokenId: EmptyTokenId,
    abbr: currLabel('bBNB'),
    fullName: 'Binance Coin',
    decimals: 18,
    logo: bnbLogo,
  },
  // MnxE family
  {
    family: 'MnxE',
    currency: 'btMNXe',
    network: 'BinanceTestNet',
    tokenId: '0xd570e1ee81a8ca94008e1cf75c72b5e7a7b83bc5',
    familyOrder: 0,
    abbr: currLabel('btMNXe'),
    fullName: 'EURx Coin',
    decimals: 8,
    logo: mnxLogo,
    globalOrder: 0,
  },
  // BUSD family
  {
    family: 'BUSD',
    familyOrder: isProd? 1 : 2, // priority on prod
    currency: 'bBUSD',
    network: 'BinanceMainNet',
    tokenId: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    abbr: currLabel('bBUSD'),
    fullName: 'Binance USD',
    decimals: 18,
    logo: busdPngLogo,
  },
  {
    family: 'BUSD',
    familyOrder: isProd? 2 : 1, // priority on dev
    currency: 'btBUSD',
    network: 'BinanceTestNet',
    tokenId: '0xed24fc36d5ee211ea25a80239fb8c4cfd80f12ee',
    abbr: currLabel('btBUSD'),
    fullName: 'Binance USD',
    decimals: 18,
    logo: busdPngLogo,
  },
  // USDT family
  {
    family: 'USDT',
    currency: 'bUSDT',
    network: 'BinanceMainNet',
    tokenId: '0x55d398326f99059fF775485246999027B3197955',
    abbr: currLabel('bUSDT'),
    fullName: 'Tether',
    decimals: 18,
    logo: usdtPngLogo,
    globalOrder: 0,
  }
];


export function getTokenAmount(token: TokenState|undefined): number{
  return !token? 0 : getAmountFromAbsolute(token.balance, token.decimals);
}

export function getAmountFromAbsolute(absoluteAmount: string|number, decimals: number): number{
  return new BigNumber(absoluteAmount).shiftedBy(-decimals).toNumber();
}

export function getAbsoluteTokenAmount(amount: number, decimals: number): number {
  return new BigNumber(amount).shiftedBy(decimals).toNumber();
}

export const Tokens = {

  all(): TokenDef[] {
    return tokens;
  },

  findById(tokenId: string): TokenDef|undefined {
    return tokens.find(token => isSameAddress(token.tokenId, tokenId));
  },

  findCurrencyToken(currency: Currency): TokenDef|undefined {
    return tokens.find(token => token.currency === currency);
  },

  findTokensByMoney(moneyList: Money[]): TokenDef[] {
    return moneyList.map(money => Tokens.findCurrencyToken(money.currency))
      .filter(token => !!token) as TokenDef[];
  },

  getCurrencyToken(currency: Currency): TokenDef {

    const token = Tokens.findCurrencyToken(currency);

    if(!token)
      throw new Error(`unknown token for currency ${currency}`);
    return token;
  },

  isSupportedCurrency(currency: Currency){
    return tokens.map(token => token.currency).includes(currency);
  },

  getNetworkTokens(network: Network): TokenDef[] {
    return tokens.filter(token =>
      token.network === network);
  },

  selectOnesFromFamilies(tokens: TokenDef[]){

    const byFamily = new Map<TokenFamily, TokenDef>();
    const noFamily: TokenDef[] = [];

    tokens.forEach(token => {
      if(token.family){
        const oldToken = byFamily.get(token.family);
        const oldTokenOrder = oldToken?.familyOrder || 0;
        const tokenOrder = token.familyOrder || 0;
        if(!oldToken || oldTokenOrder > tokenOrder){
          byFamily.set(token.family, token);
        }
      } else {
        noFamily.push(token);
      }
    });

    const filtered = [
      ...noFamily,
      ...Array.from(byFamily.values())
    ];

    return Tokens.sort(filtered);
  },

  /** our token is first in order */
  sort(tokens: TokenDef[]): TokenDef[] {
    const all = [...tokens];
    return all.sort((a, b)=>{

      if(a.globalOrder === undefined
          && b.globalOrder === undefined)
        return Util.ascSort(a.abbr, b.abbr);

      if(a.globalOrder === undefined)
        return 1;
      if(b.globalOrder === undefined)
        return -1;

      return Util.ascSort(a.globalOrder, b.globalOrder);
    });
  }

};

export function sameToken(a?: TokenDef, b?: TokenDef){
  return a && b
    && a.network === b.network
    && isSameAddress(a.tokenId, b.tokenId)
    && a.altCoin === b.altCoin;
}

export function checkSameNetwork(tokenA: TokenBase, tokenB: TokenBase): Network {
  const {network} = tokenA;

  if( network !== tokenB.network)
    throw new Error(`networks not the same: ${network}, ${tokenB.network}`);

  return network;
}


export function toAltCoin(base: TokenBase): TokenDef {
  return {
    ...base,
    altCoin: true,
    currency: Tokens.findById(base.tokenId)?.currency || 'UNKNOWN',
  }
}
