import { call, put, select, takeEvery } from 'redux-saga/effects';
import { Action } from '@/model/actions';
import { ActionWithPayload, withErrorHandler } from '@/utils/redux/action-creator';
import { RootState } from '@/model/types';
import { Util } from '@/utils/util';
import { getTokenAmount, Tokens } from '@/model/common/Assets';
import { Config } from '@/config';
import { DefLang } from '@/model/common/Lang';
import {
  InstarampLang,
  InstarampUrls,
  queryInstarampLogout,
  querySignParams,
  SignDataReq,
  SignDataResp,
} from '@/api/instaramp';
import { InstarampCryptoPair, instarampDemoEthAddress, instarampPartnerId } from '@/model/onRamp/instaramp/types';
import { OnRampLastOperation, OnRampOperation } from '@/model/onRamp/types';
import { LSCache } from '@/utils/storage';
import { Goal, Metrika } from '@/api/metrika';
import { FiatCurrency } from '@/model/common/Money';
import { isSameAddress } from '@/model/common/Blockchain';
import { MathUtil } from '@/utils/math-util';
import { StringUtil } from '@/utils/string-util';

const log = Util.getLog('instaramp/sagas');

const lastOperationCacheKey = 'instaramp-last-operation';
const safeDelta = 1.01; // for enough money in deposit


function* prepareInstarampOnRamp(){

  const rootState = (yield select((state: RootState) => state)) as RootState;

  if(rootState.onRamp.provider !== 'Instaramp')
    return;

  const {currency, amount} = rootState.walletProvider.selectedQuote || {};
  const {baseCurrency: oldBaseCurrency} = rootState.onRamp;
  const lang = rootState.global.lang || DefLang;
  const langParam:InstarampLang = lang === 'ru'? 'RU' : 'EN';
  const {address} = rootState.wallet;

  const selectedTokenId = rootState.walletProvider.selectedToken?.tokenId;
  const tokenBalance = (rootState.wallet.assets?.otherAssets || [])
    .find(token => isSameAddress(token.tokenId, selectedTokenId));

  const tokenAmount = getTokenAmount(tokenBalance);
  const cryptoCurrency = selectedTokenId ? Tokens.findById(selectedTokenId) : undefined;

  if( !currency || !amount || !oldBaseCurrency || !address || !cryptoCurrency)
    return;

  let baseCurrency: FiatCurrency = oldBaseCurrency;

  // use only EUR
  if(oldBaseCurrency !== 'EUR'){
    baseCurrency = 'EUR';
    yield put(Action.onRamp.support.SetBaseCurrency(baseCurrency).pure);
  }

  const currencyPair = InstarampCryptoPair[currency];
  const fastBuyMode = tokenAmount === 0;

  let needCryptoAmount = amount;
  if(tokenAmount > 0 && tokenAmount < amount){
    needCryptoAmount = (amount - tokenAmount) * safeDelta;
  }

  // handle a near by zero value
  if(needCryptoAmount < amount * 0.05){
    needCryptoAmount = amount;
  }

  // round for 8 digits: instaramp format
  needCryptoAmount = MathUtil.round(needCryptoAmount, -8);

  yield put(Action.onRamp.support.SetStatus('Preparing').pure);

  let frameUrl:string|undefined;

  let recipientAddress = address;
  // use demo address in non-prod environment
  if( ! Config.IsProd){
    recipientAddress = instarampDemoEthAddress;
  }

  // set signature and create auto-session
  const email = rootState.auth.data?.email;
  const externalId = undefined;

  if(email && currencyPair){
    try {


      const random = StringUtil.replaceAll(Util.uuid(), '-', '');
      const transactionId = externalId? `${random}_${externalId}` : undefined;

      const req: SignDataReq = {
        currencyPair,
        recipientAddress,
        cryptoAmount: needCryptoAmount.toString(),
        lang: langParam,
        partnerId: instarampPartnerId,
        transactionId,
      }
      const {signature} = (yield call(querySignParams, req)) as SignDataResp;

      const urlParams = new URLSearchParams();
      urlParams.set('userEmail', email);
      urlParams.set('partnerId', req.partnerId);
      urlParams.set('currencyPair', req.currencyPair);
      urlParams.set('cryptoAmount', req.cryptoAmount);
      urlParams.set('recipientAddress', req.recipientAddress);
      urlParams.set('userLang', req.lang);
      urlParams.set('signature', signature);

      if(req.transactionId){
        urlParams.set('transactionId', req.transactionId);
      }

      if(fastBuyMode) {
        urlParams.set('customFields', 'fastBuy:true');
      }

      frameUrl = `${InstarampUrls[Config.Environment]}/purchase?${urlParams}`;
      Metrika.reach(Goal.instaramp.OpenedByEmail);

    } catch (e: any) {
      log.error('cannot sign url', e);
    }
  }

  if(!frameUrl){

    const urlParams = new URLSearchParams();

    urlParams.set('cryptoAmount', needCryptoAmount.toString());
    urlParams.set('lang', langParam);
    urlParams.set('recipientAddress', recipientAddress);

    if(currencyPair){
      urlParams.set('currencyPair', currencyPair);
    }

    frameUrl = `${InstarampUrls[Config.Environment]}/widget-buy?${urlParams}`;
    Metrika.reach(Goal.instaramp.OpenedByGuest);
  }

  yield put(Action.onRamp.support.SetCryptoAmount(needCryptoAmount).pure);
  yield put(Action.onRamp.support.SetCryptoCurrency(cryptoCurrency.currency).pure);
  yield put(Action.onRamp.support.SetFrameUrl(frameUrl).pure);
  yield put(Action.onRamp.support.SetStatus('Shown').pure);

  Metrika.reach(Goal.instaramp.Opened);
}

function* storeOperationStatus(
  {payload: operation}: ActionWithPayload<OnRampOperation|undefined>
){

  const rootState = (yield select((state: RootState) => state)) as RootState;
  const {provider} = rootState.onRamp;

  if(!operation || provider !== 'Instaramp')
    return;

  const {status} = operation;

  if(status !== 'Succeed'){

    // store processing operation
    const toStore: OnRampLastOperation = {
      provider: 'Instaramp',
      ...operation,
    };

    LSCache.set(lastOperationCacheKey, toStore);
    yield put(Action.onRamp.support.SetLastProcessingOperation(toStore).pure);
  }
  else {

    // remove succeed operation
    LSCache.remove(lastOperationCacheKey);
    yield put(Action.onRamp.support.SetLastProcessingOperation(undefined).pure);
  }
}

function* restoreOldOperationStatus(){
  const operation: OnRampLastOperation|undefined = LSCache.get(lastOperationCacheKey);
  yield put(Action.onRamp.support.SetLastProcessingOperation(operation).pure);
}


function* onWalletLogout(){
  try {
    yield call(queryInstarampLogout);
  } catch (e: any){
    log.warn('cannot logout', e);
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function findExternalIdFromUrl(): string|undefined {

  const query = new URLSearchParams(window.location.search);
  const sourceStr = query.get('success-url') || '';

  // search for format ".../success/36736756/"
  const parts = sourceStr.split('/success/');
  if(parts.length < 2)
    return undefined;

  // "36736756/"
  return parts[1].split('/')[0];
}


export default function* rootSaga(): Generator {

  yield takeEvery(Action.onRamp.support.PrepareOnRamp.type,
    withErrorHandler(
      prepareInstarampOnRamp
    ));

  yield takeEvery(Action.onRamp.SetOperationStatus.type,
    withErrorHandler(
      storeOperationStatus
    ));

  yield takeEvery(Action.global.RestorePageData.type,
    withErrorHandler(
      restoreOldOperationStatus
    ));

  yield takeEvery(Action.wallet.Logout.type,
    withErrorHandler(
      onWalletLogout
    ));
}
