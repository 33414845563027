import { CircularProgress } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import { ClassName } from '@/components/Style';

const styles = makeStyles((theme: Theme) => {

  return {
    enteringLoading: {
      marginRight: '8px',
    },
  };
});

export interface EnteringButtonContentProps {
  title: string
}

export function EnteringButtonContent(
  {
    title
  }: EnteringButtonContentProps
){
  const classes = styles();
  return (
    <>
      <CircularProgress
        size={20}
        color="inherit"
        className={clsx(classes.enteringLoading, ClassName.SkipOpacity)}/>
      {title}
    </>
  )
}
