import { actionCreator } from '@/utils/redux/action-creator';
import { Network } from '@/model/common/Network';


const creator = actionCreator('DAPP_INNER_WALLET');


export const InnerWalletAction = {

  SetInnerProvider: creator.asyncAction('SET_INNER_PROVIDER'),
  InitWallet:     creator.asyncAction('INIT_WALLET'),
  UpdateNetwork:  creator.syncActionWithPayload<Network>('UPDATE_NETWORK'),
};

