import { ActionWithPayload } from '@/utils/redux/action-creator';

import { createReducer, createStateHandlers } from '@/utils/redux/reducer-util';
import { Action } from '@/model/actions';
import { AddNotificationPayload, RemoveNotificationPayload, SetLoadingActionPayload } from '@/model/global/actions';
import { GlobalState as S, initialState, NotificationType } from './types';

const { p2f, p2fs } = createStateHandlers<S>();

export const reducers = createReducer<S>(initialState, {

  // p2f<S['']>(''),

  [Action.global.ShowGlobalMenu.type]: p2f<S['showGlobalMenu']>('showGlobalMenu'),
  [Action.global.SetLoading.type]: p2fs<SetLoadingActionPayload, S['loading']>('loading', p => p.loading),
  [Action.global.SetLang.type]: p2f<S['lang']>('lang'),


  [Action.global.support.AddNotification.type](
    state: S, { payload }: ActionWithPayload<AddNotificationPayload>
  ): S {

    if(payload.type === 'error')
      return {
        ...state,
        errors: [
          payload,
          ...state.errors
        ]
      };

    if(payload.type === 'warning')
      return {
        ...state,
        warnings: [
          payload,
          ...state.warnings
        ]
      };

    if(payload.type === 'success')
      return {
        ...state,
        successes: [
          payload,
          ...state.successes
        ]
      };

    return state;
  },
  [Action.global.support.RemoveAllNotifications.type](
    state: S, { payload: type }: ActionWithPayload<NotificationType>
  ): S {

    if(type === 'error')
      return {
        ...state,
        errors: []
      };

    if(type === 'warning')
      return {
        ...state,
        warnings: []
      };

    if(type === 'success')
      return {
        ...state,
        successes: []
    };

    return state;
  },
  [Action.global.support.RemoveNotification.type](
    state: S, { payload: { id, type } }: ActionWithPayload<RemoveNotificationPayload>
  ): S {

    if(type === 'error')
      return {
        ...state,
        errors: [
          ...state.errors.filter(({ id: saved }) => saved !== id)
        ]
      };

    if(type === 'warning')
      return {
        ...state,
        warnings: [
          ...state.warnings.filter(({ id: saved }) => saved !== id)
        ]
      };

    if(type === 'success')
      return {
        ...state,
        successes: [
          ...state.successes.filter(({ id: saved }) => saved !== id)
        ]
      };

    return state;
  },

  [Action.global.CleanNotifications.type](
    state: S
  ): S {
    return {
      ...state,
      errors: [],
      warnings: [],
      successes: [],
    };
  },
});

export default reducers;
