import { createReducer, createStateHandlers } from '@/utils/redux/reducer-util';
import { Action } from '@/model/actions';
import { ActionWithPayload } from '@/utils/redux/action-creator';
import { initialState, WalletState as S } from './types';

const { p2f } = createStateHandlers<S>();

export const reducers = createReducer<S>(initialState, {

  // p2f<S['']>(''),

  [Action.wallet.SetMigrationScreenShown.type]: p2f<S['migrationScreenShown']>('migrationScreenShown'),
  [Action.wallet.SetLastRequestedProvider.type]: p2f<S['lastRequestedProvider']>('lastRequestedProvider'),
  [Action.wallet.SetDialog.type]: p2f<S['dialog']>('dialog'),
  [Action.wallet.SetAddress.type]: p2f<S['address']>('address'),
  [Action.wallet.SetAssets.type]: p2f<S['assets']>('assets'),
  [Action.wallet.SetDelayedReloadRequest.type]: p2f<S['delayedReloadRequest']>('delayedReloadRequest'),
  [Action.wallet.SetNeedShowMigrationScreen.type]: p2f<S['needShowMigrationScreen']>('needShowMigrationScreen'),

  [Action.wallet.SetLoading.type](
    state: S, { payload }: ActionWithPayload<boolean>
  ): S {
    return {
      ...state,
      loading: payload,
      loadingType: undefined,
    };
  },

  [Action.wallet.StartReloadLoading.type](state: S): S {
    return {
      ...state,
      loading: true,
      loadingType: 'reload',
    };
  },

  [Action.wallet.Logout.type](state: S): S {
    return {
      ...state,
      address: undefined,
      assets: undefined,
      lastRequestedProvider: undefined,
      dialog: 'none',
      delayedReloadRequest: false,
      needShowMigrationScreen: false,
      migrationScreenShown: false,
    };
  },


});

export default reducers;
