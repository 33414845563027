import { createReducer } from '@/utils/redux/reducer-util';
import { Action } from '@/model/actions';
import { ActionWithPayload } from '@/utils/redux/action-creator';
import { InitErrorType, initialState, WalletInitState } from '@/model/walletInit/types';


export const reducers = createReducer<WalletInitState>(initialState, {

  [Action.init.SetLoaded.type](
    state: WalletInitState,
  ): WalletInitState {

    return {
      ...state,
      loaded: true,
    };
  },

  [Action.init.SetLoadTries.type](
    state: WalletInitState,
    {payload}: ActionWithPayload<number>
  ): WalletInitState {
    return {
      ...state,
      loadTries: payload,
    };
  },

  [Action.init.SetErrorType.type](
    state: WalletInitState,
    {payload}: ActionWithPayload<InitErrorType>
  ): WalletInitState {
    return {
      ...state,
      initError: payload,
    };
  },
});

export default reducers;
