import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import { Color, important, Style } from '@/components/Style';

export { default as Space } from './common/Space';

export const SpaceChar = '\u00A0';
export const LinkUrl = '#'


export function makeCommonStyles() {
  return makeStyles((theme: Theme) => {
    return {
      icon14: {
        width: '14px',
        height: '14px',
      },
      icon16: {
        width: '16px',
        height: '16px',
      },
      icon18: {
        width: '18px',
        height: '18px',
      },
      icon20: {
        width: '20px',
        height: '20px',
      },
      icon24: {
        width: '24px',
        height: '24px',
      },
      icon32: {
        width: '32px',
        height: '32px',
      },
      icon40: {
        width: '40px',
        height: '40px',
      },
      icon48: {
        width: '48px',
        height: '48px',
      },
      icon60: {
        width: '60px',
        height: '60px',
      },
      iconPadding9: {
        padding: '9px'
      },
      spaceBlock: {
        flexGrow: 10,
        flexShrink: 10,
      },
      textAlignLeft: {
        textAlign: 'left'
      },
      // flex
      alignItemsCenter: {
        alignItems: 'center',
      },
      flexCenter: {
        alignItems: 'center',
        justifyContent: 'center',
      },
      flexWrap: {
        flexWrap: 'wrap'
      },
      noShrink: {
        flexShrink: 0,
      },
      hide: {
        display: 'none',
      }
    };
  });
}

export function makeMainStyles() {
  return makeStyles(theme => {
    return {
      title: {
        ...Style.pack.Title
      },
      subTitle: {
        marginBottom: important('32px'),
        color: Color.text.Second
      },
      smallLabel: {
        color: Color.text.Second,
        fontSize: '15px',
      },
      smallText: {
        fontSize: '13px',
        lineHeight: '20px',
        color: Color.text.Second,
      },
      bigText: {
        color: Color.text.Main,
        fontSize: '16px',
      },
      mainText: {
        color: Color.text.Main,
        fontWeight: 500,
      },
      successText: {
        color: Color.text.Success,
      },
      infoText: {
        color: Color.text.Main,
      },
      errorText: {
        color: Color.error.Text2,
      },
      mainButtonItem: {
        marginBottom: important('16px')
      },
      iconButtonLabel: {
        marginLeft: '8px',
      },
      listItemButton: {
        border: important('none'),
        paddingLeft: '8px',
        paddingRight: '8px',
        paddingTop: '4px',
        paddingBottom: '4px',
        marginBottom: important('12px'),
        height: '40px',
        justifyContent: 'flex-start',
        '& .MuiButton-label > :not(:last-child)': {
          marginRight: '16px'
        }
      },
      enteringButton: {
        color: important(Color.text.Main)
      },
      warningBlock: {
        padding: '10px 16px',
        borderRadius: '8px',
        backgroundColor: Color.warning.Back,
        color: Color.warning.Text,
      },
      dialogTitle: {
        fontSize: '24px',
        lineHeight: '40px',
        padding: '16px 8px 8px 8px',
        textAlign: 'center',
      },
      dialogLongDescription: {
        marginTop: '8px',
        marginBottom: important('40px'),
        color: Color.text.Second,
      },
      dialogSubTitle: {
        fontSize: '20px',
        color: Color.text.Main,
        marginBottom: important('30px'),
      },
      dialogLongDescription2: {
        marginBottom: important('22px'),
      },
      descriptionBlock: {
        alignItems: 'center',
        justifyContent: 'space-between',
        flexShrink: 0,
        '& .MuiTypography-root': {
          fontSize: '14px',
          lineHeight: '20px',
        }
      },
      addressBlock: {
        padding: '16px',
        backgroundColor: Color.back.Second,
        borderRadius: '8px',
        '& .MuiChip-root': {
          fontSize: '12px',
        }
      },
      addressLinksBlock: {
        marginTop: important('12px'),
        '& button': {
          display: 'inline-flex',
          alignItems: 'center',
          marginRight: important('20px')
        },
        '& button svg': {
          marginRight: '8px',
        }
      },
      frame: {
        width: '100%',
        minHeight: '600px',
        borderWidth: '0px',
        borderRadius: '12px',
        backgroundColor: 'white',
        flexGrow: 1,
        flexShrink: 0,
      },
      frameHide: {
        display: 'none',
      },
      frameLoading: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: Color.text.Main,
      },
      linkText: {
        display: 'inline-block',
        padding: '0 4px',
        color: Color.text.Main,
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: Color.selected.White1,
        }
      }
    }
  })
}

export function makeDialogStyles() {
  return makeStyles((theme) => {

    return {
      title: {
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '16px 24px',
      },
      content: {
        marginBottom: '15px',
      },
      fullScreen: {
        '& .MuiDialog-paper': {
          maxHeight: '100vh',
          height: '100%',
        }
      }
    }
  });
}


export function makeFullMessageStyles() {
  return makeStyles((theme: Theme) => {

    return {
      logo: {
        textAlign: 'center',
        marginTop: '0px',
        marginBottom: important('2px'),
      },
      title: {
        ...Style.pack.Title,
        textAlign: 'center',
      },
      subTitle: {
        color: Color.text.Second,
        textAlign: 'center',
        lineHeight: '20px',
      }
    };
  });
}
