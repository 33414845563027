import { Button, Typography } from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import { makeCommonStyles, makeMainStyles, Space } from '@/components';
import { ReactComponent as WarningIcon } from '@/assets/ui/warning-2-logo.svg';
import { useI18n } from '@/i18n';
import { Color, important } from '@/components/Style';


const styles = makeStyles((theme: Theme) => {

  return {
    root: {
      marginTop: '16px'
    },
    titleBlock: {
      backgroundColor: Color.back.Second,
      padding: '14px 4px',
      paddingBottom: '2px',
      borderRadius: '8px',
    },
    titleDesc: {
      lineHeight: important('17px'),
    },
    promoBlock: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center'
    },
    promo1: {
      marginTop: '58px',
      marginBottom: important('16px'),
      color: Color.text.Second,
      textAlign: 'center',
    },
    promo2: {
      color: important(Color.back.Fourth),
      textAlign: 'center',
    }
  }
});

const commonStyles = makeCommonStyles();
const mainStyles = makeMainStyles();

export interface SelectWalletWelcomePanelProps {
  onOpenWallet: ()=>void
}


export function WelcomePanel(
  {
    onOpenWallet
  }: SelectWalletWelcomePanelProps
){

  const i18n = useI18n();
  const classes = styles();
  const mainClasses = mainStyles();
  const commonClasses = commonStyles();

  return (
    <Space direction="vertical" className={clsx(commonClasses.spaceBlock, classes.root)}>

      <Space direction="horizontal" className={classes.titleBlock} size={0.5}>
        <WarningIcon />
        <Space direction="vertical" size={0}>
          <Typography color="textPrimary">
            {i18n.m('select_wallet_welcome.title')}
          </Typography>
          <Typography color="textSecondary" className={clsx(mainClasses.smallText, classes.titleDesc)}>
            {i18n.m('select_wallet_welcome.description')}
          </Typography>
        </Space>
      </Space>

      <div className={clsx(commonClasses.spaceBlock, classes.promoBlock)}>
        <Space direction="vertical" size={0}>
          <Typography color="textPrimary" className={clsx(classes.promo1)}>
            {i18n.m('select_wallet_welcome.promo1')}
          </Typography>
          <Typography color="textSecondary" className={clsx(classes.promo2, mainClasses.smallText)}>
            {i18n.m('select_wallet_welcome.promo2')}
          </Typography>
          <Typography color="textSecondary" className={clsx(classes.promo2, mainClasses.smallText)}>
            {i18n.m('select_wallet_welcome.promo3')}
          </Typography>
        </Space>
      </div>

      <Button
        variant="outlined"
        type="submit"
        onClick={onOpenWallet}
        data-test="open-login-dialog"
      >
        {i18n.m('select_wallet_welcome.select')}
      </Button>

    </Space>
  )
}
