import { createReducer, createStateHandlers } from '@/utils/redux/reducer-util';
import { Action } from '@/model/actions';
import { initialState, TorusState as S } from '@/model/torus/types';

const { p2f, tf } = createStateHandlers<S>();

export const reducers = createReducer<S>(initialState, {

  // p2f<S['']>(''),

  [Action.wallet.Logout.type]: tf<S['cred']>('cred', null),

  [Action.torus.SetLoading.type]: p2f<S['loading']>('loading'),
  [Action.torus.SetEntering.type]: p2f<S['entering']>('entering'),
  [Action.torus.SetCred.type]: p2f<S['cred']>('cred'),
  [Action.torus.SetLastRequestedLoginType.type]: p2f<S['lastRequestedLoginType']>('lastRequestedLoginType'),

  [Action.torus.UpdateStateId.type](
    state: S,
  ): S {
    const newState = (state.stateId || 0) + 1;
    return {
      ...state,
      stateId: newState
    };
  },
});

export default reducers;
