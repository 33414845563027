import { IconButton, Typography } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import React, { useCallback } from 'react';
import clsx from 'clsx';
import { ReactComponent as ArrowBackIcon } from '@/assets/icons/arrow-back.svg';
import { makeCommonStyles, makeMainStyles, Space } from '@/components';
import { HeaderWithMenu } from '@/components/wallet/common/HeaderWithMenu';
import { important } from '@/components/Style';
import { Action } from '@/model/actions';
import { useDispatch } from 'react-redux';

const commonStyles = makeCommonStyles();
const mainStyles = makeMainStyles();

const styles = makeStyles((theme: Theme) => {
  return {
    back: {
      padding: '6px',
      marginRight: important('12px')
    }
  }
});

export interface HeaderWithMenuAndBackProps {
  title: string,
  onClick?: () => void,
  className?: string,
  hideMenu?: boolean,
  disabled?: boolean,
  titleClassName?: string,
}

export function HeaderWithMenuAndBack(
  {
    title,
    onClick,
    className,
    hideMenu = false,
    disabled = false,
    titleClassName,
  }: HeaderWithMenuAndBackProps
){

  const dispatch = useDispatch();
  const commonClasses = commonStyles();
  const mainClasses = mainStyles();
  const classes = styles();

  const onClickHandler = useCallback(()=>{

    if(disabled)
      return undefined;

    if(onClick)
      return onClick();

    return Action.wallet.SetDialog('none')(dispatch);

  }, [onClick, dispatch, disabled]);

  return (
    <HeaderWithMenu hideMenu={hideMenu}>
      <Space direction="horizontal" size={1} className={clsx(commonClasses.alignItemsCenter, className)}>

        <IconButton
          className={clsx(commonClasses.icon40, classes.back)}
          data-test="back"
          onClick={onClickHandler}
        >
          <ArrowBackIcon/>
        </IconButton>

        <Typography className={clsx(mainClasses.title, titleClassName)}>
          {title}
        </Typography>
      </Space>
    </HeaderWithMenu>
  )
}
