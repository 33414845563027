import { useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MsgKey } from '@/i18n/Msg';
import { rootStore } from '@/hocs/withStore/configureStore';
import { Action } from '@/model/actions';
import { Network } from '@/model/common/Network';
import { Binance } from '@/api/blockchain/binance';
import { getTokenAmount, TokenState } from '@/model/common/Assets';
import { RootState } from '@/model/types';
import { Config } from '@/config';
import { Util } from '@/utils/util';
import { I18n } from '@/i18n';
import { isSameAddress } from '@/model/common/Blockchain';
import { ImgToCacheBuffer } from '@/api/support/image_preload';
import MetamaskIcon from '@/assets/wallet/metamask.png';
import BinanceIcon from '@/assets/wallet/binance.png';
import { WalletProviderType } from '@/model/wallet/provider/types';
import InnerIcon from '@/assets/wallet/inner.svg';

const log = Util.getLog('components/wallet')


ImgToCacheBuffer.push(...[
  MetamaskIcon,
  BinanceIcon,
]);

export const WalletIcon: Record<WalletProviderType, string> = {
  none: '',
  inner: InnerIcon,
  metamask: MetamaskIcon,
  binanceWallet: BinanceIcon,
  demoWallet: InnerIcon,
}


export function WalletLabel(i18n: I18n, type: WalletProviderType){
  switch (type){
    case 'inner': return i18n.m('wallet.inner');
    case 'metamask': return i18n.m('wallet.metamask');
    case 'binanceWallet': return i18n.m('wallet.binance_wallet');
    case 'demoWallet': return i18n.m('wallet.demo_wallet');
    default: return '';
  }
}



export async function copyAddressToClipboard(address: string){
  return copyToClipboard(
    address,
    'wallet.address_copied',
    'wallet.address_not_copied');
}

export async function copyToClipboard(text: string, success?: MsgKey, fail?: MsgKey){

  try {
    await navigator.clipboard.writeText(text);
    await rootStore.dispatch(Action.global.AddSuccess({messageId: success || 'common.copied'}).pure);
  } catch (e: any){

    // try old api
    const doneByOldApi = fallbackCopyToClipboardByOldApi(text);
    if(doneByOldApi){
      await rootStore.dispatch(Action.global.AddSuccess({messageId: success || 'common.copied'}).pure);
    }
    else {
      log.error('cannot copy to clipboard', e);
      await rootStore.dispatch(Action.global.AddError({messageId: fail || 'common.copy_error'}).pure);
    }
  }
}

function fallbackCopyToClipboardByOldApi(text: string): boolean {

  const textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';
  textArea.style.opacity = '0';

  try {

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    return document.execCommand('copy');
  } catch (err) {
    return false;
  } finally {
    document.body.removeChild(textArea);
  }
}



export function openAddress(address: string, network: Network){
  try {
    const url = Binance.getAddressUrl(address, network)
    window.open(url, '_blank')?.focus();
  } catch (e: any) {
    log.error('cannot open address', e);
  }
}

export function isTestNet(network: Network){
  return ! network.includes('MainNet');
}

export function getAddressLabel(address?: string){
  return address? `${address.substring(0, 5)}...${address.substring(address.length-5)}` : '';
}

export function getNetworkTypeLabel(network: Network){
  switch (network){
    case 'BinanceMainNet': return 'BSC Mainnet';
    case 'BinanceTestNet': return 'BSC Tesnet';
    default: return network;
  }
}


export function useOnAddressChanged(address: string|undefined, handler: ()=> void){

  const [oldAddress, setOldAddress] = useState<string>();

  useLayoutEffect(()=>{
    if( !address || (oldAddress && address !== oldAddress)){
      setOldAddress(undefined);
      handler();
    }
    else if(!oldAddress){
      setOldAddress(address);
    }
  }, [address, oldAddress, handler]);
}

export function useSelectedTokenBalance(): TokenState|undefined{
  const selectedToken = useSelector((state: RootState) => state.walletProvider.selectedToken);
  const assets = useSelector((state: RootState) => state.wallet.assets?.otherAssets || []);
  return assets.find(token => isSameAddress(selectedToken?.tokenId, token.tokenId));
}

export function useNeedDepositAmount(): number {

  const tokenBalance = useSelectedTokenBalance();
  const tokenAmount = getTokenAmount(tokenBalance);
  const payAmount = usePayAmount();

  return Math.max(0, payAmount - tokenAmount);
}

export function usePayAmount(): number {
  const selectedQuote = useSelector((state: RootState) => state.walletProvider.selectedQuote);
  const selectedToken = useSelector((state: RootState) => state.walletProvider.selectedToken);
  const swapPath = useSelector((state: RootState) => state.swap.swapPath);

  let payAmount = 0;
  if(selectedToken?.altCoin){
    payAmount = swapPath?.price || 0;
  } else {
    payAmount = selectedQuote?.amount || 0;
  }

  return payAmount;
}

/**
 * Send request to reload the wallet balance after on-ramp dialog will be closed
 */
export function useReloadWalletRequest(){

  const dispatch = useDispatch();
  const timeout = Config.wallet.SetDelayedReloadTimeout;

  useLayoutEffect(()=>{

    const timerId = setTimeout(()=>{
      Action.wallet.SetDelayedReloadRequest(true)(dispatch);
    }, timeout)

    return ()=>{
      clearTimeout(timerId);
    };
  }, [timeout, dispatch]);
}


export function useMailtoLink(){
  return `mailto:${Config.SupportEmail}`;
}


export function useShowAlts(): boolean {

  const providerType = useSelector((state: RootState) => state.walletProvider.type);

  let showAlts = false;
  if(providerType === 'inner')
    showAlts = Util.parseBool(Config.toggles['show-alts-inner-wallet'])
  else if(providerType !== 'demoWallet')
    showAlts = Util.parseBool(Config.toggles['show-alts-external-wallet'])

  return showAlts;
}

