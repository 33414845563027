import { CircularProgress, Typography } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import { useI18n } from '@/i18n';
import { ReactComponent as Logo } from '@/assets/ui/pay-progress-logo.svg';
import { makeFullMessageStyles, Space } from '@/components';
import { Color } from '@/components/Style';

const styles = makeStyles((theme: Theme) => {

  return {
    loading: {
      alignItems: 'center',
      justifyContent: 'center',
      color: Color.text.Main,
      marginTop: '24px',
    }
  };
});

const fullMessageStyles = makeFullMessageStyles();

export function MigrationInProgressView(){

  const i18n = useI18n();
  const fullMessageClasses = fullMessageStyles();
  const classes = styles();

  return (
    <Space direction="vertical">

      <div className={fullMessageClasses.logo}>
        <Logo/>
      </div>

      <Typography className={fullMessageClasses.title} data-test="title">
        {i18n.m('migration_in_progress.title')}
      </Typography>
      <Typography className={fullMessageClasses.subTitle} data-test="description">
        {i18n.m('migration_in_progress.description')}
      </Typography>

      <Space direction="vertical" className={classes.loading}>
        <CircularProgress color="inherit" />
      </Space>

    </Space>
  );
}
