import { call, put, select, takeEvery } from 'redux-saga/effects';
import { Util } from '@/utils/util';
import { Action } from '@/model/actions';
import { withErrorHandler } from '@/utils/redux/action-creator';
import { RootState } from '@/model/types';
import { Tokens } from '@/model/common/Assets';
import { getBinanceSwapPath } from '@/api/blockchain/binance_swap';
import { isSameGetSwapPathReqs, SwapPath, SwapPathReq } from '@/model/swap/types';

const log = Util.getLog('swap/sagas');


function* onSelectedToken(){

  // clear old rate
  yield put(Action.swap.ResetRate().pure);

  // wait until root model will be updated
  yield Util.timeout(0);

  // load new rate if need
  yield loadRate();
}

function* loadRate(){

  const rootState = (yield select((state: RootState) => state)) as RootState;

  const {selectedToken: fromToken, selectedQuote} = rootState.walletProvider;
  if( ! selectedQuote || ! fromToken || ! fromToken.altCoin)
    return;

  const toToken = Tokens.getCurrencyToken(selectedQuote.currency);
  if( toToken.altCoin)
    return;

  const req: SwapPathReq = {from: fromToken.tokenId, to: toToken.tokenId, outPrice: selectedQuote.amount};
  const oldReq: SwapPathReq|undefined = rootState.swap.swapPathReq;

  // already in req
  if(isSameGetSwapPathReqs(oldReq, req))
    return;

  yield put(Action.swap.support.SetRateState('loading').pure);
  try {

    yield put(Action.swap.support.SetSwapPathReq(req).pure);
    const swapPath = (yield call(getBinanceSwapPath, fromToken, toToken, selectedQuote.amount)) as SwapPath;

    if(swapPath.path.length === 0)
      throw new Error(`empty swap path for ${[fromToken, toToken].map(t => t.abbr).join(',')}`);

    // check resp is outdated
    const curReq = (yield select((state: RootState) => state.swap.swapPathReq)) as SwapPathReq;
    if( ! isSameGetSwapPathReqs(curReq, req))
      return;

    log.info('swap path', {
      path: swapPath.path.map(token => token.abbr).join(','),
      fromPrice: swapPath.price,
      toPrice: selectedQuote.amount,
    });

    yield put(Action.swap.support.SetSwapPath(swapPath).pure);
    yield put(Action.swap.support.SetRateState('success').pure);

  } catch (e){
    log.error('cannot load rate', e);
    yield put(Action.swap.support.SetRateState('error').pure);
  }
  finally {
    yield put(Action.swap.support.SetSwapPathReq(undefined).pure);
  }
}


export default function* rootSaga(): Generator {

  yield takeEvery(Action.walletProvider.SetSelectedToken.type,
    withErrorHandler(
      onSelectedToken
    ));

  yield takeEvery(Action.swap.LoadRate.type,
    withErrorHandler(
      loadRate
    ));

}
