import { call, put, select, takeEvery } from 'redux-saga/effects';
import { withErrorHandler } from '@/utils/redux/action-creator';
import { Action } from '@/model/actions';
import { withGlobalLock } from '@/model/global/sagas';
import { RootState } from '@/model/types';
import { Util } from '@/utils/util';
import { Config, ToggleDefVal, ToggleKey, Toggles } from '@/config';
import { setBinanceWeb3Urls } from '@/api/blockchain/binance';
import { queryWalletInitInfo } from '@/api/wallet';
import { WalletInitInfo } from '@/model/walletInit/types';

const log = Util.getLog('walletInit/sagas');
const maxLoadTries = 5;
const nextTryTimeout = 2000;
const isProd = Config.IsProd;


function* loadData(){

  const rootState = (yield select((state: RootState) => state)) as RootState;
  const tryCount = rootState.init.loadTries || 0;

  try {

    const info = (yield call(queryWalletInitInfo)) as WalletInitInfo;
    const {toggles, web3} = info;

    if(toggles){
      updateConfigToggles(rootState, toggles);
    }

    if(web3){
      setBinanceWeb3Urls(web3.bsc);
    }

    if( ! isProd){
      log.info('app Config', Config);
    }

  } catch (e: any){

    const {status} = e;

    // network error or service temporarily unavailable
    if( ! status || status >= 500){

      // no need for same error log on next try
      if(tryCount === 0)
        log.error('invalid walletInit response', e);

      yield nextLoadInitDataTry();
      return;
    }

    // unknown error: will use default settings
    log.error('cannot walletInit', e);
  }
  finally {
    yield put(Action.init.SetLoaded().pure);
  }

}


function updateConfigToggles(rootState: RootState, toggles: Toggles){

  Object.entries(toggles).forEach(entry => {
    const [key, val] = entry;
    const toggleKey = key as ToggleKey;
    const defVal = ToggleDefVal[toggleKey] || false;
    Config.toggles[toggleKey] = Util.parseBool(val, defVal);
  });

  // special for non prod
  if( ! isProd){
    if(Util.parseBool(rootState.params['instaramp-on-ramp'], false)){
      Config.toggles['instaramp-priority'] = true;
      Config.toggles['moonpay-priority'] = false;
    }
    else if(Util.parseBool(rootState.params['moonpay-on-ramp'], false)){
      Config.toggles['moonpay-priority'] = true;
      Config.toggles['instaramp-priority'] = false;
    }
  }
}


function* nextLoadInitDataTry(): Generator {

  const rootState = (yield select((state: RootState) => state)) as RootState;
  const tryCount = (rootState.init.loadTries || 0) + 1;
  const canNextTry = tryCount < maxLoadTries;

  if(canNextTry){
    yield call(Util.timeout, nextTryTimeout);
    yield put(Action.init.SetLoadTries(tryCount).pure);
    yield loadData();
  } else {
    yield put(Action.init.SetErrorType('ServiceUnavailable').pure);
  }
}

export default function* rootSaga(): Generator {

  yield takeEvery(Action.init.LoadData.type,
      withGlobalLock(
        withErrorHandler(
          loadData
        )));
}
