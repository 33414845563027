
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface AppParams extends Record<ParamKey, any> {}

export type ParamKey =
  'loaded'
  | 'frame-mode'
  | 'demo-mode'
  // for tests
  | 'torus-private-key'
  | 'demo-on-ramp'
  | 'moonpay-on-ramp'
  | 'instaramp-on-ramp'
  | 'bot-token'
  // invoice input:
  | 'invoice-id'
  | 'name'
  | 'success-url'
  | 'fail-url';


export const initialState: AppParams = {
  loaded: false,
} as any;
