import { Config } from '@/config';
import { LocalStorage } from '@/utils/storage';
import { Util } from '@/utils/util';

const log = Util.getLog('cross_domain_connection');
const waitStateTimeout = 5000;

let init = false;

const localStorageKeys = [
  'ts',
  'LANG',
  'lscache--ncps-authData',
  'lscache--ncps-instaramp-last-operation',
  'lscache--ncps-restore-data',
  'lscache--ncps-selected-pay-token',
  'lscache--ncps-torus',
];


export async function initCrossDomainConnection(): Promise<boolean>{

  if(init)
    return false;
  init = true;

  const targetUrl = getTargetUrl();
  if( ! targetUrl)
    return false;

  let done = false;
  let timeoutTimerId: any;

  return new Promise<boolean>((resolve) => {

    log.info('trying get a cross domain state...');
    const frameUrl = `${targetUrl}/cross-domain-connection.html`;

    window.addEventListener('message', (event) => {

      const {data, origin} = event;
      const fromWidget = frameUrl.startsWith(origin);
      if( ! data || ! fromWidget)
        return;

      const {type} = data;

      if(type === 'localStorage' && ! done){

        log.info('got cross domain state');
        setLocalStorageState(data.state || {});

        done = true;
        clearTimeout(timeoutTimerId);

        resolve(true);
      }
    });

    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = frameUrl;
    document.body.appendChild(iframe);

    // timeout for iframe resp
    timeoutTimerId = setTimeout(()=>{

      if( ! done){
        done = true;

        log.error('cannot get cross domain state', frameUrl);
        resolve(false);
      }
    }, waitStateTimeout);
  });
}


function getTargetUrl(): string|undefined {

  const env = Config.Environment;
  if(env === 'prod'){
    return 'https://checkout.smartypay.io';
  }

  if(env === 'local'){
    return 'http://localhost:3000';
  }

  // "dev.mnxsc.tech"
  const parent = location.host.split('.').slice(1).join('.');
  return `https://ncps-ui.${parent}`;
}


function setLocalStorageState(state: Record<string, string>){

  const stateTime = parseInt(state.ts || '0', 10);
  const curTime = LocalStorage.getLastUpdateTime();

  if(curTime > stateTime){
    log.info('skip outdated remote state', {local: curTime, remote: stateTime});
    return;
  }

  localStorageKeys.forEach(key => {
    const value = state[key];
    if(value){
      LocalStorage.setItem(key, value);
    }
  })
}
