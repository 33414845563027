

export interface Reward {
  id: string,
  type: string,
  status: RewardStatus,
}

export type RewardStatus =
  'Created'
  | 'InProgress'
  | 'Sent'
  | 'Claimed'
  | 'Error';


export function isRewardInProgress(reward: Reward|undefined){
  return reward && (reward.status === 'InProgress' || reward.status === 'Sent');
}

export interface RewardsState {
  list?: Reward[],
  processingRewardId?: string,
}

export const rewardsInitialState: RewardsState = {
  list: undefined
}
