import { Util } from '@/utils/util';
import { Binance, DappProcessingVersion, DataToSign, getBinancePublicRpc } from '@/api/blockchain/binance';
import { Erc20ABI, ProcessingABI } from '@/api/abi';
import { ExternalWalletLogos, TokenDef } from '@/model/common/Assets';
import { BinanceExternalDapp, DappConnectResp, DappListener } from '@/api/dapp/common';
import { WalletProviderStartError } from '@/model/wallet/provider/types';
import { Network, unknownNetwork } from '@/model/common/Network';
import { SignPayErrorCode } from '@/model/wallet/signPay/types';
import { isOutOfGasError, ProcessingVersion } from '@/api/blockchain/common';


const log = Util.getLog('metamask');
const listeners: DappListener[] = [];

let checkEvents = false;
let curAddress: string|undefined;
let curNetwork: Network|undefined;


function getEthereum(){
  return (window as any).ethereum;
}

function startCheckEvents(){

  // start listen BinanceChain after success address init
  if( !curAddress || checkEvents){
    return;
  }
  checkEvents = true;


  log.info('start monitoring Metamask state...');

  const ethereum = getEthereum();
  if( ! ethereum) {
    log.info('cannot find Metamask wallet');
    return;
  }

  ethereum.on('accountsChanged', (accounts: string[]|undefined) => {

    const newAddress = accounts && accounts.length>0? accounts[0] : undefined;
    if(curAddress === newAddress)
      return;

    log.info('Metamask account changed');
    curAddress = newAddress;

    listeners.forEach(listener => {
      if(listener.onAddressChanged)
        listener.onAddressChanged(newAddress);
    });
  });

  ethereum.on('chainChanged', (chainId: string) => {

    curNetwork = Binance.findNetwork(chainId);

    listeners.forEach(listener => {
      if(listener.onNetworkChanged)
        listener.onNetworkChanged(curNetwork);
    });
  });
}

interface MetamaskApiExt {
  addNetworkToWallet: (network: Network) => Promise<boolean>,
  addTokenToWallet: (token: TokenDef) => Promise<boolean>,
}

export const MetamaskApi: BinanceExternalDapp & MetamaskApiExt = {

  processingVersion(network): ProcessingVersion {
    return DappProcessingVersion[network];
  },

  hasExternalDapp(){
    return !! getEthereum();
  },

  filterCurrentValidTokens(tokens: TokenDef[]): TokenDef[]{
    const network = MetamaskApi.getCurrentNetwork()
    return network? tokens.filter(token => token.network === network) : [];
  },

  getCurrentNetwork(){
    return curNetwork;
  },


  getAddress(): string|undefined {
    return curAddress;
  },

  getStartError(): WalletProviderStartError {
    return 'need_binance_smart_chain';
  },

  async connect(): Promise<DappConnectResp>{

    const ethereum = getEthereum();

    try {

      const accounts: string[]|undefined = await ethereum.request({ method: 'eth_requestAccounts' });
      curAddress = accounts && accounts.length > 0? accounts[0] : undefined;

    } catch (e: any){

      log.warn('cannot get accounts', e);

      const msg = (e.message || e.toString()).toLowerCase();
      if(msg.includes('already processing')
          || msg.includes('already pending')) {
        return { waitOtherOperation: true };
      }

      return {address: undefined, network: undefined};
    }

    const chainId = await ethereum.request({ method: 'eth_chainId' });
    curNetwork = Binance.findNetwork(chainId);

    startCheckEvents();

    return {address: curAddress, network: curNetwork};
  },

  addListener(listener: DappListener){
    listeners.push(listener);
    startCheckEvents();
  },

  async approveForPay(
    tokenId: string,
    approveAmount: string,
    ownerAddress: string,
  ): Promise<string> {

    if(!curNetwork)
      throw unknownNetwork();

    const processingVersion = MetamaskApi.processingVersion(curNetwork);
    const {router} = Binance.processingInfo(curNetwork, processingVersion);

    const txParams: DataToSign = await Binance.prepareToSign(
      curNetwork,
      tokenId,
      Erc20ABI,
      ownerAddress,
      (methods) => methods.approve(router, approveAmount)
    );

    const txHash = await getEthereum().request({
      method: 'eth_sendTransaction',
      params: [txParams],
    });

    return txHash;
  },

  async pay(
    path: string[],
    amountInMax: string,
    amountOut: string,
    ownerAddress: string,
    toAddress: string,
    deadline: Date,
  ): Promise<string> {

    if(!curNetwork)
      throw unknownNetwork();

    const processingVersion = MetamaskApi.processingVersion(curNetwork);
    const {processing} = Binance.processingInfo(curNetwork, processingVersion);
    const deadlineSec = Math.floor(deadline.getTime() / 1000);

    const txParams: DataToSign = await Binance.prepareToSign(
      curNetwork,
      processing,
      ProcessingABI[processingVersion],
      ownerAddress,
      (methods) => methods.payERC(toAddress, path, amountInMax, amountOut, deadlineSec)
    );

    const txHash = await getEthereum().request({
      method: 'eth_sendTransaction',
      params: [txParams],
    });

    return txHash;

  },

  getSignPayErrorCode(e: any): SignPayErrorCode {

    const msg = (e.message || e.error || e.toString()) as string;

    let code: SignPayErrorCode|undefined;
    if(e.code === 4001)
      code = 'SignCanceled';
    else if(msg === 'TRANSACTION_EXPIRATION_ERROR')
      code = 'TransactionExpired';
    else if(isOutOfGasError(msg))
      code = 'LowGasToInvoke'
    else
      code = 'InvalidWalletState';

    return code;
  },

  async addNetworkToWallet(network: Network): Promise<boolean>{

    const validNetwork = network === 'BinanceMainNet'
      || network === 'BinanceTestNet';

    const chainId = Binance.findChainId(network);
    const ethereum = getEthereum();

    if( !validNetwork || !chainId || !ethereum)
      return false;

    const chainName = network === 'BinanceMainNet'?
      'Binance Smart Chain'
      : 'Binance Smart Chain - Testnet';

    const rpcUrls = [getBinancePublicRpc(network)];

    const blockExplorerUrls = network === 'BinanceMainNet'?
      ['https://bscscan.com']
      : ['https://testnet.bscscan.com'];

    try {

      const result = await ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [{
          chainId,
          chainName,
          nativeCurrency: {
            name: 'BNB',
            symbol: 'BNB',
            decimals: 18,
          },
          rpcUrls,
          blockExplorerUrls,
        }],
      });

      log.info('add network result', result);

      // https://docs.metamask.io/guide/rpc-api.html#other-rpc-methods
      return result === null;

    } catch (e: any){
      log.info('cannot addNetworkToWallet', e);
      return false;
    }
  },

  async addTokenToWallet(token: TokenDef): Promise<boolean> {

    const ethereum = getEthereum();

    if( !ethereum)
      return false;

    try {

      let logo = token.family? ExternalWalletLogos[token.family] : undefined;
      if(!logo)
        logo = token.logo;

      const result = await ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address: token.tokenId,
            symbol: token.abbr,
            decimals: token.decimals,
            image: logo,
          },
        },
      });

      log.info('add token result', result);

      // https://docs.metamask.io/guide/rpc-api.html#other-rpc-methods
      return result === true;

    } catch (e: any){
      log.info('cannot addTokenToWallet', e);
      return false;
    }
  }
}






