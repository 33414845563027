import React, { ReactNode, useLayoutEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from '@material-ui/core';
import { Space } from '@/components';
import { RootState } from '@/model/types';
import { Action } from '@/model/actions';
import { EmptyRender } from '@/utils/react-util';
import { WalletView } from '@/pages/WalletPage/components/WalletView';
import { ReactComponent as ExpiredLogo } from '@/assets/ui/expired-logo.svg';
import { useI18n } from '@/i18n';
import { Config } from '@/config';
import { Goal, Metrika } from '@/api/metrika';
import { ErrorView } from '@/components/wallet/view/ErrorView';
import { MigrationInProgressView } from '@/components/wallet/view/MigrationInProgressView';
import { useMailtoLink } from '@/components/wallet';
import { GlobalMenuDialog } from '@/components/wallet/dialog/GlobalMenuDialog';
import { FooterLabel } from '@/components/wallet/common/FooterLabel';


const styles = makeStyles((theme: Theme) => {

  return {
    root: {
      justifyContent: 'space-between',
      marginTop: '64px',
    },
    mainWrapper: {
      width: '420px',
      flexShrink: 0,
      position: 'relative',
    },
    mainBlock: {
      width: '100%',
      minHeight: '604px',
      padding: '24px',
      backgroundColor: '#FFF',
      borderRadius: '16px',
      boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.24)',
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '40px'
    },
    mainContentWrapper: {
      width: '100%',
      flexGrow: 2,
      display: 'flex',
      flexDirection: 'row',
      '& > *': {
        width: '100%'
      }
    },
    spaceBlock: {
      width: '100%'
    },
  };
});



export function WalletPage(){

  const classes = styles();
  const dispatch = useDispatch();
  const i18n = useI18n();

  const globalLoading = useSelector((state: RootState) => state.global.loading);
  const loaded = useSelector((state: RootState) => state.init.loaded);
  const initError = useSelector((state: RootState) => state.init.initError);
  const needShowMigrationScreen = useSelector((state: RootState) => state.wallet.needShowMigrationScreen);
  const showGlobalMenu = useSelector((state: RootState) => state.global.showGlobalMenu);

  useLayoutEffect(()=>{
    if( !loaded){
      Action.init.LoadData()(dispatch);
    }
  }, [loaded, dispatch]);

  useLayoutEffect(()=>{
    if(loaded){
      Action.walletRestore.LoadData()(dispatch);
    }
  }, [loaded, dispatch]);

  const mailtoLink = useMailtoLink();


  let mainElem: JSX.Element | null = null;

  const inServiceMaintenance = Config.toggles['maintenance-mode'];

  if(inServiceMaintenance){
    mainElem = <ErrorView
      title={i18n.m('maintenance_mode.title')}
      subTitle={i18n.m('maintenance_mode.description', {
        link: (content: string): ReactNode => <Link href={mailtoLink}>{content}</Link>,
      })}
      customLogo={<ExpiredLogo/>}
    />;
    Metrika.reachOnce(Goal.err.Maintenance);
  }
  else if(initError === 'InvalidQuery'){

    mainElem = <ErrorView title={i18n.m('global_error.invalid_query.title')}/>;
    Metrika.reachOnce(Goal.err.InvalidToken);
  }
  else if(initError === 'ServiceUnavailable') {

    mainElem = <ErrorView title={i18n.m('global_error.service_unavailable.title')}/>;
    Metrika.reachOnce(Goal.err.ServerUnavailable);
  }
  else if(needShowMigrationScreen){

    mainElem = <MigrationInProgressView/>;
    Metrika.reachOnce(Goal.wallet.MigrationProcess);
  }
  else if(loaded){

    mainElem =  <WalletView/>;

    if(Metrika.alreadyReached(Goal.invoice.PayInProgress)){
      Metrika.removeReached(Goal.invoice.PayInProgress);
      Metrika.reachOnce(Goal.invoice.PaymentFailed);
    }
  }

  if( !mainElem) {
    if(globalLoading)
      return <EmptyRender msg="mainPage" details="long init loading"/>;
    else
      return <EmptyRender msg="mainPage" details="unknown screen to show"/>;
  }

  return (
    <>

      <Space
        direction="horizontal"
        size={0}
        className={clsx(classes.root, 'v2-root')}
      >

        {/* empty block for a center template logic */}
        <div className={clsx(classes.spaceBlock, 'v2-block-left')}/>

        <div className={clsx(classes.mainWrapper, 'v2-block-center')}>

          <div className={clsx(classes.mainBlock, 'v2-main')}>

            <div className={classes.mainContentWrapper}>
              { ! showGlobalMenu && mainElem}
              { showGlobalMenu && <GlobalMenuDialog/>}
            </div>
          </div>

        </div>

        <div className={clsx(classes.spaceBlock, 'v2-block-right')}/>

      </Space>

      <FooterLabel/>
    </>
  )
}
