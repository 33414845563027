import { Net } from '@/utils/axios';
import { WalletProviderType } from '@/model/wallet/provider/types';
import { LSCache } from '@/utils/storage';
import { Network } from '@/model/common/Network';
import { RestoreData } from '@/model/wallet/restore/types';
import { WalletInitInfo } from '@/model/walletInit/types';
import { OneSec } from '@/utils/date-util';

const getInitInfoTimeout = 10 * OneSec;
const currentPayInDeployCacheKey = 'current-pay-in-deploy';
const currentPayCacheKey = 'current-pay';
const restoreCacheKey = 'restore-data';


export interface PredictAddressReq {

  clientAddress: string;
  network: Network;
}

export interface PredictedAddressResp {
  address: string,
  isMigrationRequired: boolean,
}


export interface PrepareContractReq {
  clientAddress: string;
  invoiceId: string;
  network: Network;
  v: number,
  r: string,
  s: string,
}

export interface CustomerWalletInfo {
  owner: string,
  contractAddress?: string,
  hash?: string,
  status: WalletStatus
}

export type WalletStatus =
  'Pending'
  | 'Deployed'
  | 'Failed';

export interface PrepareContractResp {

  hash: string;
}

export interface PayInvoiceReq {
  customerAddress: string;
  invoiceId: string,
  path: string[],
  inAmountMax: number,
  outAmount: number;
  v: number,
  r: string,
  s: string,
}

export type DelegatePayStatus =
  | 'Pending'
  | 'Succeeded'
  | 'Failed'
  | 'Retrying'
  | 'Expired';

export interface DelegatedPayment {
  status: DelegatePayStatus,
  hash?: string,
}


export interface CurrentPay {
  invoiceId: string,
  wallet: WalletProviderType,
  network: Network,
  txId: string,
}

export interface CurrentPayInDeploy {
  invoiceId: string,
  wallet: WalletProviderType,
  walletAddress: string,
  network: Network,
  pageId: string,
}


export async function queryWalletInitInfo(): Promise<WalletInitInfo> {
  return Net.get('/wallet/init', {
    timeout: getInitInfoTimeout
  });
}


export async function queryPredictWalletAddress(
  req: PredictAddressReq,
  recaptchaToken: string
): Promise<PredictedAddressResp> {

  return Net.get(`/wallet/predict-wallet-address?clientAddress=${req.clientAddress}&network=${req.network}`, {
    headers: {
      'x-recaptcha': recaptchaToken
    }
  });
}

export async function queryDeployWallet(
  req: PrepareContractReq,
  recaptchaToken: string
): Promise<CustomerWalletInfo> {

  return Net.post('/wallet/deploy-wallet', req, {
    headers: {
      'x-recaptcha': recaptchaToken
    }
  });
}


export async function queryWalletStatus(clientAddress: string, network: Network): Promise<CustomerWalletInfo> {
  return Net.get(`/core/payments/wallet-status?customerAddress=${clientAddress}&blockchain=${network}`);
}



export async function queryDelegatePay(
  req: PayInvoiceReq,
  recaptchaToken: string
): Promise<DelegatedPayment> {
  return Net.post('/wallet/delegate-pay', req, {
    headers: {
      'x-recaptcha': recaptchaToken
    }
  });
}


export async function queryDemoWalletDelegatePay(
  invoiceId: string,
  recaptchaToken: string
): Promise<DelegatedPayment> {
  return Net.post('/demo-wallet/delegate-pay', {invoiceId}, {
    headers: {
      'x-recaptcha': recaptchaToken
    }
  });
}


export async function queryDelegatePayStatus(invoiceId: string): Promise<DelegatedPayment>{
  return Net.get(`/core/payments/delegate-pay/${invoiceId}`);
}


export function storePayInDeploy(data: CurrentPayInDeploy){
  LSCache.set(currentPayInDeployCacheKey, data);
}

export function restorePayInDeploy(): CurrentPayInDeploy|undefined {
  return LSCache.get(currentPayInDeployCacheKey);
}

export function clearPayInDeploy(){
  LSCache.remove(currentPayInDeployCacheKey);
}


export function storeCurrentPay(data: CurrentPay){
  LSCache.set(currentPayCacheKey, data);
}

export function restoreCurrentPay(): CurrentPay|undefined {
  return LSCache.get(currentPayCacheKey);
}

export function clearCurrentPay(){
  LSCache.remove(currentPayCacheKey);
}


export function storeRestoreData(data: RestoreData){
  LSCache.set(restoreCacheKey, data);
}

export function restoreRestoreData(): RestoreData|undefined {
  return LSCache.get(restoreCacheKey);
}

export function clearRestoreData(){
  LSCache.remove(restoreCacheKey);
}
