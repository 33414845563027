import { getAuthorizationHeader } from '@/api/auth';
import { Net } from '@/utils/axios';
import { Reward } from '@/model/rewards/types';
import { Network } from '@/model/common/Network';


export async function queryGetRewards(network: Network): Promise<Reward[]> {
  const authHeader = await getAuthorizationHeader();
  return Net.get(`/wallet/rewards?network=${network}`, {
    headers: {
      ...authHeader
    }
  })
}


export interface UseRewardReq {
  id: string,
  clientAddress: string,
}

export async function queryUseReward(req: UseRewardReq){
  const authHeader = await getAuthorizationHeader();
  return Net.post('/wallet/use-reward', req, {
    headers: {
      ...authHeader
    }
  })
}
