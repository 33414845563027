import { call, put, select, takeEvery } from 'redux-saga/effects';
import { ActionWithPayload, withErrorHandler, withSingleWorker } from '@/utils/redux/action-creator';
import { Action } from '@/model/actions';
import { RootState } from '@/model/types';
import { withGlobalLock } from '@/model/global/sagas';
import { mapTokensToView, WalletAssets } from '@/model/wallet/assets/types';
import { InnerWalletApi, InnerWalletProvider } from '@/api/dapp/inner';
import { DappSagas, isSelectedProvider } from '@/model/dapp/common/sagas';
import { queryOpenTestOnRamp } from '@/api/demo_on_ramp';
import { needRealOnRamp } from '@/model/onRamp/sagas';
import { OnRampProvider } from '@/model/onRamp/types';
import { withRetry } from '@/utils/promise';

function* setInnerProvider(){

  yield put(Action.wallet.SetLastRequestedProvider('inner').pure);
  yield DappSagas.selectPayToken(InnerWalletProvider);

  // reset loading old cred
  yield put(Action.torus.UpdateStateId().pure);

  yield put(Action.walletProvider.SetType('inner').pure);
  yield put(Action.walletProvider.SetDapp(InnerWalletProvider).pure);
}


function* reloadWallet(){

  const rootState = (yield select((state: RootState) => state)) as RootState;
  if( ! isSelectedProvider(rootState, 'inner'))
    return;

  const {cred} = rootState.torus;
  const {selectedToken} = rootState.walletProvider;
  const {assets: previousAssets} = rootState.wallet;

  if( ! cred || ! selectedToken)
    return;

  const {network} = selectedToken;
  const firstLoad = previousAssets === undefined;

  try {

    yield put(Action.wallet.StartReloadLoading().pure);

    const api = (yield call(InnerWalletProvider.getWalletApi, network, cred.privateKey)) as InnerWalletApi;

    const maxCalls = firstLoad? 3 : 1;
    const assets = (yield call(
      withRetry(api.getState, {maxCalls, name: 'getWalletBalance'}),
      [selectedToken])) as WalletAssets;

    mapTokensToView(assets);

    yield put(Action.wallet.SetAddress(api.contractAddress).pure)
    yield put(Action.wallet.SetAssets(assets).pure);

    yield put(Action.walletRestore.SetSuccessProvider('inner').pure);
    yield put(Action.walletRestore.SaveData().pure);
  }
  catch (e: any){
    yield DappSagas.onCannotReload(e);
  }
  finally {
    yield put(Action.wallet.SetLoading(false).pure);
  }
}


function* openOnRamp(
  {payload: selectedProvider}: ActionWithPayload<OnRampProvider|undefined>
){

  const rootState = (yield select((state: RootState) => state)) as RootState;
  if( ! isSelectedProvider(rootState, 'inner'))
    return;

  const {cred} = rootState.torus;
  const {selectedToken} = rootState.walletProvider;

  if( ! cred || ! selectedToken)
    return;

  const {network} = selectedToken;


  const api = (yield call(InnerWalletProvider.getWalletApi, network, cred.privateKey)) as InnerWalletApi;
  const useRealOnRamp = needRealOnRamp(rootState);

  if(! useRealOnRamp){
    let transferred = false;

    try {

      yield put(Action.wallet.SetLoading(true).pure);

      transferred = yield call(queryOpenTestOnRamp, {
        clientAddress: api.contractAddress,
        amount: 0.1,
        token: selectedToken,
      });
    }
    finally {

      yield put(Action.wallet.SetLoading(false).pure);
    }

    if(transferred){

      yield reloadWallet();
      yield put(Action.onRamp.support.SetStatus('Success').pure);
    }
  }
  else {
    yield put(Action.onRamp.Start({
      selectedProvider,
      address: api.contractAddress,
      network,
      value: {
        currency: selectedToken.currency,
        amount: 0,
      }
    }).pure);
  }
}


function onLogout(){
  InnerWalletProvider.clearCache();
}


export default function* rootSaga(): Generator {

  yield takeEvery(Action.dapp.inner.SetInnerProvider.type,
    withErrorHandler(
      setInnerProvider
    ));

  yield takeEvery(Action.dapp.inner.InitWallet.type,
    withGlobalLock(
      withErrorHandler(
        withSingleWorker(
          reloadWallet
        ))));

  yield takeEvery(Action.wallet.Reload.type,
    withErrorHandler(
      withSingleWorker(
        reloadWallet
      )));

  yield takeEvery(Action.wallet.OpenOnRamp.type,
    withErrorHandler(
      openOnRamp
    )
  );

  yield takeEvery(Action.wallet.Logout.type,
    withErrorHandler(
      onLogout
    )
  );
}
