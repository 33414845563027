import { LocalStorage } from '@/utils/storage';

const StoreKey = 'LANG';

export function getLangFromLocalStore(): string | undefined {
  return LocalStorage.getItem(StoreKey) || undefined;
}

export function saveLangToLocalStore(locale: string) {
  LocalStorage.setItem(StoreKey, locale);
}
