import { actionCreator } from '@/utils/redux/action-creator';


const creator = actionCreator('DAPP_COMMON');


export const DappAction = {

  ReloadSelectedToken:  creator.asyncAction('RELOAD_SELECTED_TOKEN'),

};

