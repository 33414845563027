import { Action, Reducer } from 'redux';
import { ActionWithPayload } from '@/utils/redux/action-creator';

export interface ActionType<T, P> extends Action<T> {
  type: T;
  payload: P;
}

export function createReducer<S>(
  initialState: S,
  handlers: { [k in string]: (state: S, action: ActionType<any, any>) => S },
): Reducer<S> {
  const r = (state: S, action: ActionType<any, any>): S =>
    handlers[action.type]?.(state, action) || state || initialState;
  return r as Reducer<S>;
}



export function createStateHandlers<S>(){

  return {

    /* payloadToField */
    p2f<P>(field: keyof S){
      return payloadToFieldImpl<S, P>(field);
    },

    /** payloadToFieldSafe */
    p2fs<P, O>(field: keyof S, payloadExtractor?: (payload:P)=> O){
      return payloadToFieldImpl(field, payloadExtractor);
    },

    /** toField */
    tf<O>(field: keyof S, value: O) {
      return payloadToFieldImpl(field, ()=> value);
    },
  }
}


function payloadToFieldImpl<S, P>(fieldKey: keyof S, payloadExtractor?: (payload:P)=> any){
  return function impl(state:S, {payload}: ActionWithPayload<P>): S {
    return {
      ...state,
      [fieldKey]: payloadExtractor? payloadExtractor(payload) : payload
    }
  }
}
