import { Net } from '@/utils/axios';


export interface CheckCodeResp {
  token: string,
}


export async function queryAuth0SendCode(email: string, recaptchaToken: string): Promise<string> {
  return Net.post('/auth0/send-code', {email}, {
    headers: {
      'x-recaptcha': recaptchaToken
    }
  });
}

export async function queryAuth0CheckCode(
  email: string,
  code: string,
  recaptchaToken: string
): Promise<CheckCodeResp> {
  return Net.post('/auth0/check-code', {email, code}, {
    headers: {
      'x-recaptcha': recaptchaToken
    }
  });
}
