import { actionCreator } from '@/utils/redux/action-creator';
import { OnRampLastOperation, OnRampOperation, OnRampReq, OnRampShowMode, OnRampStatus } from '@/model/onRamp/types';
import { Currency, FiatCurrency } from '@/model/common/Money';


const creator = actionCreator('ON_RAMP');


export const OnRampAction = {

  Start:            creator.syncActionWithPayload<OnRampReq>('START'),
  Reset:            creator.syncAction('RESET'),
  SetProvider:      creator.syncActionWithPayload<OnRampShowMode>('SET_PROVIDER'),
  InitBaseCurrency: creator.syncAction('INIT_BASE_CURRENCY'),
  SetBaseCurrency:  creator.syncActionWithPayload<FiatCurrency>('SET_BASE_CURRENCY'),
  SetOperationStatus: creator.syncActionWithPayload<OnRampOperation|undefined>('SET_OPERATION_STATUS'),

  support: {
    PrepareOnRamp:    creator.syncAction('SUPPORT_PREPARE_ON_RAMP'),
    SetBaseCurrency:  creator.syncActionWithPayload<FiatCurrency>('SUPPORT_SET_BASE_CURRENCY'),
    SetBaseAmount:    creator.syncActionWithPayload<number>('SUPPORT_SET_BASE_AMOUNT'),
    SetCryptoCurrency: creator.syncActionWithPayload<Currency>('SUPPORT_SET_CRYPTO_CURRENCY'),
    SetCryptoAmount:  creator.syncActionWithPayload<number>('SUPPORT_SET_CRYPTO_AMOUNT'),
    SetFrameUrl:      creator.syncActionWithPayload<string>('SUPPORT_SET_FRAME_URL'),
    SetStatus:        creator.syncActionWithPayload<OnRampStatus>('SUPPORT_SET_STATUS'),
    SetLastProcessingOperation:
      creator.syncActionWithPayload<OnRampLastOperation|undefined>('SUPPORT_SET_LAST_OPERATION')
  }
};

