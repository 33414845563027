import { Button, Typography } from '@material-ui/core';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeCommonStyles, Space } from '@/components';
import { RootState } from '@/model/types';
import { EmptyRender } from '@/utils/react-util';
import { Action } from '@/model/actions';
import { useI18n } from '@/i18n';
import { WalletAddressPanel } from '@/components/wallet/panel/WalletAddressPanel';
import { WalletDetailsPanel } from '@/components/wallet/panel/WalletDetailsPanel';
import { WalletBalancePanel } from '@/components/wallet/panel/WalletBalancePanel';
import { SelectTokenDialog } from '@/components/wallet/dialog/SelectTokenDialog';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import { HeaderWithMenu } from '@/components/wallet/common/HeaderWithMenu';
import { WithdrawalDialog } from '@/components/wallet/dialog/WithdrawalDialog';

const styles = makeStyles((theme: Theme) => {

  return {
    root: {
      '& .add-deposit-amount':{
        textAlign: 'center',
      }
    },
    title: {
      fontSize: '32px',
      lineHeight: '40px',
      fontWeight: 'normal',
      wordBreak: 'break-all',
    },
  }
});
const commonStyles = makeCommonStyles();


export function WalletBalance(){

  const i18n = useI18n();
  const dispatch = useDispatch();
  const classes = styles();
  const commonClasses = commonStyles();

  const [prevSelectedToken, setPrevSelectedToken] = useState<string>();

  const dialog = useSelector((state: RootState) => state.wallet.dialog);
  const payTokens = useSelector((state: RootState) => state.walletProvider.payTokens);
  const selectedToken = useSelector((state: RootState) => state.walletProvider.selectedToken);
  const loading = useSelector((state: RootState) => state.wallet.loading);
  const address = useSelector((state: RootState) => state.wallet.address);
  const delayedReloadRequest = useSelector((state: RootState) => state.wallet.delayedReloadRequest);


  // use reload after dialog closed and find flag about it
  useEffect(()=>{
    if(dialog === 'none' && delayedReloadRequest){
      Action.wallet.SetDelayedReloadRequest(false)(dispatch);
      Action.wallet.Reload()(dispatch);
    }
  }, [dialog, delayedReloadRequest, dispatch]);

  // token changed event
  useLayoutEffect(()=>{
    if(!!selectedToken && selectedToken.tokenId !== prevSelectedToken){
      setPrevSelectedToken(selectedToken.tokenId);
    }
  }, [selectedToken, prevSelectedToken]);


  if( ! address
    || ! selectedToken
    || payTokens.length === 0)
    return <EmptyRender msg="WalletPay" details={{address, selectedToken, payTokens}}/>;

  if(dialog === 'walletDetails')
    return <WalletDetailsPanel/>;
  if(dialog === 'allTokens')
    return <SelectTokenDialog/>;
  if(dialog === 'withdrawal')
    return <WithdrawalDialog/>;


  return (
    <Space direction="vertical" className={classes.root}>

      <HeaderWithMenu>
        <Typography
          color="textPrimary"
          className={classes.title}>
          {i18n.m('balance.title')}
        </Typography>
      </HeaderWithMenu>

      <WalletAddressPanel/>

      <WalletBalancePanel
        title={i18n.m('balance.token')}
        disabled={loading}
      />

      <div className={commonClasses.spaceBlock}/>

      <Button
        variant="contained"
        color="primary"
        type="submit"
        onClick={()=>{
          Action.wallet.SetDialog('withdrawal')(dispatch);
        }}
        data-test="open-withdrawal"
      >
        {i18n.m('balance.open_withdrawal')}
      </Button>

    </Space>
  );
}
