import { rootStore } from '@/hocs/withStore/configureStore';
import { Action } from '@/model/actions';
import { isFinalPayStep } from '@/model/wallet/signPay/types';


export function reloadViewAfterDAppEvent(){

  const { payState: signPayState } = rootStore.getState().walletSignPay;

  // close sign pay dialog if need
  if( ! isFinalPayStep(signPayState)){
    rootStore.dispatch(Action.walletSignPay.Reset().pure);
  }

  rootStore.dispatch(Action.walletProvider.UpdateStateId().pure);
}


export function unlimitedApproveAmount(): string{
  return '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';
}
