import { WalletProviderType } from '@/model/wallet/provider/types';
import { WalletAssets } from '@/model/wallet/assets/types';


export type WalletDialog =
  'none'
  | 'walletDetails'
  | 'onRampSelect'
  | 'onRampByCard'
  | 'onRampByExchange'
  | 'onRampByTransfer'
  | 'signPay'
  | 'allTokens'
  | 'withdrawal';

export type LoadingType = 'reload';



export interface WalletState {
  loading: boolean,
  loadingType?: LoadingType,
  assets?: WalletAssets,
  address?: string,
  lastRequestedProvider?: WalletProviderType,
  dialog: WalletDialog,
  delayedReloadRequest: boolean,
  needShowMigrationScreen?: boolean,
  migrationScreenShown?: boolean,
}

export const initialState: WalletState = {
  loading: false,
  dialog: 'none',
  delayedReloadRequest: false,
};
