import React, { useEffect } from 'react';
import { Util } from '@/utils/util';

const log = Util.getLog('react-util');

const emptyRenderLogTimeout = 3000;
let emptyRenderLogged = false;

export interface EmptyRenderProps {
  msg: string,
  details?: any,
}

/** Show empty screen with error log if this screen will not be unmounted after reasonable time */
export function EmptyRender(
  {
    msg,
    details,
  }: EmptyRenderProps
){

  useEffect(() => {

    const logTimerId = setTimeout(()=>{

      if(emptyRenderLogged)
        return;
      emptyRenderLogged = true;
      log.error('show empty render:', msg, {timeout: emptyRenderLogTimeout, details});

    }, emptyRenderLogTimeout);

    return () => {
      clearTimeout(logTimerId);
    };
  }, [msg, details]);

  return (<></>)
}
