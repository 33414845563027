import { takeEvery } from 'redux-saga/effects';
import { Action } from '@/model/actions';
import { ActionWithPayload, withErrorHandler, withSingleWorker } from '@/utils/redux/action-creator';
import { BinanceWalletApi } from '@/api/dapp/binance_wallet';
import { DappSagas } from '@/model/dapp/common/sagas';
import { OnRampProvider } from '@/model/onRamp/types';


// external changes
BinanceWalletApi.addListener(DappSagas.dappListener(BinanceWalletApi, 'binanceWallet'));


function* setBinanceProvider(){
  yield DappSagas.connectAndSetProvider(BinanceWalletApi, 'binanceWallet');
}


function* reloadWallet(){
  yield DappSagas.reloadWallet(BinanceWalletApi, 'binanceWallet');
}

function* openOnRamp(
  {payload}: ActionWithPayload<OnRampProvider|undefined>
){
  yield DappSagas.openOnRamp(BinanceWalletApi, 'binanceWallet', payload);
}




export default function* rootSaga(): Generator {

  yield takeEvery(Action.dapp.binanceWallet.SetBinanceProvider.type,
    withErrorHandler(
      setBinanceProvider
    ));

  yield takeEvery(Action.wallet.Reload.type,
    withErrorHandler(
      withSingleWorker(
        reloadWallet
      )));


  yield takeEvery(Action.wallet.OpenOnRamp.type,
    withErrorHandler(
      openOnRamp
    )
  );
}
