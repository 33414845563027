import { rootStore } from '@/hocs/withStore/configureStore';
import { TokenDef } from '@/model/common/Assets';
import { BaseDapp } from '@/api/dapp/common';
import { Money } from '@/model/common/Money';


export type WalletProviderType =
  'none'
  | 'inner'
  | 'binanceWallet'
  | 'metamask'
  | 'demoWallet'

export type WalletProviderStartError =
  'access_denied'
  | 'need_binance_smart_chain'
  | 'unsupported_token'
  | 'wait_other_operation'
  | 'cannot_load_first_balance';


export function isProviderStateOutdated(stateId: number){
  const curStateId = rootStore.getState().walletProvider.stateId;
  return curStateId !== stateId;
}


export interface WalletProviderState {
  type: WalletProviderType,
  starting: boolean,
  startError?: WalletProviderStartError,
  stateId: number,
  dapp?: BaseDapp,
  payTokens: TokenDef[],
  selectedToken?: TokenDef,
  selectedQuote?: Money,
}

export const initialState: WalletProviderState = {
  type: 'none',
  stateId: 0,
  starting: false,
  payTokens: [],
}
