import 'fontsource-roboto';

import React from 'react';
import { Route, Router, Switch } from 'react-router-dom';

import { history } from '@/hocs/withStore/configureStore';
import {
  withAppParams,
  withGlobalBackdrop,
  withGlobalErrorHandler,
  withI18n,
  withI18nSupport,
  withIFrameDetect,
  withMobileDetect, withRestoreLocalStorage,
  withStore,
  withTheme,
} from '@/hocs';
import withAppState from '@/hocs/withAppState';
import { StringUtil } from '@/utils/string-util';
import { WalletPage } from '@/pages/WalletPage';


export function getAppPath(): string {

  const path = StringUtil.removeBegin(window.location.pathname, '/');

  let appPath = path.split('/')[0];
  appPath = `/${appPath}`;

  return appPath;
}

const Routes: React.FC = () => (
  <div>
    <Switch>
      <Route component={WalletPage} />
    </Switch>
  </div>
);


const App: React.FC = () => {
  return (
    <Router history={history}>
      <Routes />
    </Router>);
}

export default
withStore(
  withMobileDetect(
    withIFrameDetect(
      withAppParams(
        withTheme(
          withGlobalBackdrop(
            withRestoreLocalStorage(
              withI18nSupport(
                withI18n(
                  withGlobalErrorHandler(
                    withAppState(
                      App
                    )))))))))));
