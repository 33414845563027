import { Network } from '@/model/common/Network';
import { Currency, FiatCurrency, Money } from '@/model/common/Money';

export type OnRampProvider = 'Moonpay' | 'Instaramp';
export type OnRampMode = 'widget' | 'popup';

export interface OnRampShowMode {
  provider: OnRampProvider,
  mode: OnRampMode,
}

export type OnRampStatus =
  'Closed'
  | 'Preparing'
  | 'Shown'
  | 'Success';


export interface OnRampReq {
  network: Network,
  address: string,
  value: Money,
  selectedProvider?: OnRampProvider,
}

export type OnRampOperationStatus = 'Created' | 'InProgress' | 'Succeed' | 'Failed' | 'Unknown';

export interface OnRampOperation {
  id: string,
  status: OnRampOperationStatus,
}

export interface OnRampLastOperation extends OnRampOperation {
  provider: OnRampProvider
}

export interface OnRampState {
  provider?: OnRampProvider,
  showMode?: OnRampMode,
  status: OnRampStatus,
  req?: OnRampReq,
  baseCurrency?: FiatCurrency,
  baseAmount?: number,
  cryptoAmount?: number,
  cryptoCurrency?: Currency,
  frameUrl?: string,
  operation?: OnRampOperation,
  lastProcessingOperation?: OnRampLastOperation,
}

export const initialState: OnRampState = {
  status: 'Closed',
}
