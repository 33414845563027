import { call, put, takeEvery } from 'redux-saga/effects';
import jwt_decode from 'jwt-decode';
import {
  clearAuth,
  ExternalSourceLoginReq,
  WalletSessionResp,
  getCachedAuth,
  initAuthData,
  queryLoginByExternalSource, queryLoginByTestUser,
} from '@/api/auth';
import { getRecaptchaToken } from '@/api/support/recaptcha3';
import { Action } from '@/model/actions';
import { AuthData } from '@/model/auth/types';
import { Util } from '@/utils/util';
import { withErrorHandler } from '@/utils/redux/action-creator';
import { Config } from '@/config';
import { InvoiceStat } from '@/api/invoce_stat';

const log = Util.getLog('auth/sagas');


export function* loginByExternalSource(req: ExternalSourceLoginReq){

  const oldAuth = getCachedAuthByExternalSource(req);
  if(oldAuth){

    log.info('using old auth data', {type: oldAuth.type});
    InvoiceStat.setClient(oldAuth.email);

    yield put(Action.auth.support.SetAuth(oldAuth).pure);
    return;
  }

  const recaptchaToken = (yield call(getRecaptchaToken, 'loginByExternalSource')) as string;
  const respData = (yield call(queryLoginByExternalSource, req, recaptchaToken)) as WalletSessionResp;
  const authData = initAuthData(req.authProvider, respData);

  InvoiceStat.setClient(authData.email);
  yield put(Action.auth.support.SetAuth(authData).pure);
}


export function* loginByTestUser(){

  const oldAuth = getCachedAuthByTestUser();
  if(oldAuth){
    log.info('using old auth data', {type: oldAuth.type});
    yield put(Action.auth.support.SetAuth(oldAuth).pure);
    return;
  }

  const recaptchaToken = (yield call(getRecaptchaToken, 'loginByTestUser')) as string;
  const respData = (yield call(queryLoginByTestUser, recaptchaToken)) as WalletSessionResp;
  const authData = initAuthData('google', respData);

  yield put(Action.auth.support.SetAuth(authData).pure);
}


function getCachedAuthByExternalSource(req: ExternalSourceLoginReq): AuthData|undefined {

  const oldData = getCachedAuth();
  if( ! oldData)
    return undefined;

  if(oldData.authProvider !== req.authProvider)
    return undefined;

  const reqDecoded = jwt_decode(req.authToken) as any;

  // old session request
  if(oldData.type === 'email'
      && oldData.email === reqDecoded.email){
    return oldData;
  }

  return undefined;
}

function getCachedAuthByTestUser(): AuthData|undefined {
  const oldData = getCachedAuth();
  if( ! oldData)
    return undefined;

  // test auth for non-prod env
  if(oldData.type === 'test' && ! Config.IsProd){
    return oldData;
  }

  return undefined;
}


function onLogout(){
  clearAuth();
  InvoiceStat.logout();
}


export default function* rootSaga(): Generator {

  yield takeEvery(Action.wallet.Logout.type,
    withErrorHandler(
      onLogout
    ));
}
