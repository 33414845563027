import { utils } from 'ethers';

export type PackType = 'address' | 'uint256' | 'string';

export interface Sign {
  v: number;
  r: string;
  s: string;
}

export function toHexString(val: number){
  return `0x${val.toString(16)}`;
}

export function keccak256Pack(types: PackType[], params: any[]){
  const packed = utils.solidityPack(types, params);
  return utils.keccak256(packed);
}

export function signDigest(digest: string, secret: string): Sign {

  const key = secret.startsWith('0x')? secret : `0x${secret}`;
  const sign = new utils.SigningKey(key).signDigest(digest);

  return {
    r: sign.r,
    s: sign.s,
    v: sign.v,
  }
}



