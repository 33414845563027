import { call, put, select, takeEvery } from 'redux-saga/effects';
import { TorusLoginResponse } from '@toruslabs/torus-direct-web-sdk/types/src/handlers/interfaces';
import { ActionWithPayload, withErrorHandler, withSingleWorker } from '@/utils/redux/action-creator';
import { Action } from '@/model/actions';
import { queryLoginByToken, queryStartLogin } from '@/api/torus';
import { ChildWindows } from '@/utils/window-util';
import { rootStore } from '@/hocs/withStore/configureStore';
import { SessionKey } from '@/model/common/Session';
import { LSCache } from '@/utils/storage';
import { StartLoginReq, StartTokenLoginReq } from '@/model/torus/actions';
import { decryptData, encryptData } from '@/api/support/data_encryption';
import { withGlobalLock } from '@/model/global/sagas';
import { Util } from '@/utils/util';
import { queryClearSessionKey, queryGetSessionKey } from '@/api/session';
import { RootState } from '@/model/types';
import { TorusCred, TorusLoginType } from '@/model/torus/types';
import { Config } from '@/config';
import { Goal, Metrika } from '@/api/metrika';
import { DefLang } from '@/model/common/Lang';
import { OpenIdProvider } from '@/model/common/OpenId';
import { loginByExternalSource, loginByTestUser } from '@/model/auth/sagas';
import { InvoiceStat } from '@/api/invoce_stat';
import { firstLoadRewards } from '@/model/rewards/sagas';

const log = Util.getLog('torus/sagas');

const canUseExternalKey = Config.Environment !== 'prod';
const externalKeyStubTorusToken = 'external-key-stub';

let sessionKey: string|undefined;
let inLogin = false;

ChildWindows.addListener({

  onClosed(){
    setTimeout(()=>{
      if(inLogin)
        rootStore.dispatch(Action.torus.SetEntering(true).pure);
    }, 1000);
  }

});

function getOldSessionCred(key: string): TorusCred|undefined {

  const dataWrapper = LSCache.get(SessionKey.torus);
  let oldCred: TorusCred|undefined = decryptData<TorusCred>(key, dataWrapper);

  // invalid data format
  if( ! oldCred || ! oldCred.privateKey){
    oldCred = undefined;
  }

  return oldCred;
}

function storeSessionCred(key: string, cred: TorusCred|undefined){
  const dataWrapper = encryptData(key, cred);
  LSCache.set(SessionKey.torus, dataWrapper);
}

function removeSessionCred(){
  LSCache.set(SessionKey.torus, undefined);
}


function* restoreCred(){

  const rootState = (yield select((state: RootState) => state)) as RootState;
  const demoMode = Util.parseBool(rootState.params['demo-mode']);

  const oldStateId = rootState.torus.stateId;
  let oldCred;

  try {

    sessionKey = (yield call(queryGetSessionKey)) as string|undefined;
    oldCred = ! sessionKey || demoMode? undefined : getOldSessionCred(sessionKey);

  } catch (e: any){
    log.error('cannot restore cred', e);
  }
  finally {

    const curStateId = (yield select((state: RootState) => state.torus.stateId)) as number;

    if(curStateId === oldStateId){

      yield setCred(oldCred || null);

      if(oldCred){
        Metrika.reach(Goal.torus.UseOldCred);
      }

      if(oldCred
          && oldCred.userInfo.idToken === externalKeyStubTorusToken){
        InvoiceStat.setClient('private-key');
      }

    } else {
      log.warn('restore cred is outdated')
    }

  }
}


function* startLogin(
  {payload}: ActionWithPayload<StartLoginReq>
){

  if(inLogin)
    return;
  inLogin = true;

  const rootState = (yield select((state: RootState) => state)) as RootState;
  const lang = rootState.global.lang || DefLang;
  const externalPrivateKey = rootState.params['torus-private-key'];
  const useExternalKey = canUseExternalKey && !!externalPrivateKey;
  const {type} = payload;

  // will be set by ChildWindows.addListener (see file top)
  yield put(Action.torus.SetEntering(false).pure);

  yield put(Action.torus.SetLoading(true).pure);
  yield put(Action.torus.SetLastRequestedLoginType(type).pure);

  try {

    if(useExternalKey){
      log.warn('use external private key instead of real torus');
      InvoiceStat.setClient('private-key');
    }

    Metrika.reach(Goal.torus.StartEnter);

    const torusResp: TorusLoginResponse = useExternalKey?
      // external key
      {
        privateKey: externalPrivateKey,
        userInfo: {
          idToken: externalKeyStubTorusToken
        }
      } as any
      // real torus
      : (yield call(queryStartLogin, type, lang)) as TorusLoginResponse;

    yield processTorusResp(torusResp, type);

  } catch (e: any){

    const msg = e.message;
    const validError = msg === 'Modal has been closed'
        || msg === 'user closed popup';

    if( ! validError) {
      Metrika.reach(Goal.torus.LoginError);
      throw e;
    }

  } finally {
    inLogin = false;
    yield put(Action.torus.SetEntering(false).pure);
    yield put(Action.torus.SetLoading(false).pure);
  }
}


function* startTokenLogin(
  {payload: {token, type, email}}: ActionWithPayload<StartTokenLoginReq>
){

  if(inLogin)
    return;
  inLogin = true;


  yield put(Action.torus.SetEntering(true).pure);
  yield put(Action.torus.SetLoading(true).pure);
  yield put(Action.torus.SetLastRequestedLoginType(type).pure);

  try {

    const torusResp = (yield call(queryLoginByToken, type, email, token)) as TorusLoginResponse;
    yield processTorusResp(torusResp, type);
  }
  catch (e){
    Metrika.reach(Goal.torus.LoginError);
    yield put(Action.torus.LoginByTokenError().pure);
    throw e;
  }
  finally {
    inLogin = false;
    yield put(Action.torus.SetEntering(false).pure);
    yield put(Action.torus.SetLoading(false).pure);
  }
}

function* processTorusResp(torusResp: TorusLoginResponse, type: TorusLoginType){

  const cred: TorusCred = {
    ...torusResp,
    loginType: type,
  }

  yield setCred(cred);

  // get encode key and store cred
  try {
    sessionKey = (yield call(queryGetSessionKey)) as string;
  } catch (e){
    log.error('cannot get session key', e);
  }

  if(sessionKey)
    storeSessionCred(sessionKey, cred);
}



function* setCred(cred: TorusCred|null|undefined){

  if( cred){

    try {

      // real auth
      if(cred.userInfo.idToken
          && cred.userInfo.idToken !== externalKeyStubTorusToken){

        const {idToken: authToken} = cred.userInfo;
        const authProvider: OpenIdProvider = cred.loginType === 'google'? 'google' : 'auth0';
        yield loginByExternalSource({authProvider, authToken});

        // load email rewards
        yield firstLoadRewards();
      }
      // test auth for non-prod env
      else if(cred.userInfo.idToken === externalKeyStubTorusToken
          && ! Config.IsProd){
        yield loginByTestUser();
      }

    } catch (e){
      log.error('cannot login to wallet', e);
    }
  }

  yield put(Action.torus.SetCred(cred).pure);
}


function onLogout(){

  removeSessionCred();

  // async remove key from server
  queryClearSessionKey().catch(Util.onErr);
}

export default function* rootSaga(): Generator {

  yield takeEvery(Action.torus.RestoreCred.type,
    withGlobalLock(
      withErrorHandler(
        restoreCred
      )));

  yield takeEvery(Action.torus.RestoreCredAsync.type,
    withErrorHandler(
      restoreCred
    ));

  yield takeEvery(Action.torus.StartLogin.type,
    withErrorHandler(
      startLogin
    ));

  yield takeEvery(Action.torus.StartTokenLogin.type,
    withErrorHandler(
      withSingleWorker(
        startTokenLogin
      )));

  yield takeEvery(Action.wallet.Logout.type,
    withErrorHandler(
      onLogout
    ));

}
