import { ProcessingVersion } from '@/api/blockchain/common';


const defProcessingABI = [
  {
    name: 'balanceOf',
    inputs:[
      { name:'_stealth', type:'address'}
    ],
    outputs:[
      { name:'balance', type:'tuple'}
    ],
    constant:true,
    type:'function'
  },
  {
    name: 'payERC',
    inputs:[
      { name:'stealth', type:'address'},
      { name:'path', type:'address[]'},
      { name:'amountInMax', type:'uint256'},
      { name:'amountOut', type:'uint256'},
      { name:'deadline', type:'uint256'}
    ],
    outputs:[],
    constant: false,
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  }];

export const ProcessingABI: Record<ProcessingVersion, any[]> = {
  10: defProcessingABI,
  9: defProcessingABI,
  7: defProcessingABI,
  6: defProcessingABI,
}

export const Erc20ABI: any[] = [
  {
    name: 'allowance',
    inputs:[
      { name:'_owner', type:'address' },
      { name:'_spender', type:'address' },
    ],
    outputs:[
      { name: '', type: 'uint256'},
    ],
    stateMutability: 'view',
    payable: false,
    constant: true,
    type: 'function'
  },
  {
    name: 'balanceOf',
    inputs:[
      { name:'_owner', type:'address' }
    ],
    outputs:[
      { name:'balance', type:'uint256' }
    ],
    constant:true,
    type:'function'
  },
  {
    name: 'decimals',
    inputs:[],
    outputs:[
      { name:'', type:'uint8' }
    ],
    constant:true,
    type:'function'
  },
  {
    name: 'approve',
    inputs: [
      { name: '_spender', type: 'address' },
      { name: '_value', type: 'uint256' }
    ],
    outputs: [
      { name: '', type: 'bool' }
    ],
    constant: false,
    payable: false,
    stateMutability: 'nonpayable',
    type: 'function'
  }
];



export const PancakeRouterABI: any[] = [
  {
    name: 'getAmountsIn',
    inputs:[
      { name:'amountOut', type:'uint256' },
      { name:'path', type:'address[]' },
    ],
    outputs:[
      { name: '', type: 'uint256[]'},
    ],
    stateMutability: 'view',
    payable: false,
    type: 'function'
  }
];



export const IPancakePairABI: any[] = [
  {
    name: 'token0',
    inputs:[],
    outputs:[
      { name: '', type: 'address'},
    ],
    stateMutability: 'view',
    payable: false,
    type: 'function'
  },
  {
    name: 'token1',
    inputs:[],
    outputs:[
      { name: '', type: 'address'},
    ],
    stateMutability: 'view',
    payable: false,
    type: 'function'
  },
  {
    name: 'getReserves',
    inputs:[],
    outputs:[
      { name: 'reserve0', type: 'uint112'},
      { name: 'reserve1', type: 'uint112'},
      { name: 'blockTimestampLast', type: 'uint32'},
    ],
    stateMutability: 'view',
    payable: false,
    type: 'function'
  }
];


export const UniswapV2FactoryABI: any[] = [
  {
    name: 'getPair',
    inputs:[
      { name:'tokenA', type:'address' },
      { name:'tokenB', type:'address' },
    ],
    outputs:[
      { name: '', type: 'address'},
    ],
    stateMutability: 'view',
    payable: false,
    type: 'function'
  }
];

