import { call, select, takeEvery } from 'redux-saga/effects';
import { Action } from '@/model/actions';
import { ActionWithPayload, withErrorHandler, withSingleWorker } from '@/utils/redux/action-creator';
import { MetamaskApi } from '@/api/dapp/metamask';
import { DappSagas } from '@/model/dapp/common/sagas';
import { RootState } from '@/model/types';
import { Tokens } from '@/model/common/Assets';
import { Network } from '@/model/common/Network';
import { Config } from '@/config';
import { Goal, Metrika } from '@/api/metrika';
import { Util } from '@/utils/util';
import { OnRampProvider } from '@/model/onRamp/types';

// external changes
MetamaskApi.addListener(DappSagas.dappListener(MetamaskApi, 'metamask'));


function* setMetamaskProvider(){

  yield DappSagas.connectAndSetProvider(MetamaskApi, 'metamask');

  const rootState = (yield select((state: RootState) => state)) as RootState;
  const {startError, type} = rootState.walletProvider;
  const curNetwork = MetamaskApi.getCurrentNetwork();

  if(type === 'none'
      && startError === 'need_binance_smart_chain'
      && ! curNetwork){
    yield addBinanceNetworkReq();
  }
}


function* reloadWallet(){
  yield DappSagas.reloadWallet(MetamaskApi, 'metamask');
}


function* openOnRamp(
  {payload}: ActionWithPayload<OnRampProvider|undefined>
){
  yield DappSagas.openOnRamp(MetamaskApi, 'metamask', payload);
}


function* addBinanceNetworkReq(): Generator {

  const rootState = (yield select((state: RootState) => state)) as RootState;
  const {lastRequestedProvider} = rootState.wallet;

  if( lastRequestedProvider !== 'metamask')
    return;

  const demoMode = Util.parseBool(rootState.params['demo-mode'])
  const isProd = Config.IsProd;
  const network: Network = (isProd && ! demoMode)? 'BinanceMainNet' : 'BinanceTestNet';

  // add or switch target network in metamask
  Metrika.reach(Goal.metamask.SwitchToBscReq);
  const success = (yield call(MetamaskApi.addNetworkToWallet, network)) as boolean;
  if(!success)
    return;

  // try to open wallet again
  yield setMetamaskProvider();

  // add our token to metamask view
  const ourToken = isProd? undefined : Tokens.getCurrencyToken('btMNXe');
  if(ourToken){
    Metrika.reach(Goal.metamask.AddOurTokenReq);
    yield call(MetamaskApi.addTokenToWallet, ourToken);
  }
}

export default function* rootSaga(): Generator {

  yield takeEvery(Action.dapp.metamask.SetMetamaskProvider.type,
    withErrorHandler(
      setMetamaskProvider
    ));

  yield takeEvery(Action.wallet.Reload.type,
    withErrorHandler(
      withSingleWorker(
        reloadWallet
      )));

  yield takeEvery(Action.wallet.OpenOnRamp.type,
    withErrorHandler(
      openOnRamp
    )
  );

  yield takeEvery(Action.dapp.metamask.AddBinanceNetwork.type,
    withErrorHandler(
      withSingleWorker(
        addBinanceNetworkReq
      )));
}
