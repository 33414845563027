import { TokenBase, TokenDef } from '@/model/common/Assets';

export type RateState = 'empty' | 'loading' | 'success' | 'error'

export interface SwapPathReq {
  from: string,
  to: string,
  outPrice: number,
}

export function isSameGetSwapPathReqs(a: SwapPathReq|undefined, b: SwapPathReq|undefined){
  return a && b
    && a.to === b.to
    && a.from === b.from
    && a.outPrice === b.outPrice;
}

export interface SwapPath {
  path: TokenBase[],
  price: number,
}

export interface SwapState {
  rateState: RateState,
  swapPath?: SwapPath,
  swapPathReq?: SwapPathReq,
  selectedAlt?: TokenDef
}



export const initialState: SwapState = {
  rateState: 'empty'
}
