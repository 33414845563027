import { IconButton, Typography } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { ReactComponent as ArrowBackIcon } from '@/assets/icons/arrow-back.svg';
import { makeCommonStyles, makeMainStyles, Space } from '@/components';
import { Util } from '@/utils/util';
import { Goal, Metrika } from '@/api/metrika';
import { Action } from '@/model/actions';
import { ReactComponent as MenuIcon } from '@/assets/icons/global-menu.svg';

export interface DialogPageTitleProps {
  title: string,
  onClick: () => void,
  globalMenuLink?: boolean,
  className?: string,
}

const commonStyles = makeCommonStyles();
const mainStyles = makeMainStyles();

const styles = makeStyles((theme: Theme) => {
  return {
    back: {
      padding: '6px',
    }
  }
});

export function DialogPageTitle(
  {
    title,
    onClick,
    globalMenuLink,
    className,
  }: DialogPageTitleProps
){

  const dispatch = useDispatch();
  const commonClasses = commonStyles();
  const mainClasses = mainStyles();
  const classes = styles();

  const showGlobalMenuLink = Util.parseBool(globalMenuLink, true);

  return (
    <Space direction="horizontal" size={1} className={clsx(commonClasses.alignItemsCenter, className)}>

      <IconButton
        className={clsx(commonClasses.icon40, classes.back)}
        data-test="back"
        onClick={onClick}
      >
        <ArrowBackIcon/>
      </IconButton>

      <Typography className={mainClasses.title}>
        {title}
      </Typography>

      { showGlobalMenuLink &&
        <>
          <div className={commonClasses.spaceBlock}/>
          <IconButton
            className={clsx(commonClasses.icon40, commonClasses.iconPadding9)}
            data-test="open-global-menu"
            onClick={()=> {
              Metrika.reach(Goal.globalMenu.Show);
              Action.global.ShowGlobalMenu(true)(dispatch);
            }}>
            <MenuIcon />
          </IconButton>
        </>
      }
    </Space>
  )
}
