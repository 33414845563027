
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line import/no-named-default
import {default as AES} from 'crypto-js/aes';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line import/no-named-default
import {default as CryptoCore} from 'crypto-js/core';

import { Util } from '@/utils/util';

export interface PayloadWrapper {
  encoded: any
}

const log = Util.getLog('data_encryption');

export function encryptData(key: string, payload?: any): PayloadWrapper {

  try {

    const text = payload ? Util.toJson(payload) : '';
    const ciphertext = AES.encrypt(text, key).toString();

    return {
      encoded: ciphertext
    }

  } catch (e: any){

    log.warn('cannot encrypt data', e.message );

    return {
      encoded: undefined
    }
  }
}

export function decryptData<T>(key: string, payloadWrapper?: PayloadWrapper): T|undefined {

  if( ! payloadWrapper || Util.isBlank(payloadWrapper.encoded))
    return undefined;

  try {

    const bytes = AES.decrypt(payloadWrapper.encoded, key);
    const text = bytes.toString(CryptoCore.enc.Utf8);
    const payload = JSON.parse(text);

    return payload;
  }
  catch (e: any){
    log.warn('cannot decrypt data', e.message );
    return undefined;
  }
}
