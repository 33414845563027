import { TokenDef, Tokens, TokenState } from '@/model/common/Assets';
import { isSameAddress } from '@/model/common/Blockchain';


export function mapTokensToView(wallet: WalletAssets) {

  const mainToken = Tokens.findById(wallet.mainAsset.tokenId) || {};

  wallet.mainAsset = {
    ...wallet.mainAsset,
    ...mainToken,
  }

  wallet.otherAssets = wallet.otherAssets.map(state => {
    const token = Tokens.findById(state.tokenId) || {};
    return {
      ...state,
      ...token,
    }
  });
}


export interface WalletAssets {
  mainAsset: TokenState,
  otherAssets: TokenState[],
}


export function isEmptyWallet(wallet: WalletAssets){
  return wallet.mainAsset.balance === 0
    && ! wallet.otherAssets.find(asset => asset.balance > 0);
}

export function tokenBalance(wallet: WalletAssets, token: TokenDef): number|undefined {

  if(!wallet || !token)
    return undefined;

  const {tokenId} = token;

  if(isSameAddress(wallet.mainAsset.tokenId, tokenId))
    return wallet.mainAsset.balance;

  const asset = wallet.otherAssets.find(asset => isSameAddress(asset.tokenId, tokenId));
  return asset?.balance;
}


