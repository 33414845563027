

export interface ErrorWithCode {
  code?: string
}

export const ApiError = {

  common: {
    BadRequest:         'BadRequest',
    Unauthorized:       'Unauthorized',
    Forbidden:          'Forbidden',
    NotFound:           'NotFound',
    Unavailable:        'ServiceUnavailable',
    RateLimit:          'RateLimit',
    Unknown:            'Unknown',
    OutdatedRequest:    'OutdatedRequest',
    UnknownInput:       'UnknownInput',
    UnknownOperation:   'UnknownOperation',
    InvalidReqType:     'InvalidRequestType',
    GatewayTimeout:     'GatewayTimeout',
  },

  core: {
    InvoiceExpiresAtInvalid:  'InvoiceExpiresAtInvalid',
    CustomerWalletNotFound:   'CustomerWalletNotFound'
  },

  login: {
    BadEmail:       'login.BadEmail',
    BadCode:        'login.BadCode',
    CodeExpired:    'login.CodeExpired',
    TriesLimit:     'login.TriesLimit',
    EmailBlocked:   'login.EmailBlocked',
  }
};
