import React, { useLayoutEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/model/types';
import { Action } from '@/model/actions';
import { SelectWallet } from '@/pages/WalletPage/components/SelectWallet';
import { WalletBalance } from '@/pages/WalletPage/components/WalletBalance';


export function WalletView(){

  const dispatch = useDispatch();

  // monitoring provider changes
  useSelector((state: RootState) => state.walletProvider.stateId);

  const provider = useSelector((state: RootState) => state.walletProvider.type);
  const walletAddress = useSelector((state: RootState) => state.wallet.address);
  const torusCred = useSelector((state: RootState) => state.torus.cred);
  const showRestoringProgress = useSelector((state: RootState) => state.walletRestore.showRestoringProgress);

  const providerNotSelected = provider === 'none';
  const walletOpening = provider !== 'none' && !walletAddress;

  const showSelectWallets = providerNotSelected || walletOpening || showRestoringProgress;
  const showInnerWallet = !showSelectWallets && provider === 'inner' && walletAddress;
  const showExternalWallet = !showSelectWallets && provider !== 'inner';

  useLayoutEffect(()=>{

    if(provider !== 'inner'){
      Action.wallet.Reload()(dispatch);
    }
    else if(provider === 'inner' && !!torusCred && !walletAddress){
      Action.wallet.Reload()(dispatch);
    }
  }, [provider, torusCred, walletAddress, dispatch]);

  return (
    <>
      { showSelectWallets && <SelectWallet/>}
      { (showInnerWallet || showExternalWallet) && <WalletBalance/> }
    </>
  );
}
