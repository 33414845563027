import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { createStyles, Snackbar, SvgIcon, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { AlertTitle } from '@material-ui/lab';
import clsx from 'clsx';
import WarningOutlinedIcon from '@material-ui/icons/WarningOutlined';
import { RootState } from '@/model/types';
import { Action } from '@/model/actions';
import { ReactComponent as TopErrorIcon } from '@/assets/icons/top-error-icon.svg';
import { ReactComponent as TopSuccessIcon } from '@/assets/icons/top-success-icon.svg';
import { Color } from '@/components/Style';
import { Msg } from '@/i18n/Msg';

const Alert: React.FC<AlertProps> = (props: AlertProps) => <MuiAlert elevation={6} variant="filled" {...props} />;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    snackbar: {
      padding: theme.spacing(),
      top: '10px',
    },
    alert: {
      width: 'calc(100vw - 20px)',
      maxWidth: '600px',
      boxShadow: '0px 20px 40px rgba(0, 0, 0, 0.2)',
      borderRadius: '12px',
      backgroundColor: '#FFFFFF',
      color: Color.text.Second,
      fontSize: '14px',
      padding: '16px 24px',
      '& .MuiAlert-icon': {
        width: '40px',
        padding: '0px',
        margin: '0px',
        opacity: 1,
        marginRight: '14px',
      },
      '& .MuiAlert-message': {
        padding: '0',
      },
      '& .MuiAlert-action': {
        alignItems: 'start'
      },
      '& .MuiAlert-action button': {
        height: '20px'
      },
      '& .MuiAlert-action button:hover': {
        backgroundColor: 'inherit'
      }
    },
    alertTitle: {
      color: Color.text.Main,
      fontSize: '16px',
      fontWeight: 400,
    },
    errorAlert : {
      '& .MuiAlert-icon': {
        marginTop: '8px'
      },
      '& .MuiAlert-icon svg': {
        width: '36px',
        height: '25px',
      }
    },
    warningAlert: {
      '& .MuiAlert-icon svg': {
        width: '30px',
        height: '30px',
        color: Color.warning.Text,
      },
      '& .MuiAlertTitle-root': {
        marginTop: '0px'
      }
    },
    successAlert: {
      '& .MuiAlert-icon svg': {
        width: '30px',
        height: '30px',
      },
      '& .MuiAlertTitle-root': {
        marginTop: '0px'
      }
    }
  }),
);


export interface NotificationErrorProps {
  title: string,
  description: string,
  key?: string,
}

export function NotificationError(
  {
    title,
    description,
  }: NotificationErrorProps
){

  const classes = useStyles();
  const [closed, setClosed] = useState(false);

  if(closed)
    return (<></>);

  return (
    <Snackbar open className={classes.snackbar} anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
      <Alert
        icon={<SvgIcon component={TopErrorIcon} viewBox="0 0 36 25"/>}
        severity="error"
        className={clsx(classes.alert, classes.errorAlert)}
        onClose={()=>{
          setClosed(true);
        }}
      >
        <AlertTitle className={classes.alertTitle}>
          {title}
        </AlertTitle>
        {description}
      </Alert>
    </Snackbar>
  )
}

const Notifications: React.FC = () => {

  const classes = useStyles();
  const errors = useSelector((state: RootState) => state.global.errors);
  const warnings = useSelector((state: RootState) => state.global.warnings);
  const successes = useSelector((state: RootState) => state.global.successes);
  const dispatch = useDispatch();

  const successesToShow = successes.length > 0? [successes[0]] : [];
  const warningsToShow = warnings.length > 0? [warnings[0]] : [];
  const errorsToShow = errors.length > 0? [errors[0]] : [];

  return (
    <>

      {successesToShow.length > 0 && successesToShow.map(({ messageId, id }) => (
        <Snackbar open className={classes.snackbar} anchorOrigin={{ horizontal: 'center', vertical: 'top' }} key={id}>
          <Alert
            severity="success"
            icon={<TopSuccessIcon/>}
            className={clsx(classes.alert, classes.successAlert)}
            onClose={(): void => {
              Action.global.support.RemoveAllNotifications('success')(dispatch);
            }}>
            <AlertTitle className={classes.alertTitle}>
              <FormattedMessage id={messageId} />
            </AlertTitle>
          </Alert>
        </Snackbar>
      ))}

      {warningsToShow.length > 0 && warningsToShow.map(({ messageId, id }) => (
        <Snackbar open className={classes.snackbar} anchorOrigin={{ horizontal: 'center', vertical: 'top' }} key={id}>
          <Alert
            icon={<WarningOutlinedIcon />}
            severity="warning"
            className={clsx(classes.alert, classes.warningAlert)}
            onClose={(): void => {
              Action.global.support.RemoveAllNotifications('warning')(dispatch);
            }}
          >
            <AlertTitle className={classes.alertTitle}>
              <FormattedMessage id={Msg['common.warning_title']} />
            </AlertTitle>
            <FormattedMessage id={messageId} />
          </Alert>
        </Snackbar>
      ))}

      {errorsToShow.length > 0 && errorsToShow.map(({ messageId, id }) => (
        <Snackbar open className={classes.snackbar} anchorOrigin={{ horizontal: 'center', vertical: 'top' }} key={id}>
          <Alert
            icon={<SvgIcon component={TopErrorIcon} viewBox="0 0 36 25"/>}
            severity="error"
            className={clsx(classes.alert, classes.errorAlert)}
            onClose={(): void => {
              Action.global.support.RemoveAllNotifications('error')(dispatch);
            }}
          >
            <AlertTitle className={classes.alertTitle}>
              <FormattedMessage id={Msg['common.error_title']} />
            </AlertTitle>
            <FormattedMessage id={messageId} />
          </Alert>
        </Snackbar>
      ))}
    </>
  );
};

export default Notifications;
