import { Lang } from '@/model/common/Lang';
import { MsgKey } from '@/i18n/Msg';


export type NotificationType = 'error' | 'warning' | 'success';

export type Notification = {
  messageId: MsgKey;
  values?: Record<string, any>;
};

export type NotificationObj = Notification & {
  id: string;
};

export enum UIState {
  LOGIN = 'LOGIN',
  WALLET = 'WALLET',
  INVOICE = 'INVOICE',
}

export interface GlobalState {
  loading: boolean;
  successes: NotificationObj[];
  errors: NotificationObj[];
  warnings: NotificationObj[];
  lang?: Lang;
  showGlobalMenu: boolean,
}

export const initialState: GlobalState = {
  loading: false,
  successes: [],
  errors: [],
  warnings: [],
  showGlobalMenu: false,
};
