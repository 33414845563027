import { Space } from '@/components';
import clsx from 'clsx';
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import { useI18n } from '@/i18n';
import { Typography } from '@material-ui/core';
import { Color } from '@/components/Style';
import { ReactComponent as LogoIcon } from '@/assets/icons/footer-logo.svg';
import useMediaQuery from '@material-ui/core/useMediaQuery';


const styles = makeStyles((theme: Theme) => {

  return {
    root: {
      position: 'absolute',
      bottom: '26px',
      margin: '0 auto',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
    },
    mediumHeight: {
      bottom: '15px',
    },
    text: {
      color: Color.back.Fourth,
      fontSize: '14px',
      lineHeight: '20px',
    },
    logo: {
      marginTop: '2px',
      cursor: 'pointer',
    }
  }
});

export function FooterLabel(){

  const i18n = useI18n();
  const classes = styles();

  const smallHeight = useMediaQuery('(max-height:715px)');
  const mediumHeight = useMediaQuery('(max-height:740px)');

  if(smallHeight)
    return (<></>);

  return(
    <Space
      direction="horizontal"
      className={clsx(classes.root, 'footer-label', mediumHeight? classes.mediumHeight : undefined)}
      size={0.5}>
      <Typography className={classes.text}>
        {i18n.m('footer_label.powered_by')}
      </Typography>
      <LogoIcon
        className={classes.logo}
        title="smartypay.io"
        onClick={()=>{
          window.open('https://smartypay.io', '_blank')?.focus();
        }}
      />
    </Space>
  )
}
