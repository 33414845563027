

/*
Same EN-RU keys: 
  - footer_label.powered_by
  - on_ramp_select_by_card.instaramp.title
  - on_ramp_select_by_card.moonpay.title
  - wallet.binance_wallet
  - wallet.inner
  - wallet.metamask 
*/

/* Find Unused keys disabled */


export type MsgKey = 
  | 'all_tokens.title'                             // {ru: "Доступная валюта" en: "Available assets"}
  | 'balance.open_withdrawal'                      // {ru: "Вывод средств" en: "Funds Withdrawal"}
  | 'balance.title'                                // {ru: "Баланс" en: "Balance"}
  | 'balance.token'                                // {ru: "Выберите валюту для просмотра" en: "Select an asset to show"}
  | 'common.back_to_merchant'                      // {ru: "Вернуться в магазин" en: "Back to merchant"}
  | 'common.cancel'                                // {ru: "Отмена" en: "Cancel"}
  | 'common.continue'                              // {ru: "Продолжить" en: "Continue"}
  | 'common.copied'                                // {ru: "Скопировано" en: "Copied"}
  | 'common.copy_error'                            // {ru: "Не удалось скопировать" en: "Failed to copy"}
  | 'common.entering'                              // {ru: "Вход..." en: "Wait..."}
  | 'common.error_info'                            // {ru: "Пожалуйста, повторите позже." en: "Please try again later."}
  | 'common.error_title'                           // {ru: "Произошла ошибка" en: "An error has occurred"}
  | 'common.error.contact_with_us'                 // {ru: "<link>Свяжитесь с нами</link> для решения этой проблемы." en: "<link>Contact us</link> to resolve this issue."}
  | 'common.open_explorer'                         // {ru: "Посмотреть в эксплорере" en: "View in Explorer"}
  | 'common.wait'                                  // {ru: "Подождите..." en: "Wait..."}
  | 'common.warning_title'                         // {ru: "Предупреждение" en: "Warning"}
  | 'enter_by_email.change_email'                  // {ru: "Ввести другой email" en: "Change email for enter"}
  | 'enter_by_email.code_help'                     // {ru: "Не пришел код? Проверьте папку Спам." en: "Didn't receive a code? Check your Spam folder."}
  | 'enter_by_email.code.bad_code'                 // {ru: "Неверный код" en: "Bad validation code"}
  | 'enter_by_email.code.code_expired'             // {ru: "Время жизни кода истекло. Попробуйте новый вход." en: "Code has expired. Try a new code to enter."}
  | 'enter_by_email.code.placeholder'              // {ru: "Введите код, отправленный на email" en: "Enter the code sent to your email"}
  | 'enter_by_email.code.tries_limit'              // {ru: "Превышено количество попыток входа. Попробуйте новый вход." en: "Too many login tries attempts. Try a new code to enter."}
  | 'enter_by_email.description1'                  // {ru: "Вы получите одноразовый код для входа" en: "You will receive a code to verify"}
  | 'enter_by_email.email.bad_email'               // {ru: "Неверный формат email" en: "Bad email format"}
  | 'enter_by_email.email.blocked'                 // {ru: "Email заблокирован из-за большого количества неудачных попыток входа. На вашу почту выслано письмо с разблокировкой." en: "Your account has been blocked after multiple consecutive login attempts. We've sent you an email with instructions on how to unblock it."}
  | 'enter_by_email.email.placeholder'             // {ru: "Введите ваш email" en: "Enter your email to continue"}
  | 'enter_by_email.resend_code'                   // {ru: "Отправить новый код" en: "Resend code"}
  | 'enter_by_email.title'                         // {ru: "Войти через Email" en: "Connect with Email"}
  | 'footer_label.powered_by'                      // {ru: "Powered by" en: "Powered by"}
  | 'format.decimalSeparator'                      // {ru: "," en: "."}
  | 'global_error.common.rate_limit'               // {ru: "Слишком много запросов. Попробуйте через {timeout} сек." en: "Too many requests. Try again in {timeout} seconds."}
  | 'global_error.default_sub_title'               // {ru: "Запустите процедуру оплаты в магазине заново" en: "Please restart a merchant payment procedure"}
  | 'global_error.invalid_query.title'             // {ru: "Неверно оформленная заявка" en: "Invalid invoice"}
  | 'global_error.invoice_expired.title'           // {ru: "Счет на оплату просрочен" en: "Invoice is outdated"}
  | 'global_error.service_unavailable.title'       // {ru: "Сервис временно недоступен" en: "Service is temporarily unavailable"}
  | 'global_error.unknown_error.sub_title'         // {ru: "Свяжитесь с нами для решения этой проблемы" en: "Contact us to resolve this issue"}
  | 'global_error.unknown_error.title'             // {ru: "Произошла ошибка при оплате" en: "An error occurred while paying"}
  | 'global_error.wallet.cannot_enter'             // {ru: "Временные сетевые трудности. Пожалуйста, попробуйте войти в кошелек заново." en: "Temporary network error. Please login again."}
  | 'global_menu.about'                            // {ru: "О SmartyPay" en: "About SmartyPay"}
  | 'global_menu.current_lang'                     // {ru: "Русский язык" en: "English"}
  | 'global_menu.help'                             // {ru: "Связаться с нами" en: "Contact us"}
  | 'global_menu.switch_to'                        // {ru: "Переключить на Русский" en: "Switch to English"}
  | 'global_menu.title'                            // {ru: "Меню" en: "Menu"}
  | 'instaramp_help.help1'                         // {ru: "Перевод займет до 5 минут..." en: "It can take up to 5 minutes..."}
  | 'instaramp_help.help2'                         // {ru: "После перевода нажмите кнопку <b>Заплатить</b>, для завершения операции." en: "After the transfer, click the <b>Pay</b> button to complete the operation."}
  | 'instaramp_help.no_cookie.desc'                // {ru: "Для дальнейшего пополнения мы откроем вам новое окно. После пополнения просто вернитесь обратно и обновите свой баланс." en: "For continue a deposit process  we will open a new window. After success deposit just go back and update your balance."}
  | 'instaramp_help.no_cookie.open'                // {ru: "Продолжить в новом окне" en: "Continue in a new window"}
  | 'instaramp_help.no_cookie.title'               // {ru: "🍪😔 Нам очень жаль, но, кажется, в вашем браузере отключены сторонние файлы cookie." en: "🍪😔 We're sorry, but it seems you have third-party cookies disabled on your browser."}
  | 'invoice_details.title'                        // {ru: "Подробности заказа" en: "Invoice details"}
  | 'invoice_expired_warn.sub_title'               // {ru: "Деньги останутся на кошельке до следующей оплаты." en: "The money will remain in the wallet until the next payment."}
  | 'invoice_expired_warn.title'                   // {ru: "Счет на оплату просрочен." en: "Invoice is expired."}
  | 'invoice.error.expired'                        // {ru: "Текущий счет на оплату просрочен." en: "The current invoice is expired."}
  | 'invoice.label.id'                             // {ru: "Счет" en: "Invoice"}
  | 'invoice.label.id_do_copy'                     // {ru: "Скопировать номер счета" en: "Copy invoice id"}
  | 'invoice.label.id_to_email'                    // {ru: "Счет" en: "Invoice"}
  | 'invoice.label.id2'                            // {ru: "Операция" en: "Invoice"}
  | 'invoice.label.merchant'                       // {ru: "от" en: "from"}
  | 'invoice.label.open_details'                   // {ru: "Подробности заказа" en: "Show full details"}
  | 'maintenance_mode.description'                 // {ru: "Пожалуйста, откройте страницу позже, либо <link>свяжитесь с нами</link>." en: "Please open the page later or <link>contact us</link>."}
  | 'maintenance_mode.title'                       // {ru: "Ведутся технические работы" en: "Technical work in progress"}
  | 'migration_in_progress.description'            // {ru: "Пожалуйста, подождите некоторое время..." en: "Please wait for a while ..."}
  | 'migration_in_progress.title'                  // {ru: "Идет подготовка кошелька" en: "Wallet is being prepared"}
  | 'need_deposit.description'                     // {ru: "Пополните кошелек для оплаты счета" en: "Deposit assets to pay the invoice"}
  | 'need_deposit.need_amount'                     // {ru: "Для оплаты недостаточно:" en: "Insufficient balance:"}
  | 'need_deposit.title'                           // {ru: "Недостаточный баланс" en: "Insufficient balance"}
  | 'offline.description'                          // {ru: "Обновите страницу после подключения к Интернету." en: "Refresh the page after reconnecting to the Internet."}
  | 'offline.notification'                         // {ru: "Нет соединения с Интернетом..." en: "No Internet connection..."}
  | 'offline.title'                                // {ru: "Нет соединения с Интернетом" en: "No Internet connection"}
  | 'on_ramp_by_card_not_supported.desc'           // {ru: "{token} пока нельзя пополнить через Банковскую карту." en: "{token} can't be buyed by Bank card yet."}
  | 'on_ramp_by_card_not_supported.desc2'          // {ru: "Пополнение картой доступно для следующих токенов:" en: "Bank card is supported for the following tokens:"}
  | 'on_ramp_by_transfer.label.network'            // {ru: "Имя сети" en: "Network"}
  | 'on_ramp_by_transfer.label.token'              // {ru: "Токен" en: "Token"}
  | 'on_ramp_by_transfer.label.work_time'          // {ru: "Среднее время поступления" en: "Transfer time"}
  | 'on_ramp_by_transfer.label.work_time_value'    // {ru: "2 мин." en: "2 minutes"}
  | 'on_ramp_by_transfer.qr_error'                 // {ru: "Не удалось создать QR код" en: "Invalid QR code"}
  | 'on_ramp_by_transfer.use_address'              // {ru: "Переводите {token} из биржи по этому адресу" en: "Deposit {token} from exchange to the following address"}
  | 'on_ramp_by_transfer.warning'                  // {ru: "Отправляйте на этот адрес только {token}. Отправка других токенов может привести к потере средств." en: "Send only {token} to this address. Sending other tokens may result in loss of funds."}
  | 'on_ramp_select_by_card.best_price'            // {ru: "Лучшая цена" en: "Best price"}
  | 'on_ramp_select_by_card.instaramp.title'       // {ru: "Instaramp" en: "Instaramp"}
  | 'on_ramp_select_by_card.moonpay.title'         // {ru: "Moonpay" en: "Moonpay"}
  | 'on_ramp_select_by_card.select_service'        // {ru: "Выберите сервис пополнения" en: "Select On-Ramp Service"}
  | 'on_ramp_select_by_card.select_service_desc'   // {ru: "Выберите сервис для депозита <amount></amount> на ваш кошелек." en: "Select one of the options below to add <amount></amount> to your wallet."}
  | 'on_ramp_select_by_card.select_service_desc2'  // {ru: "Доступные сервисы" en: "Available Services"}
  | 'on_ramp_select_by_card.select_service_short'  // {ru: "Выберите сервис" en: "Select On-Ramp Service"}
  | 'on_ramp_select.by_card'                       // {ru: "Банковская карта" en: "Bank card"}
  | 'on_ramp_select.by_transfer'                   // {ru: "Крипто-биржа" en: "Cryptoexechange"}
  | 'on_ramp_select.tip1'                          // {ru: "Вы получите" en: "You receive"}
  | 'on_ramp_select.tip2'                          // {ru: "Депозит" en: "Deposit"}
  | 'on_ramp.last_op'                              // {ru: "Последний депозит" en: "Last deposit"}
  | 'pay_in_progress.description'                  // {ru: "Вы отправили платеж! Сейчас мы ожидаем, когда сеть подтвердит его." en: "You have sent a payment! Network confirmation pending."}
  | 'pay_in_progress.title'                        // {ru: "Подтверждение платежа" en: "Payment confirmation"}
  | 'pay_in_progress.wait_payment_start'           // {ru: "Ожидание начала оплаты." en: "Waiting for the start of payment."}
  | 'pay_in_progress.wait_wallet_deploy'           // {ru: "Подготовка вашего кошелька к оплате." en: "Preparing your wallet for payment."}
  | 'pay_success.description'                      // {ru: "Ваш платеж успешно выполнен!" en: "Your payment has been successfully completed!"}
  | 'pay_success.label.status'                     // {ru: "Статус" en: "Status"}
  | 'pay_success.label.status_success'             // {ru: "Выполнен" en: "Completed"}
  | 'pay_success.label.time'                       // {ru: "Дата/время" en: "Date/time"}
  | 'pay_success.label.total'                      // {ru: "Всего" en: "Total"}
  | 'pay_success.label.tx_id'                      // {ru: "ID транзакции" en: "Transaction ID"}
  | 'pay_success.title'                            // {ru: "Спасибо!" en: "Thank you!"}
  | 'provider.error.access_denied'                 // {ru: "Внешний кошелек отказал в доступе. Проверьте его состояние и попробуйте еще раз." en: "External wallet denied access. Check its condition and try again."}
  | 'provider.error.need_bsc'                      // {ru: "Переключите ваш кошелек на сеть Binance Smart Chain." en: "Switch your wallet to Binance Smart Chain."}
  | 'provider.error.unsupported_token'             // {ru: "Некоторые способы оплаты не поддерживают выбранную валюту." en: "Some payment methods do not support the selected currency."}
  | 'provider.error.wait_other_operation'          // {ru: "Завершите предыдущую операцию в кошельке." en: "Complete the previous wallet operation."}
  | 'rewards.contact_us'                           // {ru: "Не удалось применить бонус. Пожалуйста, <link>свяжитесь с нами</link>." en: "Unable to apply bonus. Please <link>contact us</link>."}
  | 'rewards.fail'                                 // {ru: "Не удалось использовать бонус." en: "Unable to use the bonus."}
  | 'rewards.success'                              // {ru: "Бонус успешно использован!" en: "The bonus has been successfully used!"}
  | 'rewards.type.Free10'                          // {ru: "Бонус 10 USDT!" en: "10 USDT Bonus!"}
  | 'select_wallet_modal.title'                    // {ru: "Подключите кошелек" en: "Connect a wallet to continue"}
  | 'select_wallet_welcome.description'            // {ru: "Подключите для продолжения..." en: "Please connect a wallet to continue..."}
  | 'select_wallet_welcome.promo1'                 // {ru: "Некастодиальное и безопасное хранение" en: "Non-Custodial & Secure"}
  | 'select_wallet_welcome.promo2'                 // {ru: "Вы контролируете весь доступ к средствам." en: "You control your funds and private keys."}
  | 'select_wallet_welcome.promo3'                 // {ru: "Анонимность операций." en: "No cross-association of wallet addresses."}
  | 'select_wallet_welcome.select'                 // {ru: "Подключить кошелек" en: "Connect Wallet"}
  | 'select_wallet_welcome.title'                  // {ru: "Кошелек не подключен" en: "Not connected"}
  | 'select_wallet.by_demo'                        // {ru: "Демо-кошелек" en: "Demo Wallet"}
  | 'select_wallet.by_email'                       // {ru: "Вход по Email" en: "Connect with Email"}
  | 'select_wallet.by_google'                      // {ru: "Вход через Google" en: "Connect with Google"}
  | 'select_wallet.cancel_enter'                   // {ru: "Отменить вход" en: "Reset login to wallet"}
  | 'select_wallet.description'                    // {ru: "Используйте демо-кошелек для демонстрации оплаты." en: "Use the demo wallet for payment demonstration."}
  | 'sign_pay.change_allowance'                    // {ru: "Изменить разрешение на списание денег" en: "Change allowance rule"}
  | 'sign_pay.error.canceled'                      // {ru: "Запрос был отменен пользователем, попробуйте еще раз." en: "The request was canceled by the user. Please try again."}
  | 'sign_pay.error.expired'                       // {ru: "Запрос на оплату устарел, попробуйте еще раз." en: "Payment request is out of date. Please try again."}
  | 'sign_pay.error.out_of_gas'                    // {ru: "Не хватает Gas для выполнения запроса. Попробуйте еще раз, увеличив его лимит." en: "Not enough Gas to complete the request. Try again by increasing its limit."}
  | 'sign_pay.error.unknown'                       // {ru: "Неизвестная ошибка, попробуйте еще раз." en: "Unknown error. Please try again."}
  | 'sign_pay.error.unknown_from_wallet'           // {ru: "Неизвестная ошибка. Убедитесь, что у вас хватает BNB на выбранном кошельке." en: "Unknown error. Make sure you have enough BNB on the wallet."}
  | 'sign_pay.help.need_allowance'                 // {ru: "Для оплаты требуется разрешение на списание необходимой суммы с вашего кошелька." en: "Allowance is required for the invoice payment from your wallet."}
  | 'sign_pay.help.ready'                          // {ru: "Кошелек готов к оплате." en: "The wallet is ready for payment."}
  | 'sign_pay.pay_by_wallet'                       // {ru: "Оплата через {wallet}" en: "Pay by {wallet}"}
  | 'sign_pay.pay_settings'                        // {ru: "Параметры оплаты" en: "Pay settings"}
  | 'sign_pay.set_max_allowance'                   // {ru: "Разрешите автоматическое списание для будущих оплат" en: "Automatic allowance for future payments"}
  | 'sign_pay.start_allowance'                     // {ru: "Разрешить" en: "Allow"}
  | 'sign_pay.start_pay'                           // {ru: "Заплатить {amount}<s></s>{currency}" en: "Pay {amount}<s></s>{currency}"}
  | 'swap.error.load_rate'                         // {ru: "Не удалось загрузить курс обмена для токена." en: "Failed to load exchange rate for token."}
  | 'swap.exchange_rate'                           // {ru: "Курс обмена" en: "Exchange rate"}
  | 'swap.try_reload'                              // {ru: "Попробовать еще раз" en: "Try again"}
  | 'wallet_details.label.wallet_type'             // {ru: "Подключенный кошелек" en: "Connected wallet"}
  | 'wallet_details.logout'                        // {ru: "Выйти" en: "Logout"}
  | 'wallet_details.title'                         // {ru: "Кошелек" en: "Wallet"}
  | 'wallet_pay.buy_token'                         // {ru: "Пополнить баланс" en: "Add funds with"}
  | 'wallet_pay.pay_token'                         // {ru: "Выберите валюту для оплаты" en: "Select an asset to pay"}
  | 'wallet_pay.start_pay'                         // {ru: "Заплатить {amount}<s></s>{currency}" en: "Pay {amount}<s></s>{currency}"}
  | 'wallet.address_copied'                        // {ru: "Адрес скопирован" en: "Address copied successfully"}
  | 'wallet.address_do_copy'                       // {ru: "Скопировать адрес" en: "Copy address"}
  | 'wallet.address_not_copied'                    // {ru: "Не удалось скопировать адрес" en: "Failed to copy an address"}
  | 'wallet.binance_wallet'                        // {ru: "Binance Wallet" en: "Binance Wallet"}
  | 'wallet.demo_wallet'                           // {ru: "Демо-кошелек" en: "Demo Wallet"}
  | 'wallet.entering'                              // {ru: "Вход в кошелек..." en: "Please wait..."}
  | 'wallet.inner'                                 // {ru: "SmartyPay" en: "SmartyPay"}
  | 'wallet.label'                                 // {ru: "Кошелек" en: "Wallet"}
  | 'wallet.metamask'                              // {ru: "Metamask" en: "Metamask"}
  | 'withdrawal.amount.bad_value'                  // {ru: "Неверный формат суммы" en: "Invalid amount format"}
  | 'withdrawal.amount.insufficient_funds'         // {ru: "Недостаточно средств" en: "Insufficient funds"}
  | 'withdrawal.amount.placeholder'                // {ru: "Введите сумму" en: "Enter amount"}
  | 'withdrawal.amount.use_max'                    // {ru: "Макс." en: "Max"}
  | 'withdrawal.description'                       // {ru: "Сумма для вывода" en: "Amount to withdraw"}
  | 'withdrawal.title'                             // {ru: "Вывод средств" en: "Funds Withdrawal"};


export const Msg: Record<MsgKey, string> = {
  'all_tokens.title': 'all_tokens.title',
  'balance.open_withdrawal': 'balance.open_withdrawal',
  'balance.title': 'balance.title',
  'balance.token': 'balance.token',
  'common.back_to_merchant': 'common.back_to_merchant',
  'common.cancel': 'common.cancel',
  'common.continue': 'common.continue',
  'common.copied': 'common.copied',
  'common.copy_error': 'common.copy_error',
  'common.entering': 'common.entering',
  'common.error_info': 'common.error_info',
  'common.error_title': 'common.error_title',
  'common.error.contact_with_us': 'common.error.contact_with_us',
  'common.open_explorer': 'common.open_explorer',
  'common.wait': 'common.wait',
  'common.warning_title': 'common.warning_title',
  'enter_by_email.change_email': 'enter_by_email.change_email',
  'enter_by_email.code_help': 'enter_by_email.code_help',
  'enter_by_email.code.bad_code': 'enter_by_email.code.bad_code',
  'enter_by_email.code.code_expired': 'enter_by_email.code.code_expired',
  'enter_by_email.code.placeholder': 'enter_by_email.code.placeholder',
  'enter_by_email.code.tries_limit': 'enter_by_email.code.tries_limit',
  'enter_by_email.description1': 'enter_by_email.description1',
  'enter_by_email.email.bad_email': 'enter_by_email.email.bad_email',
  'enter_by_email.email.blocked': 'enter_by_email.email.blocked',
  'enter_by_email.email.placeholder': 'enter_by_email.email.placeholder',
  'enter_by_email.resend_code': 'enter_by_email.resend_code',
  'enter_by_email.title': 'enter_by_email.title',
  'footer_label.powered_by': 'footer_label.powered_by',
  'format.decimalSeparator': 'format.decimalSeparator',
  'global_error.common.rate_limit': 'global_error.common.rate_limit',
  'global_error.default_sub_title': 'global_error.default_sub_title',
  'global_error.invalid_query.title': 'global_error.invalid_query.title',
  'global_error.invoice_expired.title': 'global_error.invoice_expired.title',
  'global_error.service_unavailable.title': 'global_error.service_unavailable.title',
  'global_error.unknown_error.sub_title': 'global_error.unknown_error.sub_title',
  'global_error.unknown_error.title': 'global_error.unknown_error.title',
  'global_error.wallet.cannot_enter': 'global_error.wallet.cannot_enter',
  'global_menu.about': 'global_menu.about',
  'global_menu.current_lang': 'global_menu.current_lang',
  'global_menu.help': 'global_menu.help',
  'global_menu.switch_to': 'global_menu.switch_to',
  'global_menu.title': 'global_menu.title',
  'instaramp_help.help1': 'instaramp_help.help1',
  'instaramp_help.help2': 'instaramp_help.help2',
  'instaramp_help.no_cookie.desc': 'instaramp_help.no_cookie.desc',
  'instaramp_help.no_cookie.open': 'instaramp_help.no_cookie.open',
  'instaramp_help.no_cookie.title': 'instaramp_help.no_cookie.title',
  'invoice_details.title': 'invoice_details.title',
  'invoice_expired_warn.sub_title': 'invoice_expired_warn.sub_title',
  'invoice_expired_warn.title': 'invoice_expired_warn.title',
  'invoice.error.expired': 'invoice.error.expired',
  'invoice.label.id': 'invoice.label.id',
  'invoice.label.id_do_copy': 'invoice.label.id_do_copy',
  'invoice.label.id_to_email': 'invoice.label.id_to_email',
  'invoice.label.id2': 'invoice.label.id2',
  'invoice.label.merchant': 'invoice.label.merchant',
  'invoice.label.open_details': 'invoice.label.open_details',
  'maintenance_mode.description': 'maintenance_mode.description',
  'maintenance_mode.title': 'maintenance_mode.title',
  'migration_in_progress.description': 'migration_in_progress.description',
  'migration_in_progress.title': 'migration_in_progress.title',
  'need_deposit.description': 'need_deposit.description',
  'need_deposit.need_amount': 'need_deposit.need_amount',
  'need_deposit.title': 'need_deposit.title',
  'offline.description': 'offline.description',
  'offline.notification': 'offline.notification',
  'offline.title': 'offline.title',
  'on_ramp_by_card_not_supported.desc': 'on_ramp_by_card_not_supported.desc',
  'on_ramp_by_card_not_supported.desc2': 'on_ramp_by_card_not_supported.desc2',
  'on_ramp_by_transfer.label.network': 'on_ramp_by_transfer.label.network',
  'on_ramp_by_transfer.label.token': 'on_ramp_by_transfer.label.token',
  'on_ramp_by_transfer.label.work_time': 'on_ramp_by_transfer.label.work_time',
  'on_ramp_by_transfer.label.work_time_value': 'on_ramp_by_transfer.label.work_time_value',
  'on_ramp_by_transfer.qr_error': 'on_ramp_by_transfer.qr_error',
  'on_ramp_by_transfer.use_address': 'on_ramp_by_transfer.use_address',
  'on_ramp_by_transfer.warning': 'on_ramp_by_transfer.warning',
  'on_ramp_select_by_card.best_price': 'on_ramp_select_by_card.best_price',
  'on_ramp_select_by_card.instaramp.title': 'on_ramp_select_by_card.instaramp.title',
  'on_ramp_select_by_card.moonpay.title': 'on_ramp_select_by_card.moonpay.title',
  'on_ramp_select_by_card.select_service': 'on_ramp_select_by_card.select_service',
  'on_ramp_select_by_card.select_service_desc': 'on_ramp_select_by_card.select_service_desc',
  'on_ramp_select_by_card.select_service_desc2': 'on_ramp_select_by_card.select_service_desc2',
  'on_ramp_select_by_card.select_service_short': 'on_ramp_select_by_card.select_service_short',
  'on_ramp_select.by_card': 'on_ramp_select.by_card',
  'on_ramp_select.by_transfer': 'on_ramp_select.by_transfer',
  'on_ramp_select.tip1': 'on_ramp_select.tip1',
  'on_ramp_select.tip2': 'on_ramp_select.tip2',
  'on_ramp.last_op': 'on_ramp.last_op',
  'pay_in_progress.description': 'pay_in_progress.description',
  'pay_in_progress.title': 'pay_in_progress.title',
  'pay_in_progress.wait_payment_start': 'pay_in_progress.wait_payment_start',
  'pay_in_progress.wait_wallet_deploy': 'pay_in_progress.wait_wallet_deploy',
  'pay_success.description': 'pay_success.description',
  'pay_success.label.status': 'pay_success.label.status',
  'pay_success.label.status_success': 'pay_success.label.status_success',
  'pay_success.label.time': 'pay_success.label.time',
  'pay_success.label.total': 'pay_success.label.total',
  'pay_success.label.tx_id': 'pay_success.label.tx_id',
  'pay_success.title': 'pay_success.title',
  'provider.error.access_denied': 'provider.error.access_denied',
  'provider.error.need_bsc': 'provider.error.need_bsc',
  'provider.error.unsupported_token': 'provider.error.unsupported_token',
  'provider.error.wait_other_operation': 'provider.error.wait_other_operation',
  'rewards.contact_us': 'rewards.contact_us',
  'rewards.fail': 'rewards.fail',
  'rewards.success': 'rewards.success',
  'rewards.type.Free10': 'rewards.type.Free10',
  'select_wallet_modal.title': 'select_wallet_modal.title',
  'select_wallet_welcome.description': 'select_wallet_welcome.description',
  'select_wallet_welcome.promo1': 'select_wallet_welcome.promo1',
  'select_wallet_welcome.promo2': 'select_wallet_welcome.promo2',
  'select_wallet_welcome.promo3': 'select_wallet_welcome.promo3',
  'select_wallet_welcome.select': 'select_wallet_welcome.select',
  'select_wallet_welcome.title': 'select_wallet_welcome.title',
  'select_wallet.by_demo': 'select_wallet.by_demo',
  'select_wallet.by_email': 'select_wallet.by_email',
  'select_wallet.by_google': 'select_wallet.by_google',
  'select_wallet.cancel_enter': 'select_wallet.cancel_enter',
  'select_wallet.description': 'select_wallet.description',
  'sign_pay.change_allowance': 'sign_pay.change_allowance',
  'sign_pay.error.canceled': 'sign_pay.error.canceled',
  'sign_pay.error.expired': 'sign_pay.error.expired',
  'sign_pay.error.out_of_gas': 'sign_pay.error.out_of_gas',
  'sign_pay.error.unknown': 'sign_pay.error.unknown',
  'sign_pay.error.unknown_from_wallet': 'sign_pay.error.unknown_from_wallet',
  'sign_pay.help.need_allowance': 'sign_pay.help.need_allowance',
  'sign_pay.help.ready': 'sign_pay.help.ready',
  'sign_pay.pay_by_wallet': 'sign_pay.pay_by_wallet',
  'sign_pay.pay_settings': 'sign_pay.pay_settings',
  'sign_pay.set_max_allowance': 'sign_pay.set_max_allowance',
  'sign_pay.start_allowance': 'sign_pay.start_allowance',
  'sign_pay.start_pay': 'sign_pay.start_pay',
  'swap.error.load_rate': 'swap.error.load_rate',
  'swap.exchange_rate': 'swap.exchange_rate',
  'swap.try_reload': 'swap.try_reload',
  'wallet_details.label.wallet_type': 'wallet_details.label.wallet_type',
  'wallet_details.logout': 'wallet_details.logout',
  'wallet_details.title': 'wallet_details.title',
  'wallet_pay.buy_token': 'wallet_pay.buy_token',
  'wallet_pay.pay_token': 'wallet_pay.pay_token',
  'wallet_pay.start_pay': 'wallet_pay.start_pay',
  'wallet.address_copied': 'wallet.address_copied',
  'wallet.address_do_copy': 'wallet.address_do_copy',
  'wallet.address_not_copied': 'wallet.address_not_copied',
  'wallet.binance_wallet': 'wallet.binance_wallet',
  'wallet.demo_wallet': 'wallet.demo_wallet',
  'wallet.entering': 'wallet.entering',
  'wallet.inner': 'wallet.inner',
  'wallet.label': 'wallet.label',
  'wallet.metamask': 'wallet.metamask',
  'withdrawal.amount.bad_value': 'withdrawal.amount.bad_value',
  'withdrawal.amount.insufficient_funds': 'withdrawal.amount.insufficient_funds',
  'withdrawal.amount.placeholder': 'withdrawal.amount.placeholder',
  'withdrawal.amount.use_max': 'withdrawal.amount.use_max',
  'withdrawal.description': 'withdrawal.description',
  'withdrawal.title': 'withdrawal.title',
};