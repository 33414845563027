import BigNumber from 'bignumber.js';

export interface Money {
  amount: number,
  currency: Currency,
}

export function sameMoney(a?: Money, b?: Money){
  return a && b
      && a.amount === b.amount
      && a.currency === b.currency;
}

/** {amount: 100, currency: "USD"} -> "100USD" */
export function toMoneyString(money: Money){
  return `${money.amount}${money.currency}`;
}

/** "1.5USD" -> {amount: 1.5, currency: "USD"} */
export function parseMoney(value?: string|Money): Money|undefined{

  if( ! value)
    return undefined;

  if((value as Money).currency)
    return value as Money;

  const str = value as string;
  let inCurrencyPart = true;
  let currencyVal = '';
  let amountVal = '';

  for(let i = str.length-1; i > -1; i--){
    const char = str.charAt(i);
    const isNum = char >= '0' && char <= '9';

    if(inCurrencyPart){
      if(!isNum){
        currencyVal = char + currencyVal;
      } else {
        inCurrencyPart = false;
        amountVal = char + amountVal;
      }
    } else {
      amountVal = char + amountVal;
    }
  }

  return {
    currency: (currencyVal.trim()) as any,
    amount: Number.parseFloat(amountVal),
  }
}


export type Currency =
  'UNKNOWN'
  | 'bBNB'
  | 'bBUSD'
  | 'bUSDT'
  | 'btMNXe'
  | 'btBUSD';

export type FiatCurrency = 'USD' | 'EUR' | 'RUB';

const CurrencySet: Record<Currency, boolean> = {
  UNKNOWN: true,
  bBNB: true,
  bBUSD: true,
  bUSDT: true,
  btMNXe: true,
  btBUSD: true,
};

const FiatCurrencySet: Record<FiatCurrency, boolean> = {
  RUB: true,
  EUR: true,
  USD: true,
}

const CurrencyLabel: Partial<Record<Currency, string>> = {
  bBNB: 'BNB',
  btMNXe: 'EURx',
  btBUSD: 'BUSD',
  bBUSD: 'BUSD',
  bUSDT: 'USDT',
}

export function getCurrency(val?: string): Currency {
  return val && (CurrencySet as any)[val]? val as Currency : 'UNKNOWN';
}

export function getFiatCurrency(val: string|undefined, defaultVal: FiatCurrency): FiatCurrency {
  return val && (FiatCurrencySet as any)[val]? val as FiatCurrency : defaultVal;
}


export function currLabel(currency: Currency): string {
  const label = CurrencyLabel[currency];
  return label || currency;
}

export interface FormatOptions {
  decimalMax?: number,
  shiftedBy?: number,
  decimalSeparator?: string,
}

export function formatAmount(
  value: number|string|undefined,
  formatOptions?: FormatOptions,
){

  formatOptions = {
    decimalMax: 5,
    ...(formatOptions || {})
  }

  const {shiftedBy, decimalMax, decimalSeparator} = formatOptions;
  const sep = decimalSeparator || ',';

  let num = new BigNumber(value || 0);

  if(shiftedBy)
    num = num.shiftedBy(shiftedBy);

  const str = num.toFormat({
    groupSeparator: ' ',
    decimalSeparator: sep,
    groupSize: 3,
  });

  // remove minor decimals
  const parts = str.split(sep);
  if(decimalMax && parts.length === 2 && parts[1].length > decimalMax){
    parts[1] = parts[1].substr(0, decimalMax);
  }

  return parts.join(sep);
}
