import { OpenIdProvider } from '@/model/common/OpenId';


export type Role = 'WalletUser';

export type WalletAuthType = 'email' | 'phone' | 'test';



export interface AuthData {
  token: string,
  tokenUntil: number,
  refreshToken: string,
  type: WalletAuthType,
  email?: string,
  authProvider: OpenIdProvider;
}

export interface AuthState {
  data?: AuthData,
}

export const initialState: AuthState = {
  data: undefined
}
