import React, { useState } from 'react';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/styles';
import { createStyles, Snackbar, SvgIcon, Theme } from '@material-ui/core';
import { Color, important } from '@/components/Style';
import { ReactComponent as TopErrorIcon } from '@/assets/icons/top-error-icon.svg';
import { ReactComponent as TopWarningIcon } from '@/assets/icons/top-warning-icon.svg';
import { ReactComponent as TopSuccessIcon } from '@/assets/icons/top-success-icon.svg';
import clsx from 'clsx';
import { AlertTitle } from '@material-ui/lab';


const Alert: React.FC<AlertProps> = (props: AlertProps) => <MuiAlert elevation={6} variant="filled" {...props} />;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    snackbar: {
      padding: theme.spacing(),
    },
    alert: {
      width: 'calc(100vw - 20px)',
      maxWidth: '547px',
      boxShadow: 'none',
      borderRadius: '12px',
      backgroundColor: Color.back.Fifth,
      color: Color.border.Main,
      padding: '8px 16px',
      '& .MuiAlert-icon': {
        width: '24px',
        height: '24px',
        padding: '0px',
        margin: '0px',
        opacity: 1,
        marginRight: '10px',
        marginTop: '4px',
      },
      '& .MuiAlert-message': {
        padding: '0',
        fontSize: important('14px'),
        lineHeight: important('20px'),
      },
      '& .MuiAlert-action': {
        alignItems: 'start'
      },
      '& .MuiAlert-action button': {
        height: '20px'
      },
      '& .MuiAlert-action button:hover': {
        backgroundColor: 'inherit'
      }
    },
    alertTitle: {
      color: 'white',
      fontSize: important('16px'),
      lineHeight: important('24px'),
      fontWeight: 400,
      margin: '0',
    },
    successAlert: {
      '& .MuiAlert-icon': {
        marginTop: '0px',
      },
    }
  }),
);


export interface NotificationProps {
  title: string,
  description?: string,
  onClose?: ()=>void,
  hide?: boolean,
  showClose?: boolean,
  type: AlertType,
  onTop?: boolean,
}

export type AlertType = 'success' | 'warning' | 'error';

export function Notification(
  {
    title,
    description,
    onClose,
    hide = false,
    showClose = true,
    type,
    onTop = true,
  }: NotificationProps
){

  const classes = useStyles();
  const [closed, setClosed] = useState(false);

  if(closed || hide)
    return (<></>);

  let icon;
  if(type === 'error'){
    icon = <SvgIcon component={TopErrorIcon}/>;
  } else if(type === 'warning'){
    icon = <TopWarningIcon />;
  } else {
    icon = <TopSuccessIcon/>;
  }

  return (
    <Snackbar
      open
      className={classes.snackbar}
      anchorOrigin={{
        horizontal: 'center',
        vertical: onTop? 'top' : 'bottom'
      }}>
      <Alert
        icon={icon}
        severity={type}
        className={clsx(classes.alert, type === 'success'? classes.successAlert : undefined)}
        onClose={showClose ? (): void => {
          if(onClose){
            onClose();
          } else {
            setClosed(true);
          }
        } : undefined}
      >
        <AlertTitle className={classes.alertTitle}>
          {title}
        </AlertTitle>
        {description}
      </Alert>
    </Snackbar>
  )
}
