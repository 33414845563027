import { rootStore } from '@/hocs/withStore/configureStore';


export type SignPayState =
  'BeforePay'
  | 'PayAllowance_Need'
  | 'PayAllowance_WaitSig'
  | 'PayAllowance_WaitBroadcast'
  | 'PayAllowance_Error'
  | 'Pay_Ready'
  | 'Pay_WaitSig'
  | 'Pay_WaitBroadcast'
  | 'Pay_Error';


export function isFinalPayStep(state: SignPayState){
  return state === 'Pay_WaitSig'
      || state === 'Pay_WaitBroadcast'
      || state === 'Pay_Error';
}

export type SignPayErrorCode =
  'SignCanceled'
  | 'TransactionExpired'
  | 'InvalidWalletState'
  | 'LowGasToInvoke';



export function isSignPayOutdated(dataId: number) {
  const curDataId = rootStore.getState().walletSignPay.dataId;
  return curDataId !== dataId;
}

export interface WalletSignPayState {
  dataId: number,
  payState: SignPayState,
  errorCode?: SignPayErrorCode,
  signTxId?: string,
  payTxId?: string,
}


export const initialState: WalletSignPayState = {
  payState: 'BeforePay',
  dataId: 0,
}
