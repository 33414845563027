import jwt_decode from 'jwt-decode';
import { OpenIdProvider } from '@/model/common/OpenId';
import { Net } from '@/utils/axios';
import { AuthData, Role, WalletAuthType } from '@/model/auth/types';
import { LSCache } from '@/utils/storage';
import { withSingleCall } from '@/utils/promise';
import { Util } from '@/utils/util';


const cacheKey = 'authData';
const tokenUntilLag = 5000;
const log = Util.getLog('auth');

let authInstance: AuthData|undefined;
let sessionId: string|undefined;


export interface WalletAuthPayload {
  exp: number,
  client: string,
  type: WalletAuthType,
  roles: Role[];
}

export interface ExternalSourceLoginReq {
  authToken: string;
  authProvider: OpenIdProvider;
}

export interface WalletSessionResp {
  token: string;
  refreshToken: string;
}

export interface AuthListener {
  onAuthUpdated: ()=> void,
}

const listeners: AuthListener[] = [];

export function addAuthListener(listener: AuthListener){
  listeners.push(listener);
}


export function initAuthData(authProvider: OpenIdProvider, data: WalletSessionResp): AuthData {

  const {token, refreshToken} = data;
  const {type, client, exp} = jwt_decode(token) as WalletAuthPayload;

  const authData: AuthData = {
    email: type === 'email'? client : undefined,
    type,
    token,
    tokenUntil: exp * 1000,
    refreshToken,
    authProvider,
  };

  setCachedAuth(authData);
  return authData;
}

export function clearAuth(){
  authInstance = undefined;
  LSCache.set(cacheKey, undefined);
  fireAuthUpdatedEvent();
}


function initSessionId(refreshToken: string|undefined){
  if( ! refreshToken)
    return;

  const oldSessionId = sessionId;
  sessionId = (jwt_decode(refreshToken) as any)?.sessionId;

  if(oldSessionId !== sessionId){
    log.info('session id', sessionId);
  }
}

export function getCachedAuth(): AuthData|undefined {

  if( ! authInstance) {
    // from local
    authInstance = LSCache.get(cacheKey);
    initSessionId(authInstance?.refreshToken);
  }

  return authInstance;
}

function setCachedAuth(authData: AuthData|undefined){

  // from server
  authInstance = authData;
  initSessionId(authInstance?.refreshToken);

  LSCache.set(cacheKey, authData);
  fireAuthUpdatedEvent();
}

function fireAuthUpdatedEvent(){
  listeners.forEach(listener => listener.onAuthUpdated());
}


export async function queryLoginByExternalSource(
  req: ExternalSourceLoginReq,
  recaptchaToken: string
): Promise<WalletSessionResp> {
  return Net.post('/wallet-auth/login-by-external-source', req, {
    headers: {
      'x-recaptcha': recaptchaToken
    }
  });
}


export async function queryLoginByTestUser(recaptchaToken: string): Promise<WalletSessionResp> {
  return Net.post('/wallet-auth/login-by-test-user', {}, {
    headers: {
      'x-recaptcha': recaptchaToken
    }
  });
}

export interface AuthorizationHeader {
  authorization?: string
}

export async function getAuthorizationHeader(): Promise<AuthorizationHeader> {

  const token = await getAuthToken();

  // no auth header
  if( ! token) {
    log.info('no auth for header');
    return {};
  }

  return {
    authorization: `Bearer ${token}`
  }
}

export function getSessionId(): string|undefined {
  return sessionId
}

export function hasAuthToken(){
  return !! getCachedAuth();
}

export async function getAuthToken(): Promise<string|undefined>{

  const data = getCachedAuth();
  if( ! data)
    return undefined;

  if( data.tokenUntil && Date.now() < data.tokenUntil - tokenUntilLag){
    return data.token;
  }

  return withSingleCall<string|undefined>('use-refresh-token', async ()=>{
    const {refreshToken} = data
    try {

      const respData: WalletSessionResp = await Net.post('/wallet-auth/update-session', {refreshToken});
      const newAuthData = initAuthData(data.authProvider, respData);
      return newAuthData.token;

    } catch (e: any){

      // invalid refresh token
      if(e.status === 400){
        log.info('clear old auth data')
        clearAuth();
      }
      // unknown error
      else {
        log.error('cannot updated access token', e);
      }

      return undefined;
    }
  })
}
