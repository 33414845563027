import { actionCreator } from '@/utils/redux/action-creator';
import { EmailEnterStep } from '@/model/emailEnter/types';


const creator = actionCreator('EMAIL_ENTER');


export const EmailEnterAction = {

  Reset:          creator.syncAction('RESET'),
  StartWithEmail: creator.syncActionWithPayload<string>('START_WITH_EMAIL'),
  SendCode:       creator.syncActionWithPayload<string>('SEND_CODE'),

  support: {
    SetLoading:   creator.syncActionWithPayload<boolean>('SUPPORT_SET_LOADING'),
    SetEmail:     creator.syncActionWithPayload<string>('SUPPORT_SET_EMAIL'),
    SetStep:      creator.syncActionWithPayload<EmailEnterStep>('SUPPORT_SET_STEP'),
    SetErrorCode: creator.syncActionWithPayload<string|undefined>('SUPPORT_ERROR_CODE'),
  }

};

