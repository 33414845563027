import { call, put, select, takeEvery } from 'redux-saga/effects';
import { Action } from '@/model/actions';
import { withErrorHandler } from '@/utils/redux/action-creator';
import { RootState } from '@/model/types';
import { Util } from '@/utils/util';
import {
  moonpayApiKey,
  MoonpayCryptoCurrency,
  MoonpayCryptoCurrencyMap,
  MoonpayFiatCurrency,
  MoonpayFiatCurrencyMap,
  onlyUSDBaseCurrency,
} from '@/model/onRamp/moonpay/types';
import { MoonpayQuoteResp, queryMoonpayBuyQuote, querySignUrl } from '@/api/moonpay';
import { getTokenAmount } from '@/model/common/Assets';
import { Color } from '@/components/Style';
import { Config, Environment } from '@/config';
import { DefLang } from '@/model/common/Lang';
import { Goal, Metrika } from '@/api/metrika';
import { isSameAddress } from '@/model/common/Blockchain';

const log = Util.getLog('moonpay/sagas');

const urls: Record<Environment, string> = {
  prod:     'https://buy.moonpay.com',
  staging:  'https://buy-staging.moonpay.com',
  dev:      'https://buy-staging.moonpay.com',
  local:    'https://buy-staging.moonpay.com',
}



function* prepareMoonpayOnRamp(){

  const rootState = (yield select((state: RootState) => state)) as RootState;

  if(rootState.onRamp.provider !== 'Moonpay')
    return;

  const {currency, amount} = rootState.walletProvider.selectedQuote || {};
  const {baseCurrency: oldBaseCurrency} = rootState.onRamp;
  const lang = rootState.global.lang || DefLang;
  const {address} = rootState.wallet;
  const isTorusEnter = rootState.walletProvider.type === 'inner';
  const email = rootState.auth.data?.email;

  const selectedTokenId = rootState.walletProvider.selectedToken?.tokenId;
  const tokenBalance = (rootState.wallet.assets?.otherAssets || [])
    .find(token => isSameAddress(token.tokenId, selectedTokenId));
  const tokenAmount = getTokenAmount(tokenBalance);

  if( !currency || !amount || !oldBaseCurrency || !address)
    return;

  let baseCurrency = oldBaseCurrency;

  // use only USD because of bad quotes for others
  if(onlyUSDBaseCurrency && oldBaseCurrency !== 'USD'){
    baseCurrency = 'USD';
    yield put(Action.onRamp.support.SetBaseCurrency(baseCurrency).pure);
  }

  const cryptoToBuy = MoonpayCryptoCurrencyMap[currency];
  const fromFiat = MoonpayFiatCurrencyMap[baseCurrency];

  const urlParams = new URLSearchParams();
  urlParams.set('apiKey', moonpayApiKey);
  urlParams.set('language', lang);
  urlParams.set('colorCode', Color.text.Third)
  urlParams.set('baseCurrencyCode', fromFiat);
  urlParams.set('walletAddress', address);
  urlParams.set('lockAmount', 'false'); // allow user to change buy amount
  if(isTorusEnter && email){
    urlParams.set('email', email);
  }
  if(cryptoToBuy){
    urlParams.set('currencyCode', cryptoToBuy);
  }

  yield put(Action.onRamp.support.SetStatus('Preparing').pure);

  // set baseCurrencyAmount
  try {

    yield setupBaseAmount(cryptoToBuy, fromFiat, amount, tokenAmount);
    const baseAmount = (yield select((state: RootState) => state.onRamp.baseAmount)) as number | undefined;

    if (baseAmount) {
      urlParams.set('baseCurrencyAmount', Math.ceil(baseAmount).toFixed(0));
    }
  } catch (e: any) {
    log.error('cannot set baseCurrencyAmount', e);
  }

  // set signature
  if(isTorusEnter) {
    try {

      const urlToSign = `${urls[Config.Environment]}/?${urlParams}`;
      const signature = (yield call(querySignUrl, urlToSign)) as string;

      urlParams.set('signature', signature);

      if(email)
        Metrika.reach(Goal.moonpay.OpenedByEmail);
      else
        Metrika.reach(Goal.moonpay.OpenedByGuest);

    } catch (e: any) {
      log.error('cannot sign url', e);
      Metrika.reach(Goal.moonpay.OpenedByGuest);
    }
  } else {
    Metrika.reach(Goal.moonpay.OpenedByGuest);
  }

  const frameUrl = `${urls[Config.Environment]}/?${urlParams}`;
  yield put(Action.onRamp.support.SetFrameUrl(frameUrl).pure);

  yield put(Action.onRamp.support.SetStatus('Shown').pure);
  Metrika.reach(Goal.moonpay.Opened);
}

function* setupBaseAmount(
  cryptoToBuy: MoonpayCryptoCurrency | undefined,
  fromFiat: MoonpayFiatCurrency | undefined,
  amount: number,
  tokenAmount: number,
){

  if(!cryptoToBuy || !fromFiat)
    return;

  const apikey = moonpayApiKey;

  const checkQuotes = (yield call(
    queryMoonpayBuyQuote,
    cryptoToBuy,
    fromFiat,
    1,
    apikey)) as MoonpayQuoteResp;

  const {quoteCurrencyPrice} = checkQuotes;

  // if wallet already has some tokens try to get only the last part
  let needCryptoAmount = amount;
  if(tokenAmount > 0 && tokenAmount < amount){
    needCryptoAmount = amount - tokenAmount;
  }

  const fiatAmount = Math.ceil(needCryptoAmount * quoteCurrencyPrice).toFixed(0);

  const targetQuotes = (yield call(
    queryMoonpayBuyQuote,
    cryptoToBuy,
    fromFiat,
    fiatAmount,
    apikey)) as MoonpayQuoteResp;

  const {quoteCurrencyAmount} = targetQuotes;

  log.info('quotes', {
    cryptoToBuy,
    fromFiat,
    quoteCurrencyPrice,
    needCryptoAmount,
    fiatAmount,
    quoteCurrencyAmount
  });

  // has a valid calculation
  if(quoteCurrencyAmount >= needCryptoAmount){
    const baseAmount = parseFloat(fiatAmount);
    yield put(Action.onRamp.support.SetBaseAmount(baseAmount).pure);
  }

}

function* onWalletLogout(){
  // cannot find api for that
}

export default function* rootSaga(): Generator {

  yield takeEvery(Action.onRamp.support.PrepareOnRamp.type,
    withErrorHandler(
      prepareMoonpayOnRamp
    ));

  yield takeEvery(Action.wallet.Logout.type,
    withErrorHandler(
      onWalletLogout
    ));

}
