import { WalletProviderType } from '@/model/wallet/provider/types';

export type RestoreStatus =
  'notLoaded'
  | 'loaded';

export interface RestoreData {
  provider: WalletProviderType
}


export interface WalletRestoreState {
  status: RestoreStatus,
  data: RestoreData,
  showRestoringProgress: boolean,
}

export const initialState: WalletRestoreState = {
  status: 'notLoaded',
  data: {
    provider: 'none',
  },
  showRestoringProgress: false,
}
