import { Currency, FiatCurrency } from '@/model/common/Money';
import { Config, Environment } from '@/config';
import { Network } from '@/model/common/Network';


export type MoonpayFiatCurrency = 'rub' | 'usd' | 'eur';
export type MoonpayCryptoCurrency = 'usdt' | 'busd_bsc';

const apiKeys: Record<Environment, string> = {
  prod:     'pk_live_E6rVc88DdYSRLlKRzJajFVqrQXf8mfc',
  staging:  'pk_test_qlJTMWn7FxMm1VPoacIZPLHNGzQfSp',
  dev:      'pk_test_qlJTMWn7FxMm1VPoacIZPLHNGzQfSp',
  local:    'pk_test_qlJTMWn7FxMm1VPoacIZPLHNGzQfSp',
}

export const moonpayApiKey = apiKeys[Config.Environment];

// Moonpay has very bad quotes now for non-usd currencies: especially rub
export const onlyUSDBaseCurrency = true;

export const MoonpayFiatCurrencyMap: Record<FiatCurrency, MoonpayFiatCurrency> = {
  RUB: 'rub',
  USD: 'usd',
  EUR: 'eur',
}

export const MoonpayCryptoCurrencyMap: Partial<Record<Currency, MoonpayCryptoCurrency>> = {
  // test
  btMNXe: 'usdt',
  // prod
  bBUSD: 'busd_bsc',
}

export const MoonpaySupportedTokens: Record<Network, Currency[]> = {
  BinanceMainNet: ['bBUSD'],
  BinanceTestNet: ['btMNXe'],
}

export function isMoonpaySupported(currency: Currency|undefined): boolean{
  return Config.toggles['moonpay-show'] && !! MoonpayCryptoCurrencyMap[currency || 'UNKNOWN'];
}
