import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import { Color } from '@/components/Style';


const backColor = '#EAEAEC';

function getBackColor(donePercent: number){
  const doneVal = Math.min(100, Math.max(0, donePercent));
  return `conic-gradient(white 0%, white ${doneVal}%, ${backColor} 0%, ${backColor} 100%)`;
}

const styles = makeStyles((theme: Theme) => {

  return {
    root: {
      width: '24px',
      height: '24px',
      borderRadius: '9999px', // magic
      background: getBackColor(0),
      textAlign: 'center',
      overflow: 'visible',
      color: Color.back.Fifth,
      display: 'flex',
      flexGrow: 0,
      flexShrink: 0,
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '12px',
      fontWeight: 500,
    },
  }
});


export interface CountdownCircleProps {
  maxSec: number,
  onDone: ()=>void,
  deltaSec?: number,
}

export function CountdownCircle(
  {
    maxSec,
    deltaSec = 1,
    onDone,
  }: CountdownCircleProps
){

  const classes = styles();
  const rootRef = useRef<HTMLDivElement>(null);

  const [val, setVal] = useState<number>(maxSec);

  useEffect(()=>{

    let newVal = maxSec;

    const intervalId = setInterval(()=>{

      newVal -= deltaSec;

      // update view
      if(newVal >= 0){
        setVal(newVal);
      }

      // done
      if(newVal === 0) {
        clearInterval(intervalId);
        onDone();
      }
    }, deltaSec * 1000);

    return ()=>{
      clearInterval(intervalId);
    }
  }, [maxSec, deltaSec, setVal, onDone]);

  useEffect(()=>{
    if(rootRef.current){
      const donePercent =  Math.round((Math.abs(maxSec - val) / maxSec) * 100);
      rootRef.current.style.background = getBackColor(donePercent);
    }
  }, [val, maxSec, rootRef])

  return (
    <div className={classes.root} ref={rootRef}>
      {val !== 0? val : ''}
    </div>
  )
}
