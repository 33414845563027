
import moment from 'moment';

export const OneSec = 1000;
export const OneMin = OneSec * 60;
export const OneHour = OneMin * 60;
export const OneDay = OneHour * 24;
export const OneWeek = OneDay * 7;

export const DateUtil = {

  dateFrom(val: any): Date|null {

    if( ! val)
      return null;

    if(val instanceof Date)
      return val;

    const fromStandardFormat = new Date(val);
    if( ! isNaN(fromStandardFormat.getTime()))
      return fromStandardFormat;

    const parsed = moment(val);
    return parsed.isValid()? parsed.toDate() : null;
  },

  /** to "yyyy-mm-dd" format */
  formatToISOYearDay(date: Date | string | number){
    const parsed = this.dateFrom(date);
    if( ! parsed)
      throw new Error(`invalid date value: ${date}`);
    return moment(parsed).format('YYYY-MM-DD');
  },

  get18YearsOld(): Date {
    return moment().subtract(18, 'y').toDate();
  },

  addTo(date: Date, delta: number, unit: UnitOfTime){
    return moment(date).add(delta, unit).toDate();
  }

};


export type UnitOfTime =
  'year' | 'years' | 'y' |
  'month' | 'months' | 'M' |
  'week' | 'weeks' | 'w' |
  'day' | 'days' | 'd' |
  'hour' | 'hours' | 'h' |
  'minute' | 'minutes' | 'm' |
  'second' | 'seconds' | 's' |
  'millisecond' | 'milliseconds' | 'ms';