

export type EmailEnterStep =
  'enter_email'
  | 'enter_code'
  | 'wait_login'
  | 'error';


export interface EmailEnterState {
  loading: boolean,
  step: EmailEnterStep,
  email?: string,
  errorCode?: string,
}

export const initialState: EmailEnterState = {
  loading: false,
  step: 'enter_email'
}
