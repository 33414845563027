import { actionCreator } from '@/utils/redux/action-creator';
import { Notification, NotificationObj, NotificationType, UIState } from '@/model/global/types';
import { Lang } from '@/model/common/Lang';

export type TransmitToActionPayload = { state: UIState; replaceHistory?: boolean };
export type SetLoadingActionPayload = { loading: boolean };

export type AddNotificationPayload = NotificationObj & { type: NotificationType };
export type RemoveNotificationPayload = {
  id: string;
  type: NotificationType;
};


const creator = actionCreator('GLOBAL');

export const GlobalAction = {

  AddError:     creator.asyncActionWithPayload<Notification, void>('ADD_ERROR'),
  AddWarning:   creator.asyncActionWithPayload<Notification, void>('ADD_WARNING'),
  AddSuccess:   creator.asyncActionWithPayload<Notification, void>('ADD_SUCCESS'),
  Transmit:     creator.asyncActionWithPayload<TransmitToActionPayload, void>('TRANSMIT_TO'),
  SetLoading:   creator.syncActionWithPayload<SetLoadingActionPayload>('SET_LOADING'),
  SetLang:      creator.syncActionWithPayload<Lang>('SET_LANG'),
  CleanNotifications: creator.syncAction('CLEAN_NOTIFICATIONS'),
  RestorePageData: creator.syncAction('RESTORE_PAGE_DATA'),
  ShowGlobalMenu: creator.syncActionWithPayload<boolean>('SHOW_GLOBAL_MENU'),

  support: {
    AddNotification:        creator.syncActionWithPayload<AddNotificationPayload>('ADD_NOTIFICATION'),
    RemoveNotification:     creator.syncActionWithPayload<RemoveNotificationPayload>('REMOVE_NOTIFICATION'),
    RemoveAllNotifications: creator.syncActionWithPayload<NotificationType>('REMOVE_ALL_NOTIFICATIONS'),
  }
};

