import { Toggles } from '@/config';
import { Web3Urls } from '@/api/blockchain/common';

export interface WalletInitInfo {
  toggles: Toggles,
  web3: AllWeb3Urls,
}

export interface AllWeb3Urls {
  bsc: Web3Urls
}

export type InitErrorType =
  'InvalidQuery'
  | 'ServiceUnavailable';


export interface WalletInitState {
  loaded: boolean,
  loadTries?: number,
  initError?: InitErrorType,
}


export const initialState: WalletInitState = {
  loaded: false,
};
