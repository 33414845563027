import { makeCommonStyles, Space } from '@/components';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import { ClassName, Color, important } from '@/components/Style';
import clsx from 'clsx';
import { Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/model/types';
import { useSelectedTokenBalance } from '@/components/wallet';
import { useI18n } from '@/i18n';
import { getTokenAmount } from '@/model/common/Assets';
import { ReactComponent as SelectRightIcon } from '@/assets/icons/select-right.svg';
import { Action } from '@/model/actions';
import { SingleTimeout } from '@/utils/SingleTimeout';
import { ReactComponent as ReloadIcon } from '@/assets/icons/reload.svg';

const balanceLoadingMinTimeout = 500;
const hiddenUpdateBalanceInterval = 6000;

const styles = makeStyles((theme: Theme) => {

  return {
    root: {
      alignItems: 'center',
      padding: '13px 16px',
      backgroundColor: Color.back.Second,
      borderRadius: '8px',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: Color.selected.Gray1,
      },
      '& .loading-icon': {
        marginRight: '-4px',
      },
      '& .loading-icon path': {
        fill: Color.text.Second,
      }
    },
    rootDisabled: {
      cursor: 'inherit',
      '&:hover': {
        backgroundColor: Color.back.Second,
      },
      '& .loading-icon': {
        animation: 'rotation 1s infinite linear'
      }
    },
    assetsLabel: {
      marginTop: '12px',
      marginBottom: important('8px'),
      color: Color.text.Second,
    },
    balance: {
      lineHeight: '21px',
    },
    balanceDesc: {
      fontSize: '13px',
      lineHeight: '17px',
      color: Color.text.Second,
    }
  };
});

const commonStyles = makeCommonStyles();

export interface WalletBalancePanelProps {
  title: string,
  disabled?: boolean,
}

export function WalletBalancePanel(
  {
    title,
    disabled: needDisabled,
  }: WalletBalancePanelProps
){

  const dispatch = useDispatch();
  const i18n = useI18n();
  const classes = styles();
  const commonClasses = commonStyles();

  const [skipFirstLoadingShow, setSkipFirstLoadingShow] = useState(true);
  const [showBalanceLoading, setShowBalanceLoading] = useState(false);
  const [loadingTimer] = useState(()=> new SingleTimeout(balanceLoadingMinTimeout));

  const selectedToken = useSelector((state: RootState) => state.walletProvider.selectedToken);
  const tokenBalance = useSelectedTokenBalance();
  const loadingType = useSelector((state: RootState) => state.wallet.loadingType);

  const balanceLoading = loadingType === 'reload';
  const disabled = needDisabled || showBalanceLoading;

  // clean up
  useEffect(() => {
    return () => {
      loadingTimer.clearTimeout();
    };
  }, [loadingTimer]);

  // hidden balance auto-update
  useEffect(()=>{

    const updateIntervalId = setInterval(()=>{
      Action.wallet.HiddenReload()(dispatch);
    }, hiddenUpdateBalanceInterval);

    return ()=> {
      clearInterval(updateIntervalId);
    };
  }, [dispatch]);

  // balance loading animation delay
  useLayoutEffect(()=>{

    // not show loading on first view
    if(skipFirstLoadingShow){
      loadingTimer.setTimeout(()=>{
        setSkipFirstLoadingShow(false);
      });
    }
    else if(!showBalanceLoading && balanceLoading){

      setShowBalanceLoading(true);

      // delay animation for min timeout
      loadingTimer.setTimeout(()=>{
        setShowBalanceLoading(false);
      });
    }
  }, [showBalanceLoading, balanceLoading, loadingTimer, skipFirstLoadingShow]);

  if( ! selectedToken) {
    return (<></>);
  }

  const tokenAmount = getTokenAmount(tokenBalance);
  const fullName = selectedToken.fullName || `${selectedToken.abbr} Coin`;

  return (
    <>
      <Typography className={clsx(classes.assetsLabel)}>
        {title}
      </Typography>
      <Space
        direction="horizontal"
        className={clsx(classes.root, disabled? classes.rootDisabled : undefined)}
        size={1.5}
        data-test="select-token"
        onClick={()=>{
          if( ! disabled ){
            Action.wallet.SetDialog('allTokens')(dispatch);
          }
        }}
      >

        <img
          className={clsx(commonClasses.icon24, ClassName.SkipOpacity)}
          src={selectedToken.logo}
          alt=""
        />

        <Space direction="vertical" size={0}>
          <Typography className={classes.balance}>
            {tokenBalance ? `${i18n.formatAmount(tokenAmount)} ${selectedToken.abbr}` : i18n.m('common.wait')}
          </Typography>
          <Typography className={classes.balanceDesc}>
            {fullName}
          </Typography>
        </Space>

        <div className={commonClasses.spaceBlock}/>
        {!disabled && !showBalanceLoading && <SelectRightIcon className={commonClasses.icon14} />}
        {showBalanceLoading && <ReloadIcon className={clsx(commonClasses.icon24, 'loading-icon')}/>}

      </Space>
    </>
  )
}
