import { Button, Typography } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import { makeCommonStyles, Space } from '@/components';
import { Action } from '@/model/actions';
import { RootState } from '@/model/types';
import { ClassName, Color, important } from '@/components/Style';
import { useI18n } from '@/i18n';
import { Goal, Metrika } from '@/api/metrika';
import { Util } from '@/utils/util';
import { getTokenAmount, TokenDef } from '@/model/common/Assets';
import { getBscAltTokensToPay } from '@/model/common/BscTokens';
import { HeaderWithMenuAndBack } from '@/components/wallet/common/HeaderWithMenuAndBack';
import { useShowAlts } from '@/components/wallet';
import { addAuthListener } from '@/api/auth';
import { MetamaskApi } from '@/api/dapp/metamask';
import { BinanceWalletApi } from '@/api/dapp/binance_wallet';
import { BlockchainProvider } from '@/api/blockchain';

const log = Util.getLog('AltTokens');
const commonStyles = makeCommonStyles();

const styles = makeStyles((theme: Theme) => {

  return {
    root: {
      maxHeight: '540px',
    },
    title: {
      marginBottom: '10px',
    },
    listWrapper: {
      overflow: 'auto',
    },
    tokenButton: {
      flexShrink: 0,
      border: important('none'),
      height: '52px',
      padding: '8px 16px',
      '& .MuiButton-label': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        textAlign: 'left',
        gap: '16px',
        color: important(Color.text.Main),
      },
      '& .MuiButton-label > svg': {
        flexShrink: 0,
      }
    },
    tokenTitle: {
      fontSize: '14px',
      lineHeight: '20px',
    },
    tokenTitleDesc: {
      fontSize: '12px',
      lineHeight: '16px',
    }
  };
});

const cachedBalances = new Map<string, number>();

function clearCache(){
  cachedBalances.clear();
}

// external changes
addAuthListener({
  onAuthUpdated: clearCache
});
MetamaskApi.addListener({
  onNetworkChanged: clearCache,
  onAddressChanged: clearCache,
});
BinanceWalletApi.addListener({
  onNetworkChanged: clearCache,
  onAddressChanged: clearCache,
});

interface TokenToSelectProps {
  token: TokenDef,
  loadBalance?: boolean,
  address?: string,
  loadBalanceDelay?: number,
}

function TokenToSelect(
  {
    token,
    loadBalance,
    loadBalanceDelay,
    address,
  }: TokenToSelectProps
){

  const i18n = useI18n();
  const dispatch = useDispatch();
  const classes = styles();
  const commonClasses = commonStyles();

  const [balance, setBalance] = useState<number|undefined>(cachedBalances.get(token.abbr));

  useEffect(()=>{

    let activeState = true;
    let timerId: any;

    const cleanupFn = ()=>{
      activeState = false;
      clearTimeout(timerId);
    }

    if( ! loadBalance || ! address){
      return cleanupFn;
    }

    const { network } = token;
    const blockchain = BlockchainProvider.getApi(network);
    const targetAddress = address;

    // load right now or with delay
    if( ! loadBalanceDelay ){
      loadRealBalance();
    } else {
      timerId = setTimeout(loadRealBalance, loadBalanceDelay);
    }

    function loadRealBalance() {

      blockchain.getWalletAssets(network, targetAddress, [token]).then(assets => {

        // no ui to show result
        if( ! activeState)
          return;

        const tokenState = assets.otherAssets.length > 0 ? assets.otherAssets[0] : undefined;
        if (tokenState) {
          const newBalance = getTokenAmount(tokenState);
          setBalance(newBalance);
          cachedBalances.set(token.abbr, newBalance);
        }

      }).catch(e => {
        log.error('cannot get balance for token', token.abbr, e);
      });
    }

    return cleanupFn;

  }, [loadBalance, loadBalanceDelay, token, address]);

  const fullName = token.fullName || `${token.abbr} Coin`;

  return (
    <Button
      className={classes.tokenButton}
      variant="outlined"
      type="submit"
      data-test={`select-token-${token.abbr}`}
      onClick={async ()=>{

        Metrika.reach(Goal.wallet.ChangeTargetToken);

        if(token.altCoin){
          Metrika.reach(Goal.wallet.AltCoinSelected);
          Action.swap.SetSelectedAlt(token)(dispatch);
        }
        else {
          Metrika.reach(Goal.wallet.TargetCoinSelected);
        }

        Action.walletProvider.SelectTokenToPay(token)(dispatch);
        Action.wallet.SetDialog('none')(dispatch);
      }}
    >
      <img className={clsx(commonClasses.icon24, ClassName.SkipOpacity)} src={token.logo} alt="" />
      <Space direction="vertical" size={0}>
        <Typography color="textPrimary" className={classes.tokenTitle}>
          {fullName}
        </Typography>
        <Typography color="textSecondary" className={classes.tokenTitleDesc}>
          {token.abbr}
        </Typography>
      </Space>


      <div className={commonClasses.spaceBlock}/>

      {loadBalance && balance === undefined &&
        <div>-</div>
      }
      {loadBalance && balance !== undefined &&
        <Typography color="textPrimary" className={classes.tokenTitle}>
          {i18n.formatAmount(balance)}
        </Typography>
      }

    </Button>
  )
}


export function SelectTokenDialog(){

  const i18n = useI18n();
  const classes = styles();

  const payTokens = useSelector((state: RootState) => state.walletProvider.payTokens);
  const selectedToken = useSelector((state: RootState) => state.walletProvider.selectedToken);
  const address = useSelector((state: RootState) => state.wallet.address);
  const showAlts = useShowAlts();

  const tokensToShow = payTokens.length > 3? payTokens.slice(0, 3) : payTokens;

  const network = selectedToken?.network;
  const altCoinsToShow = useMemo(()=>{

    if( ! showAlts)
      return [];

    const list = network? getBscAltTokensToPay(network, payTokens): [];
    list.sort((a, b)=> {
      const orderA = a.globalOrder !== undefined? a.globalOrder : Number.MAX_VALUE;
      const orderB = b.globalOrder !== undefined? b.globalOrder : Number.MAX_VALUE;
      return orderA !== orderB? Util.ascSort(orderA, orderB) : Util.ascSort(a.abbr, b.abbr);
    });
    return list;
  }, [showAlts, network, payTokens]);

  return (
    <Space direction="vertical" className={clsx(classes.root, 'select-token-dialog')}>

      <HeaderWithMenuAndBack
        title={i18n.m('all_tokens.title')}
        className={classes.title}
      />

      <Space direction="vertical" className={classes.listWrapper}>
        { tokensToShow.map((token, i) => (
          <TokenToSelect
            token={token}
            address={address}
            loadBalance
            key={token.abbr} />
        ))}

        { altCoinsToShow.map((token, i) => (
          <TokenToSelect
            token={token}
            address={address}
            loadBalance
            loadBalanceDelay={Math.floor(i/5) * 500} // load 5 tokens per time and pause for 500ms
            key={token.abbr}
          />
        ))}
      </Space>

    </Space>
  );
}
