import { call, put, select, takeEvery } from 'redux-saga/effects';
import { Action } from '@/model/actions';
import { ActionWithPayload, withErrorHandler, withSingleWorker } from '@/utils/redux/action-creator';
import { getRecaptchaToken } from '@/api/support/recaptcha3';
import { CheckCodeResp, queryAuth0CheckCode, queryAuth0SendCode } from '@/api/auth0';
import { RootState } from '@/model/types';
import { ApiError } from '@/model/common/ApiError';
import { Notification } from '@/model/global/types';


function* startWithEmail(
  {payload: email}: ActionWithPayload<string>
){

  yield put(Action.emailEnter.support.SetErrorCode(undefined).pure);
  yield put(Action.emailEnter.support.SetLoading(true).pure);
  try {

    const recaptchaToken = (yield call(getRecaptchaToken, 'auth0SendCode')) as string;
    (yield call(queryAuth0SendCode, email, recaptchaToken));

    yield put(Action.emailEnter.support.SetEmail(email).pure);
    yield put(Action.emailEnter.support.SetStep('enter_code').pure);

  }
  catch (e: any){

    // validation error
    if(e.code === ApiError.login.BadEmail){
      yield put(Action.emailEnter.support.SetErrorCode(e.code).pure);
      return;
    }

    if(e.code === ApiError.common.RateLimit){
      const notification: Notification = {
        messageId: 'global_error.common.rate_limit',
        values: {
          timeout: e.details?.timeoutSec || 60
        }
      };
      yield put(Action.global.AddError(notification).pure);
      return;
    }

    // unknown error
    throw e;
  }
  finally {
    yield put(Action.emailEnter.support.SetLoading(false).pure);
  }
}


function* sendCode(
  {payload: code}: ActionWithPayload<string>
){

  const rootState = (yield select((state: RootState) => state)) as RootState;
  const {email} = rootState.emailEnter;

  if( ! email)
    return;

  yield put(Action.emailEnter.support.SetErrorCode(undefined).pure);
  yield put(Action.emailEnter.support.SetLoading(true).pure);
  try {

    const recaptchaToken = (yield call(getRecaptchaToken, 'auth0CheckCode')) as string;
    const {token} = (yield call(queryAuth0CheckCode, email, code, recaptchaToken)) as CheckCodeResp;

    const normalEmail = email.toLowerCase().trim();
    yield put(Action.emailEnter.support.SetStep('wait_login').pure);
    yield put(Action.torus.StartTokenLogin({
      type: 'email',
      token,
      email: normalEmail
    }).pure);

  }
  catch (e: any){

    // validation error
    if(e.code === ApiError.login.BadCode){
      yield put(Action.emailEnter.support.SetErrorCode(e.code).pure);
      return;
    }

    // enter session is done
    if(e.code === ApiError.login.CodeExpired
      || e.code === ApiError.login.TriesLimit
      || e.code === ApiError.login.EmailBlocked){

      if(e.code === ApiError.login.CodeExpired) {
        yield put(Action.global.AddError({ messageId: 'enter_by_email.code.code_expired' }).pure);
      }
      else if(e.code === ApiError.login.TriesLimit){
        yield put(Action.global.AddError({ messageId: 'enter_by_email.code.tries_limit' }).pure);
      }
      else if(e.code === ApiError.login.EmailBlocked){
        yield put(Action.global.AddError({ messageId: 'enter_by_email.email.blocked' }).pure);
      }

      yield put(Action.emailEnter.support.SetStep('error').pure);
      return;
    }

    // unknown error
    throw e;
  }
  finally {
    yield put(Action.emailEnter.support.SetLoading(false).pure);
  }
}



export default function* rootSaga(): Generator {

  yield takeEvery(Action.emailEnter.StartWithEmail.type,
    withErrorHandler(
      withSingleWorker(
        startWithEmail
    )));

  yield takeEvery(Action.emailEnter.SendCode.type,
    withErrorHandler(
      withSingleWorker(
        sendCode
      )));
}
