import { actionCreator } from '@/utils/redux/action-creator';
import { WalletProviderType } from '@/model/wallet/provider/types';
import { RestoreData } from '@/model/wallet/restore/types';


const creator = actionCreator('WALLET_RESTORE');


export const WalletRestoreAction = {

  SetSuccessProvider: creator.syncActionWithPayload<WalletProviderType>('SET_SUCCESS_PROVIDER'),
  SaveData: creator.syncAction('SAVE_DATA'),
  LoadData: creator.syncAction('LOAD_DATA'),
  SetData: creator.syncActionWithPayload<RestoreData>('SET_DATA'),
  SetShowRestoringProgress: creator.syncActionWithPayload<boolean>('SET_SHOW_RESTORING_PROGRESS'),
};

