import React from 'react';
import { SelectWalletPanel } from '@/components/wallet/panel/SelectWalletPanel';
import { HeaderWithMenu } from '@/components/wallet/common/HeaderWithMenu';


export function SelectWallet(){

  return (
    <SelectWalletPanel title={<HeaderWithMenu/>}/>
  );
}
