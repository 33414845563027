import { Net } from '@/utils/axios';
import { getAuthorizationHeader, getSessionId } from '@/api/auth';

const timeout = 3000;


export async function queryGetSessionKey(): Promise<string|undefined>{

  const authHeader = await getAuthorizationHeader();
  const sessionId = getSessionId();

  if( ! sessionId)
    return undefined;

  const result: any = await Net.get(`/wallet/session-key?session-id=${sessionId}`, {
    timeout,
    headers: {
      ...authHeader
    }
  });
  return result.sessionKey;
}


export async function queryClearSessionKey(): Promise<any> {
  const sessionId = getSessionId();
  return Net.get(`/wallet/clear-session-key?session-id=${sessionId}`, {
    timeout,
  })
}
