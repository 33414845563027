import React, { useLayoutEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Util } from '@/utils/util';
import { Action } from '@/model/actions';
import { EmptyRender } from '@/utils/react-util';
import { initCrossDomainConnection } from '@/api/cross_domain_connection';
import { LocalStorage } from '@/utils/storage';


const log = Util.getLog('withRestoreLocalStorage');


async function restoreLocalStorage(){
  try {
    await initCrossDomainConnection();
  } catch (e){
    log.error('cannot restoreLocalStorage', e);
  } finally {
    // now can detect localStorage updates for other cross domains
    LocalStorage.setEnableUpdateTimestamp(true);
  }
}

const withRestoreLocalStorage = (Component: React.FC) => (props: any) => {

  const dispatch = useDispatch();
  const [restored, setRestored] = useState(false);

  useLayoutEffect(()=>{

    if(!restored){

      Action.global.SetLoading({loading: true})(dispatch);

      restoreLocalStorage().then(()=>{
        setRestored(true);
      });

    } else {
      Action.global.SetLoading({loading: false})(dispatch);
    }

  }, [restored, setRestored, dispatch]);

  if( ! restored)
    return <EmptyRender msg="RestoreLocalStorage" details={{restored}}/>

  return (
    <>
      <Component {...props} />
    </>
  );
}

export default withRestoreLocalStorage;
