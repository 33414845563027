import { actionCreator } from '@/utils/redux/action-creator';
import { RateState, SwapPath, SwapPathReq } from '@/model/swap/types';
import { TokenDef } from '@/model/common/Assets';


const creator = actionCreator('SWAP');


export const SwapAction = {

  LoadRate:     creator.syncAction('LOAD_RATE'),
  ResetRate:    creator.syncAction('RESET_RATE'),
  SetSelectedAlt: creator.syncActionWithPayload<TokenDef|undefined>('SET_SELECTED_ALT'),

  support: {
    SetRateState: creator.syncActionWithPayload<RateState>('SET_RATE_STATE'),
    SetSwapPathReq:  creator.syncActionWithPayload<SwapPathReq|undefined>('SET_SWAP_PATH_REQ'),
    SetSwapPath:  creator.syncActionWithPayload<SwapPath>('SET_SWAP_PATH'),
  }
};

