import { createReducer, createStateHandlers } from '@/utils/redux/reducer-util';
import { Action } from '@/model/actions';
import { initialState, RestoreData, WalletRestoreState as S } from '@/model/wallet/restore/types';
import { ActionWithPayload } from '@/utils/redux/action-creator';
import { WalletProviderType } from '@/model/wallet/provider/types';

const { p2f } = createStateHandlers<S>();

export const reducers = createReducer<S>(initialState, {

  // p2f<S['']>(''),

  [Action.walletRestore.SetShowRestoringProgress.type]: p2f<S['showRestoringProgress']>('showRestoringProgress'),

  [Action.walletRestore.SetData.type](
    state: S, { payload }: ActionWithPayload<RestoreData>
  ): S {
    return {
      ...state,
      status: 'loaded',
      data: {
        ...payload
      }
    };
  },

  [Action.walletRestore.SetSuccessProvider.type](
    state: S, { payload }: ActionWithPayload<WalletProviderType>
  ): S {
    const {data} = state;
    return {
      ...state,
      data: {
        ...data,
        provider: payload
      }
    };
  },

});

export default reducers;
