/**
 * from: https://www.jayfreestone.com/writing/react-portals-with-hooks/
 * */
import { useRef, useEffect } from 'react';

/**
 * Creates DOM element to be used as React root.
 */
function createRootElement(id: string): HTMLElement {
  const rootContainer = document.createElement('div');
  rootContainer.setAttribute('id', id);
  return rootContainer;
}

/**
 * Appends element as last child of body.
 */
function addRootElement(rootElem: HTMLElement) {
  document.body.appendChild(rootElem);
}

/**
 * Hook to create a React Portal.
 * Automatically handles creating and tearing-down the root elements (no SRR
 * makes this trivial), so there is no need to ensure the parent target already
 * exists.
 * @example
 * const target = usePortal(id, [id]);
 * return createPortal(children, target);
 */
export function usePortal(id: string): HTMLElement {

  const rootElemRef = useRef<HTMLElement>(null);

  useEffect(()=> {

    const existingParent = document.querySelector(`#${id}`);
    const parentElem = (existingParent || createRootElement(id)) as HTMLElement;

    if ( ! existingParent) {
      addRootElement(parentElem);
    }

    if(rootElemRef.current){
      parentElem.appendChild(rootElemRef.current);
    }

    return ()=> {

      if(rootElemRef.current){
        rootElemRef.current.remove();
      }

      if (!parentElem.childElementCount) {
        parentElem.remove();
      }
    };
  }, [id]);

  /**
   * It's important we evaluate this lazily:
   * - We need first render to contain the DOM element, so it shouldn't happen
   *   in useEffect. We would normally put this in the constructor().
   * - We can't do 'const rootElemRef = useRef(document.createElement('div))',
   *   since this will run every single render (that's a lot).
   * - We want the ref to consistently point to the same DOM element and only
   *   ever run once.
   * @link https://reactjs.org/docs/hooks-faq.html#how-to-create-expensive-objects-lazily
   */
  function getRootElem() {
    if ( ! rootElemRef.current) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      rootElemRef.current = document.createElement('div');
    }
    return rootElemRef.current;
  }

  return getRootElem();
}
