import { Link, TextField, Typography } from '@material-ui/core';
import React, { useLayoutEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import { Space } from '@/components';
import { important } from '@/components/Style';
import { useI18n } from '@/i18n';
import { getTokenAmount } from '@/model/common/Assets';
import { HeaderWithMenuAndBack } from '@/components/wallet/common/HeaderWithMenuAndBack';
import { useSelectedTokenBalance } from '@/components/wallet';
import { MathUtil } from '@/utils/math-util';


const styles = makeStyles((theme: Theme) => {

  return {
    root: {
      maxHeight: '540px',
    },
    title: {
      marginBottom: '10px',
    },
    description: {
      marginTop: '1px',
      marginBottom: important('22px'),
    },
    maxLink: {
      marginTop: '4px',
      textAlign: 'right',
      fontSize: '15px',
    }
  };
});




export function WithdrawalDialog(){

  const i18n = useI18n();
  const classes = styles();

  const [amountStr, setAmountStr] = useState<string>('');
  const [amount, setAmount] = useState<number>(0);

  const tokenBalance = useSelectedTokenBalance();
  const loading = false//useSelector((state: RootState) => state.emailEnter.loading);


  const tokenAmount = getTokenAmount(tokenBalance);

  const isBadAmountFormat = amount < 0;
  const isTooBigAmount = amount > tokenAmount;
  const isAmountError = isBadAmountFormat || isTooBigAmount;

  let amountHelperText: string|undefined;
  if(isBadAmountFormat){
    amountHelperText = i18n.m('withdrawal.amount.bad_value');
  }
  else if(isTooBigAmount){
    amountHelperText = i18n.m('withdrawal.amount.insufficient_funds');
  }

  // parse amount
  useLayoutEffect(()=>{
    if(amountStr.length === 0){
      setAmount(0);
    }
    else {
      setAmount(MathUtil.parseAnyFloatFormat(amountStr, -1))
    }
  }, [amountStr, setAmount]);

  if( ! tokenBalance){
    return <></>;
  }

  return (
    <Space direction="vertical" className={clsx(classes.root, 'withdrawal-dialog')}>

      <HeaderWithMenuAndBack
        title={i18n.m('withdrawal.title')}
        className={classes.title}
      />

      <Typography color="textSecondary" className={classes.description}>
        {i18n.m('withdrawal.description')}{' '}{tokenBalance.abbr}
      </Typography>

      <TextField
        name="amount"
        placeholder={i18n.m('withdrawal.amount.placeholder')}
        value={amountStr}
        disabled={loading}
        InputProps={{
          readOnly: loading,
        }}
        onChange={(event)=>{
          const normalValue = (event.target.value || '')
            .trim()
            .replace(/[^0-9.,]/g, '');
          setAmountStr(normalValue);
        }}
        onKeyDown={(e)=>{
          if(e.key === 'Enter'){
            //nextAction();
          }
        }}
        error={isAmountError}
        helperText={amountHelperText}
      />

      <Link
        component="button"
        variant="body2"
        data-test="use-all-amount"
        className={classes.maxLink}
        onClick={()=>{
          setAmountStr(i18n.formatAmount(tokenAmount));
        }}>
        {i18n.m('withdrawal.amount.use_max')}
        {' '}
        {i18n.formatAmount(tokenAmount)}
        {' '}
        {tokenBalance.abbr}
      </Link>

    </Space>
  );
}
