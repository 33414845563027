import React from 'react';
import { useDispatch } from 'react-redux';
import { Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import { makeCommonStyles, Space } from '@/components';
import { Action } from '@/model/actions';
import { DialogPageTitle } from '@/components/wallet/dialog/GlobalMenuDialog/DialogPageTitle';
import { curLinkWithLang, getLangMessages, useI18n } from '@/i18n';
import { Goal, Metrika } from '@/api/metrika';
import { Color, important } from '@/components/Style';
import { ReactComponent as LangIcon } from '@/assets/icons/global-menu-lang.svg';
import { ReactComponent as AboutIcon } from '@/assets/icons/global-menu-about.svg';
import { ReactComponent as EmailIcon } from '@/assets/icons/email-icon.svg';
import { ReactComponent as ExternalLinkIcon } from '@/assets/icons/external-link-gray.svg';
import { Lang, LangKeys } from '@/model/common/Lang';
import { Util } from '@/utils/util';
import { useMailtoLink } from '@/components/wallet';
import clsx from 'clsx';

const commonStyles = makeCommonStyles();
const styles = makeStyles((theme: Theme) => {

  return {
    title: {
      marginBottom: important('28px')
    },
    menuButton: {
      border: important('none'),
      paddingLeft: '8px',
      paddingRight: '8px',
      '& .MuiButton-label': {
        fontSize: '15px',
        fontWeight: 400,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        textAlign: 'left',
        gap: '16px',
        color: important(Color.text.Main),
      },
      '& .MuiButton-label > svg': {
        flexShrink: 0,
      }
    },
    langTitle: {
      marginTop: '12px',
      paddingLeft: '8px',
      color: Color.text.Main,
      display: 'flex',
      gap: '16px',
      justifyContent: 'flex-start',
      alignItems: 'center',
      '& svg': {
        flexShrink: 0,
      }
    },
    langPanel: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'start',
      '& > *': {
        flexShrink: 0,
      },
    },
    langButton: {
      minWidth: 'initial',
      width: 'initial',
      paddingLeft: '8px',
      paddingRight: '8px',
      '& .MuiButton-label': {
        justifyContent: 'end',
      }
    },
    langList: {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
    }
  }
});

interface SelectLangButtonProps {
  lang: Lang,
}

function SelectLangButton(
  {
    lang
  }: SelectLangButtonProps
){

  const classes = styles();
  const msgs = getLangMessages(lang);

  return (
    <Button
      className={clsx(classes.menuButton, classes.langButton)}
      variant="outlined"
      type="submit"
      data-test={`change-lang-to-${lang}`}
      onClick={async ()=>{

        Metrika.reach(Goal.globalMenu.ChangeLang);
        await Util.timeout(100); // wait for metrika sending

        window.location.href = curLinkWithLang(lang);
      }}
    >
      <Typography color="textSecondary">
        {msgs.global_menu.switch_to}
      </Typography>
    </Button>
  );
}



export function GlobalMenuDialog(){

  const i18n = useI18n();
  const dispatch = useDispatch();
  const commonClasses = commonStyles();
  const classes = styles();

  const {lang} = i18n;
  const mailtoLink = useMailtoLink();

  return (
    <Space direction="vertical" size={0.5}>


      <DialogPageTitle
        title={i18n.m('global_menu.title')}
        className={classes.title}
        globalMenuLink={false}
        onClick={()=>{
          Metrika.reach(Goal.globalMenu.Hide);
          Action.global.ShowGlobalMenu(false)(dispatch);
        }}
      />

      <div className={classes.langPanel}>

        <div className={classes.langTitle}>
          <LangIcon className={commonClasses.icon24} />
          <span>
            {i18n.m('global_menu.current_lang')}
          </span>
        </div>

        <div className={classes.langList}>
          {LangKeys.filter(l => l !== lang).map(otherLang => <SelectLangButton lang={otherLang}/>)}
        </div>
      </div>



      <Button
        className={classes.menuButton}
        variant="outlined"
        type="submit"
        data-test="open-site"
        onClick={async ()=>{

          Metrika.reach(Goal.globalMenu.OpenAbout);
          await Util.timeout(100); // wait for metrika sending

          window.open('https://smartypay.io', '_blank');
        }}
      >
        <AboutIcon className={commonClasses.icon24} />
        <span>
          {i18n.m('global_menu.about')}
        </span>
        <div className={commonClasses.spaceBlock}/>
        <ExternalLinkIcon/>
      </Button>

      <Button
        className={classes.menuButton}
        variant="outlined"
        type="submit"
        data-test="open-help"
        onClick={async ()=>{

          Metrika.reach(Goal.globalMenu.OpenHelp);
          await Util.timeout(100); // wait for metrika sending

          // special open case for "mailto:" protocol (from https://stackoverflow.com/a/49559804)
          const mail = document.createElement('a');
          mail.href = mailtoLink;
          mail.click();
        }}
      >
        <EmailIcon className={commonClasses.icon24} />
        <span>
          {i18n.m('global_menu.help')}
        </span>
      </Button>

    </Space>
  );
}
