import { Net } from '@/utils/axios';
import { MoonpayCryptoCurrency, MoonpayFiatCurrency } from '@/model/onRamp/moonpay/types';
import { getAuthorizationHeader } from '@/api/auth';


export interface MoonpayQuoteResp {
  baseCurrencyCode: MoonpayFiatCurrency,
  baseCurrencyAmount: number,
  quoteCurrencyCode: MoonpayCryptoCurrency,
  quoteCurrencyAmount: number,
  quoteCurrencyPrice: number,
  paymentMethod: string,
  feeAmount: number,
  extraFeeAmount: number,
  networkFeeAmount: number,
  totalAmount: number,
}

export async function queryMoonpayBuyQuote(
  cryptoToBuy: MoonpayCryptoCurrency,
  baseCurrency: MoonpayFiatCurrency,
  baseCurrencyAmount: number|string,
  apiKey: string): Promise<MoonpayQuoteResp> {

  const url = `https://api.moonpay.com/v3/currencies/${cryptoToBuy}/buy_quote/?`
    +`apiKey=${apiKey}`
    +`&baseCurrencyAmount=${baseCurrencyAmount}`
    +`&baseCurrencyCode=${baseCurrency}`
    +'&extraFeePercentage=0'
    +'&paymentMethod=credit_debit_card';

  return Net.get(url);
}


export async function querySignUrl(url: string): Promise<string> {
  const authHeader = await getAuthorizationHeader();
  const resp: any = await Net.post('/moonpay/sign-url', {url}, {
    headers: {
      ...authHeader
    }
  });

  return resp.signature;
}
