import { actionCreator } from '@/utils/redux/action-creator';
import { Reward } from '@/model/rewards/types';


const creator = actionCreator('REWARDS');


export const RewardsAction = {

  Load:       creator.syncAction('LOAD'),
  Reset:      creator.syncAction('RESET'),
  UseReward:  creator.syncActionWithPayload<Reward>('USE_REWARD'),
  WaitReward: creator.syncActionWithPayload<string>('WAIT_REWARD'),
  support: {
    SetList:  creator.syncActionWithPayload<Reward[]>('SET_LIST'),
    SetProcessingReward: creator.syncActionWithPayload<string|undefined>('SET_PROCESSING_REWARD'),
  }
};

