import { LSCache } from '@/utils/storage';
import { OneWeek } from '@/utils/date-util';
import { Util } from '@/utils/util';

const log = Util.getLog('api/image_preload');
const cacheSize = 100;
const cacheTime = OneWeek;
const cacheKey = 'img-cache';

export const ImgToCacheBuffer: string[] = [];

export function storeImagesToPreload(imgUrls: string[]){

  const urlsSet: Set<string> = new Set(LSCache.get(cacheKey) || []);

  imgUrls.forEach(url => {

    if(!url){
      return;
    }

    if ( url.startsWith('data:')){
      log.info('invalid image format to cache', url.substring(0, 30), '...')
      return;
    }

    if(urlsSet.size >= cacheSize){
      log.info('image cache is full');
      return;
    }

    urlsSet.add(url);
  });

  LSCache.set(cacheKey, Array.from(urlsSet), cacheTime);
}

export function preloadImagesFromCache(){

  const urls: string[] = LSCache.get(cacheKey) || [];

  log.info('preload images', urls.length);

  const loads: Promise<any>[] = [];
  const badUrls: string[] = [];

  urls.forEach((url: string) => {
    if(url){

      const promise = preloadImage(url).then(success => {
        if( ! success){
          badUrls.push(url);
        }
      }).catch(Util.onErr);

      loads.push(promise);
    }
  });

  Promise.all(loads).then(()=> {
    if(badUrls.length > 0){
      const newUrls = urls.filter(url => !badUrls.includes(url));
      LSCache.set(cacheKey, newUrls, cacheTime);
    }
  })
}


export async function preloadImage(imgUrl?: string) {

  let done = false;
  let timeoutId: any;
  const waitTimeout = 1000;

  return new Promise((resolve) => {

    const onDone = (success: boolean)=>{

      if(done)
        return;

      done = true;
      clearTimeout(timeoutId);

      resolve(success);
    };

    if( ! imgUrl) {
      onDone(false);
      return;
    }

    const img = new Image();

    // success load
    img.onload = ()=>{
      onDone(true);
    };

    // failed to load
    img.onerror = ()=>{
      onDone(false);
    }
    timeoutId = setTimeout(()=>{
      onDone(false);
    }, waitTimeout);


    // start
    img.src = imgUrl;

    // already loaded
    if(img.complete)
      onDone(true);
  })

}