import { IntlShape } from 'react-intl/src/types';
import { useIntl } from 'react-intl';
import { useMemo } from 'react';
import { MsgKey } from '@/i18n/Msg';
import { formatAmount, FormatOptions } from '@/model/common/Money';
import { Lang, toLang } from '@/model/common/Lang';
import ru from './ru.json';
import en from './en.json';
import es from './es.json';

export const AllMessages = {
  en,
  ru,
  es,
}

export function getLangMessages(lang: Lang){
  return AllMessages[lang] || en;
}

export interface I18n {

  m(id: MsgKey, values?: Record<string, any>): string,

  hasM(id: any): boolean,

  formatAmount(
    value: number|string|undefined,
    formatOptions?: FormatOptions,
  ): string,

  readonly lang: Lang,

  readonly intl: IntlShape,
}

export function useI18n(): I18n {

  const intl = useIntl();

  return useMemo<I18n>(()=>{
    const lang = toLang(intl.locale);
    const i18n: I18n = {
      intl,
      lang,

      m(id, values?: Record<string, any>){
        return intl.formatMessage({id}, values);
      },

      hasM(id: any): boolean {
        return !! intl.messages[id];
      },

      formatAmount(value, formatOptions?): string {
        const opts: FormatOptions = {
          decimalSeparator: i18n.m('format.decimalSeparator'),
          ...(formatOptions || {})
        }
        return formatAmount(value, opts);
      }
    };
    return i18n;

  }, [intl]);


}


export function curLinkWithLang(lang: Lang){

  const {location} = window;

  const query = new URLSearchParams(location.search);
  query.set('lang', lang);

  return `${location.protocol}//${location.host}${location.pathname}?${query}`;
}
