import React, { ReactNode, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { WelcomePanel } from '@/components/wallet/panel/SelectWalletPanel/WelcomePanel';
import { RestoringScreen, useNeedRestoringScreen } from '@/components/wallet/panel/SelectWalletPanel/RestoringScreen';
import { Space } from '@/components';
import { ButtonsModal } from '@/components/wallet/panel/SelectWalletPanel/ButtonsModal';
import { Goal, Metrika } from '@/api/metrika';
import { Action } from '@/model/actions';
import { RootState } from '@/model/types';


const restoringWaitTime = 6000;

export interface SelectWalletPanelProps {
  title : ReactNode;
}

export function SelectWalletPanel(
  {
    title,
  }: SelectWalletPanelProps
){

  const dispatch = useDispatch();
  const [showButtonsModal, setShowButtonsModal] = useState<boolean>(true);
  const needRestoringScreen = useNeedRestoringScreen();

  const torusCred = useSelector((state: RootState) => state.torus.cred);
  const restoredProvider = useSelector((state: RootState) => state.walletRestore.data.provider);

  // start restore previous wallet session
  useLayoutEffect(()=>{

    if(torusCred === undefined
      || !restoredProvider
      || restoredProvider === 'none')
      return;

    let waitForRestoring = false;

    if(restoredProvider === 'inner' && torusCred){
      waitForRestoring = true;

      if(torusCred.loginType === 'google'){
        Metrika.reach(Goal.wallet.EnterByGmail);
      }
      else if(torusCred.loginType === 'email'){
        Metrika.reach(Goal.wallet.EnterByEmail);
      }

      Action.dapp.inner.SetInnerProvider()(dispatch);
      Action.wallet.Reload()(dispatch);
    }
    else if(restoredProvider === 'metamask'){
      waitForRestoring = true;

      Metrika.reach(Goal.wallet.EnterByMetamask);
      Action.dapp.metamask.SetMetamaskProvider()(dispatch);
    }
    else if(restoredProvider === 'binanceWallet'){
      waitForRestoring = true;

      Metrika.reach(Goal.wallet.EnterByBinanceWallet);
      Action.dapp.binanceWallet.SetBinanceProvider()(dispatch);
    }

    // give time to restoring process
    const waitTime = waitForRestoring? restoringWaitTime : 0;
    setTimeout(()=>{
      Action.walletRestore.SetSuccessProvider('none')(dispatch);
    }, waitTime);

  }, [restoredProvider, torusCred, dispatch])

  if(needRestoringScreen) {
    return <RestoringScreen/>
  }

  return (
    <Space direction="vertical">

      {title}

      <WelcomePanel onOpenWallet={()=>{
        setShowButtonsModal(true);
      }}/>

      {showButtonsModal &&
        <ButtonsModal onClose={()=>{
          setShowButtonsModal(false);
        }}/>
      }

    </Space>
  );
}
