import { createReducer, createStateHandlers } from '@/utils/redux/reducer-util';
import { Action } from '@/model/actions';
import { AuthState as S, initialState } from '@/model/auth/types';

const { p2f } = createStateHandlers<S>();

export const reducers = createReducer<S>(initialState, {

  // p2f<S['']>(''),

  [Action.auth.support.SetAuth.type]: p2f<S['data']>('data'),

});

export default reducers;
