import { Network } from '@/model/common/Network';
import { TokenDef } from '@/model/common/Assets';
import { WalletAssets } from '@/model/wallet/assets/types';
import { ContractTxInfo, TxInfo, TxInfoReq } from '@/model/common/Blockchain';


export const Web3CommonTimeout = 10000;
export type ProcessingVersion = 10 | 9 | 7 | 6;


export interface Web3Urls {
  mainPublic: string,
  testPublic: string,
  canUseMainProxy: boolean,
  canUseTestProxy: boolean,
}


export interface ProcessingInfo {
  processing: string,
  router: string,
}

export const StubAddress = '???';

export interface BaseBlockchainApi {

  findNetwork(chainId: string | undefined): Network | undefined,

  privateKeyToAccount(network: Network, privateKey: string): string,

  processingInfo(network: Network, version: ProcessingVersion): ProcessingInfo,

  getTxUrl(txId: string, network: Network): string,

  getAddressUrl(address: string, network: Network): string,

  getAllowance(
    network: Network,
    tokenId: string,
    ownerAddress: string,
    spenderAddress: string,
  ): Promise<number>,

  getWalletAssets(
    network: Network,
    address: string,
    targetAssets: TokenDef[]
  ): Promise<WalletAssets>,

  getTxInfo(req: TxInfoReq): Promise<TxInfo>,

  getContactTxInfo(req: TxInfoReq): Promise<ContractTxInfo>,

  hasContract(network: Network, address: string): Promise<boolean>,

}


export function isOutOfGasError(msg: string|undefined){

  if(!msg)
    return false;

  return msg.includes('gas too low')
    || msg.includes('out of gas')
    || msg.includes('transaction underpriced')
}
