import clsx from 'clsx';
import React, { PropsWithChildren, useEffect, useLayoutEffect, useState } from 'react';
import { Button, Link, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { ModalDialog } from '@/components/common/ModalDialog';
import { ClassName, Color, important } from '@/components/Style';
import { useI18n } from '@/i18n';
import { makeCommonStyles, makeMainStyles, Space } from '@/components';
import { Config } from '@/config';
import { BinanceWalletApi } from '@/api/dapp/binance_wallet';
import { MetamaskApi } from '@/api/dapp/metamask';
import { SingleTimeout } from '@/utils/SingleTimeout';
import { RootState } from '@/model/types';
import { Util } from '@/utils/util';
import { Goal, Metrika } from '@/api/metrika';
import { Action } from '@/model/actions';
import { ReactComponent as GoogleIcon } from '@/assets/icons/google-icon.svg';
import { ReactComponent as EmailIcon } from '@/assets/icons/email-icon.svg';
import { EnteringButtonContent } from '@/components/common/EnteringButtonContent';
import { Notification } from '@/components/common/Notification';
import { WalletIcon, WalletLabel } from '@/components/wallet';
import { EnterByEmailModal } from '@/components/wallet/panel/SelectWalletPanel/EnterByEmailModal';

const skipLogoutButtonTime = 3000;

type EnterType =
  'gmail'
  | 'mail'
  | 'metamask'
  | 'binanceWallet'
  | 'demoWallet';

const styles = makeStyles((theme: Theme) => {

  return {
    dialog: {
      textAlign: 'center',
      paddingBottom: 0,
    },
    title: {
      fontSize: '24px',
      lineHeight: '40px',
      margin: important('32px 32px 16px 32px')
    },
    content: {
      paddingLeft: '24px',
      paddingRight: '24px',
    },
    buttonsRow: {
      justifyContent: 'center',
      marginBottom: '8px',
    },
    button: {
      borderWidth: important('0'),
      height: '116px',
      maxWidth: '152px',
      paddingTop: '16px',
      paddingLeft: '4px',
      paddingRight: '4px',
      color: Color.text.Main,
      '& .MuiButton-label': {
        display: 'flex',
        flexDirection: 'column',
      }
    },
    buttonLabel: {
      marginTop: '16px',
      fontSize: '14px',
      lineHeight: '19px',
      fontWeight: 400,
      display: 'flex',
    },
    cancelBlock: {
      marginTop: '24px',
      height: '21px',
    },
  }
});

const commonStyles = makeCommonStyles();
const mainStyles = makeMainStyles();


type ButtonContentProps = PropsWithChildren<{
  entering: boolean
}>;

function ButtonContent(
  {
    entering,
    children,
  }: ButtonContentProps
){
  const i18n = useI18n();
  const classes = styles();
  return (
    <span className={classes.buttonLabel}>
      { ! entering && children}
      { entering && <EnteringButtonContent title={i18n.m('common.entering')} />}
    </span>
  );
}


export interface ButtonsModalProps {
  onClose: ()=>void;
}

export function ButtonsModal(
  {
    onClose,
  }: ButtonsModalProps
){

  const i18n = useI18n();
  const classes = styles();
  const dispatch = useDispatch();
  const commonClasses = commonStyles();
  const mainClasses = mainStyles();

  const hasBinanceWallet = BinanceWalletApi.hasExternalDapp();
  const hasMetamask = MetamaskApi.hasExternalDapp();

  const [enterType, setEnterType] = useState<EnterType>();

  const [skipLogoutButton, setSkipLogoutButton] = useState(false);
  const [skipLogoutTimer] = useState(()=> new SingleTimeout(skipLogoutButtonTime));

  const torusCred = useSelector((state: RootState) => state.torus.cred);
  const providerStarting = useSelector((state: RootState) => state.walletProvider.starting);
  const walletLoading = useSelector((state: RootState) => state.wallet.loading);
  const torusLoading = useSelector((state: RootState) => state.torus.loading);
  const torusEntering = useSelector((state: RootState) => state.torus.entering);
  const providerStartError = useSelector((state: RootState) => state.walletProvider.startError);
  const demoMode = useSelector((state: RootState) => Util.parseBool(state.params['demo-mode']));

  const emailEnterStep = useSelector((state: RootState) => state.emailEnter.step);
  const actionDisabled = walletLoading || torusLoading || torusEntering || providerStarting;
  const needBinanceSmartChain = providerStartError === 'need_binance_smart_chain';

  const googleEntering = enterType === 'gmail';
  const emailEntering = enterType === 'mail';
  const metamaskEntering = enterType === 'metamask';
  const binanceWalletEntering = enterType === 'binanceWallet';

  const showGmail = ! demoMode && Config.toggles['login-by-gmail'];
  const showMail = ! demoMode && Config.toggles['login-by-mail'];
  const showMetamask = Config.toggles['login-by-metamask'];
  const showBinanceWallet = Config.toggles['login-by-binance-wallet'];

  const showEmailEnterModal = emailEntering && emailEnterStep !== 'wait_login';


  useEffect(()=>{
    if( ! actionDisabled){
      setEnterType(undefined);
    }
  }, [actionDisabled]);

  // clean up
  useEffect(() => {
    return () => {
      skipLogoutTimer.clearTimeout();
    };
  }, [skipLogoutTimer, dispatch]);


  // wait to show a logout button for a while
  useLayoutEffect(()=>{
    if(providerStarting){

      setSkipLogoutButton(true);

      skipLogoutTimer.setTimeout(()=> {
        setSkipLogoutButton(false);
      })
    }
  }, [providerStarting, setSkipLogoutButton, skipLogoutTimer]);


  let providerError: string|undefined;
  if(providerStartError === 'access_denied'){
    providerError = i18n.m('provider.error.access_denied');
  }
  else if(needBinanceSmartChain){
    providerError = i18n.m('provider.error.need_bsc');
  }
  else if(providerStartError === 'unsupported_token'){
    providerError = i18n.m('provider.error.unsupported_token');
  }
  else if(providerStartError === 'wait_other_operation'){
    providerError = i18n.m('provider.error.wait_other_operation')
  }

  return (
    <>

      {providerError &&
        <Notification type="warning" title={providerError} showClose={false}/>
      }

      <ModalDialog onClose={onClose} className={classes.dialog} hideDialog={showEmailEnterModal}>

        <Typography color="textPrimary" className={clsx(classes.title, 'modal-title')}>
          {i18n.m('select_wallet_modal.title')}
        </Typography>

        <div className={classes.content}>

          {demoMode &&
            <Typography color="textSecondary">
              {i18n.m('select_wallet.description')}
            </Typography>
          }

          {(showGmail || showMail) &&
            <Space direction="horizontal" className={classes.buttonsRow}>

              {showGmail &&
                <Button
                  variant="outlined"
                  type="submit"
                  onClick={async () => {

                    Metrika.reach(Goal.wallet.EnterByGmail);
                    setEnterType('gmail');

                    await Action.dapp.inner.SetInnerProvider()(dispatch);
                    if (!torusCred) {
                      await Action.torus.StartLogin({ type: 'google' })(dispatch);
                    } else {
                      await Action.wallet.Reload()(dispatch);
                    }

                  }}
                  disabled={actionDisabled}
                  data-test="pay-by-inner-wallet"
                  className={clsx(classes.button, googleEntering ? mainClasses.enteringButton : undefined)}
                >
                  <GoogleIcon
                    className={clsx(commonClasses.icon48, googleEntering ? ClassName.SkipOpacity : undefined)} />
                  <ButtonContent entering={googleEntering}>
                    {i18n.m('select_wallet.by_google')}
                  </ButtonContent>
                </Button>
              }

              {showMail &&
                <Button
                  variant="outlined"
                  type="submit"
                  onClick={async () => {

                    Metrika.reach(Goal.wallet.EnterByEmail);

                    await Action.dapp.inner.SetInnerProvider()(dispatch);
                    if(!torusCred){
                      setEnterType('mail');
                    } else {
                      await Action.wallet.Reload()(dispatch);
                    }
                  }}
                  disabled={actionDisabled}
                  data-test="pay-by-inner-wallet"
                  className={clsx(classes.button, emailEntering ? mainClasses.enteringButton : undefined)}
                >
                  <EmailIcon
                    className={clsx(commonClasses.icon48, emailEntering? ClassName.SkipOpacity : undefined)} />
                  <ButtonContent entering={emailEntering}>
                    {i18n.m('select_wallet.by_email')}
                  </ButtonContent>
                </Button>
              }
            </Space>
          }

          {(showMetamask || showBinanceWallet) &&
            <Space direction="horizontal" className={classes.buttonsRow}>
              {showMetamask &&
                <Button
                  variant="outlined"
                  type="submit"
                  onClick={() => {

                    Metrika.reach(Goal.wallet.EnterByMetamask);
                    setEnterType('metamask');

                    Action.dapp.metamask.SetMetamaskProvider()(dispatch);
                  }}
                  disabled={actionDisabled || !hasMetamask}
                  data-test="pay-by-metamask-wallet"
                  className={clsx(classes.button, metamaskEntering ? mainClasses.enteringButton : undefined)}
                >
                  <img
                    className={clsx(commonClasses.icon48, metamaskEntering? ClassName.SkipOpacity : undefined)}
                    src={WalletIcon.metamask}
                    alt="" />
                  <ButtonContent entering={metamaskEntering}>
                    {WalletLabel(i18n, 'metamask')}
                  </ButtonContent>
                </Button>
              }
              {showBinanceWallet &&
                <Button
                  variant="outlined"
                  type="submit"
                  onClick={() => {

                    Metrika.reach(Goal.wallet.EnterByBinanceWallet);
                    setEnterType('binanceWallet');

                    Action.dapp.binanceWallet.SetBinanceProvider()(dispatch);
                  }}
                  disabled={actionDisabled || !hasBinanceWallet}
                  data-test="pay-by-binance-wallet"
                  className={clsx(classes.button, binanceWalletEntering ? mainClasses.enteringButton : undefined)}
                >
                  <img
                    className={clsx(commonClasses.icon48, binanceWalletEntering? ClassName.SkipOpacity : undefined)}
                    src={WalletIcon.binanceWallet}
                    alt="" />
                  <ButtonContent entering={binanceWalletEntering}>
                    {WalletLabel(i18n, 'binanceWallet')}
                  </ButtonContent>
                </Button>
              }
            </Space>
          }

          <div className={classes.cancelBlock}>
            {providerStarting && !skipLogoutButton &&
              <Link
                href="#"
                component="button"
                onClick={(event)=> {
                  event.preventDefault();
                  Action.wallet.Logout()(dispatch);
                }}
                data-test="cancel-wallet-login"
              >
                {i18n.m('select_wallet.cancel_enter')}
              </Link>
            }
          </div>
        </div>

        {showEmailEnterModal &&
          <EnterByEmailModal
            hideWrapper
            onCancel={() => {
              Action.emailEnter.Reset()(dispatch);
              setEnterType(undefined);
            }}
          />
        }

      </ModalDialog>


    </>
  )
}
