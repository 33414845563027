import { Util } from '@/utils/util';

// from index.html
const {ym, METRIKA_ID} = window as any;

const use = !!(ym && METRIKA_ID);
const id = METRIKA_ID;
const log = Util.getLog('Metrika');
const reachedGoals: Record<string, boolean> = {};

export const Goal = {
  err: {
    Network:              'err_network',
    Server:               'err_server',
    BadData:              'err_bad_data',
    Access:               'err_access',
    InvalidToken:         'err_invalid_token',
    Maintenance:          'err_maintenance',
    InvoiceExpired:       'err_invoice_expired',
    ServerUnavailable:    'err_server_unavailable',
    InvoiceUnknownError:  'err_invoice_unknown_error',
    UnknownQuotes:        'err_unknown_quotes',
    DnsProblem:           'err_dns',
    ApiOffline:           'err_api_offline',
    ApiCorsProblem:       'err_api_cors',
  },
  warn: {
    Offline: 'warn_offline'
  },
  invoice: {
    Success:                    'invoice_success',
    PayInProgress:              'invoice_payment_in_progress',
    PaymentFailed:              'invoice_payment_failed',
    PaymentRestoredAfterReload: 'invoice_payment_restored_after_reload',
    RestorePaymentError:        'invoice_restore_payment_error',
  },
  torus: {
    StartEnter: 'torus_start_enter',
    UseOldCred: 'torus_use_old_cred',
    LoginError: 'torus_login_error',
  },
  wallet: {
    GotDeposit:           'wallet_got_deposit',
    UnknownDepositSource: 'wallet_unknown_deposit_source',
    BigBalance:           'wallet_balance_big',
    SmallBalance:         'wallet_balance_small',
    TargetBalance:        'wallet_balance_target',
    ChangeTargetToken:    'wallet_change_target_token',
    TargetCoinSelected:   'wallet_target_coin_selected',
    AltCoinSelected:      'wallet_alt_coin_selected',
    CreateContract:       'wallet_create_contract',
    EnterByGmail:         'wallet_enter_gmail',
    EnterByEmail:         'wallet_enter_email',
    EnterByMetamask:      'wallet_enter_metamask',
    EnterByBinanceWallet: 'wallet_enter_binance_wallet',
    EnterByDemo:          'wallet_enter_demo',
    Logout:               'wallet_logout',
    LoadBalanceError:     'wallet_load_balance_error',
    MigrationProcess:     'wallet_migration_process',
    MigrationSuccess:     'wallet_migration_success',
    MigrationFailed:      'wallet_migration_failed',
    MigrationSkipped:     'wallet_migration_skipped',
    NeedDeposit:          'wallet_need_deposit',
    NeedDepositAccept:    'wallet_need_deposit_accept',
    NeedDepositCancel:    'wallet_need_deposit_cancel',
    StartResetDepositFix: 'wallet_start_reset_deposit_fix',
    AltCoinPayment:       'wallet_alt_coin_payment',
  },
  onRamp: {
    OpenByCard:       'on_ramp_open_by_card',
    OpenByExchange:   'on_ramp_open_by_exchange',
    OpenByTransfer:   'on_ramp_open_by_transfer',
    OpenSelectByCard: 'on_ramp_open_select_by_card',
  },
  instaramp: {
    Opened:         'instaramp_opened',
    OpenedByEmail:  'instaramp_opened_by_email',
    OpenedByGuest:  'instaramp_opened_by_guest',
    OrderCreated:   'instaramp_order_created',
    OrderWait:      'instaramp_order_wait',
    OrderFail:      'instaramp_order_fail',
    OrderUnknown:   'instaramp_order_unknown',
    SuccessDeposit: 'instaramp_success_deposit',
    SuccessPayment: 'instaramp_success_payment',
    NoCookieMessage:'instaramp_no_cookie_message',
    OpenInNewTab:   'instaramp_open_in_new_tab'
  },
  moonpay: {
    Opened:         'moonpay_opened',
    OpenedByEmail:  'moonpay_opened_by_email',
    OpenedByGuest:  'moonpay_opened_by_guest',
    SuccessDeposit: 'moonpay_success_deposit',
    SuccessPayment: 'moonpay_success_payment',
  },
  externalWallet: {
    AccessDenied:         'external_wallet_access_denied',
    WaitOther:            'external_wallet_wait_other',
    NeedBsc:              'external_wallet_need_bsc',
    UnsupportedToken:     'external_wallet_unsupported_token',
    CannotLoadBalance:    'external_wallet_cannot_load_balance',
    AllowLimitedPayReq:   'external_wallet_allow_limited_pay_req',
    AllowUnlimitedPayReq: 'external_wallet_allow_unlimited_pay_req',
    SignReqCanceled:      'external_wallet_sign_req_canceled',
    SignReqError:         'external_wallet_sign_req_error',
    OutOfGasError:        'external_wallet_out_of_gas_error',
  },
  metamask: {
    SwitchToBscReq:  'metamask_switch_to_bsc_req',
    AddOurTokenReq:  'metamask_add_our_token_req',
  },
  globalMenu: {
    Show:       'global_menu_show',
    Hide:       'global_menu_hide',
    ChangeLang: 'global_menu_change_lang',
    OpenAbout:  'global_menu_open_about',
    OpenHelp:   'global_menu_open_help',
  },
  offlineMode: {
    Show: 'offline_mode_show'
  }
}

export const Metrika = {

  userParams(params: any){

    log.info('userParams', params);

    if(!use || !params)
      return;

    try {
      ym(id, 'userParams', params);
    } catch (e: any){
      log.info('cannot userParams', e);
    }
  },

  reachOnce(goal: string){

    if(reachedGoals[goal])
      return;

    this.reach(goal);
  },

  alreadyReached(goal: string): boolean {
    return reachedGoals[goal];
  },

  removeReached(goal: string|string[]){

    log.info('removeReached', goal);

    const list: string[] = Util.isString(goal)?
      [goal as string]
      : goal as string[];

    list.forEach(goal => {
      delete reachedGoals[goal];
    });
  },

  reach(goal: string){

    log.info('reach', goal);

    if(!use || !goal)
      return;

    reachedGoals[goal] = true;

    try {
      ym(id, 'reachGoal', goal);
    } catch (e: any){
      log.info('cannot reachGoal', e);
    }
  }
}
