import { createReducer, createStateHandlers } from '@/utils/redux/reducer-util';
import { Action } from '@/model/actions';
import { initialState, WalletSignPayState as S } from '@/model/wallet/signPay/types';

const { p2f } = createStateHandlers<S>();

export const reducers = createReducer<S>(initialState, {

  // p2f<S['']>(''),

  [Action.walletSignPay.SetPayTxId.type]: p2f<S['payTxId']>('payTxId'),
  [Action.walletSignPay.SetSignTxId.type]: p2f<S['signTxId']>('signTxId'),
  [Action.walletSignPay.SetDataId.type]: p2f<S['dataId']>('dataId'),
  [Action.walletSignPay.SetErrorCode.type]: p2f<S['errorCode']>('errorCode'),
  [Action.walletSignPay.SetState.type]: p2f<S['payState']>('payState'),

  [Action.walletSignPay.Reset.type](state: S): S {
    return {
      ...initialState
    };
  },

  [Action.wallet.Logout.type](state: S): S {
    return {
      ...initialState,
    };
  },

});

export default reducers;
