import { put, select, takeEvery } from 'redux-saga/effects';
import { ActionWithPayload, withErrorHandler } from '@/utils/redux/action-creator';
import { Action } from '@/model/actions';
import { RootState } from '@/model/types';
import { Util } from '@/utils/util';
import { Config } from '@/config';
import { Currency, FiatCurrency, getFiatCurrency } from '@/model/common/Money';
import { LSCache } from '@/utils/storage';
import { DefLang, Lang } from '@/model/common/Lang';
import { Goal, Metrika } from '@/api/metrika';
import { OnRampProvider, OnRampReq, OnRampShowMode } from '@/model/onRamp/types';
import { isSafariLike } from '@/utils/browser-detect';
import { isMoonpaySupported } from '@/model/onRamp/moonpay/types';
import { isInstarampSupported } from '@/model/onRamp/instaramp/types';
import { InvoiceStat } from '@/api/invoce_stat';


const log = Util.getLog('onRamp/sagas');
const selectedFiatCacheKey = 'on-ramp-selected-fiat';


const defaultFiatByLang: Record<Lang, FiatCurrency> = {
  ru: 'RUB',
  en: 'USD',
  es: 'USD'
}


function* onWalletLogout(){
  yield put(Action.onRamp.Reset().pure);
}

function* onStart(
  { payload: {selectedProvider} }: ActionWithPayload<OnRampReq>
){

  const rootState = (yield select((state: RootState) => state)) as RootState;
  const {selectedToken} = rootState.walletProvider;
  const showMode = getOnRampShowMode(selectedToken?.currency, selectedProvider);

  if(showMode) {

    if(showMode.mode === 'popup'){
      log.warn('show on-ramp in popup mode', showMode);
    }

    yield put(Action.onRamp.SetProvider(showMode).pure);
    yield put(Action.onRamp.support.SetStatus('Shown').pure);
  }
  else {
    yield put(Action.wallet.SetDialog('none').pure);
    yield put(Action.onRamp.Reset().pure);
  }
}



function getOnRampShowMode(
  currency: Currency|undefined,
  selectedProvider: OnRampProvider|undefined
): OnRampShowMode | undefined {

  const {toggles} = Config;

  const canShowInstaramp = isInstarampSupported(currency);
  const canShowMoonpay = isMoonpaySupported(currency);

  if( ! canShowInstaramp
      && ! canShowMoonpay)
    return undefined;

  // special case for Safari
  if(isSafariLike() && toggles['instaramp-safari-special']){
    if(selectedProvider === 'Instaramp' && canShowInstaramp)
      return {provider: 'Instaramp', mode: 'popup'};
    else if(canShowMoonpay)
      return {provider: 'Moonpay', mode: 'widget'};
    else if(canShowInstaramp)
      return {provider: 'Instaramp', mode: 'popup'};
  }

  // selected by user
  if(selectedProvider === 'Instaramp' && canShowInstaramp)
    return {provider: 'Instaramp', mode: 'widget'};
  else if(selectedProvider === 'Moonpay' && canShowMoonpay)
    return {provider: 'Moonpay', mode: 'widget'};

  // priority order
  if(toggles['instaramp-priority'] && canShowInstaramp)
    return {provider: 'Instaramp', mode: 'widget'};
  else if(toggles['moonpay-priority'] && canShowMoonpay)
    return {provider: 'Moonpay', mode: 'widget'};

  // default order
  if(canShowInstaramp)
    return {provider: 'Instaramp', mode: 'widget'};
  else if(canShowMoonpay)
    return {provider: 'Moonpay', mode: 'widget'};

  // unknown on-ramp
  return undefined;
}

export function needRealOnRamp(rootState: RootState){
  const demoOnRamp = Util.parseBool(rootState.params['demo-on-ramp'], false);
  return Config.IsProd || !demoOnRamp;
}


function* initBaseCurrency(){

  const rootState = (yield select((state: RootState) => state)) as RootState;
  const lang = rootState.global.lang || DefLang;

  const defaultVal: FiatCurrency = defaultFiatByLang[lang] || 'RUB';
  const fromCache = LSCache.get(selectedFiatCacheKey);
  const initBaseCurrency = getFiatCurrency(fromCache, defaultVal);

  yield put(Action.onRamp.support.SetBaseCurrency(initBaseCurrency).pure);
  yield put(Action.onRamp.support.PrepareOnRamp().pure);
}

function* setBaseCurrency(
  {payload: baseCurrency}: ActionWithPayload<FiatCurrency>
){
  LSCache.set(selectedFiatCacheKey, baseCurrency);
  yield put(Action.onRamp.support.SetBaseCurrency(baseCurrency).pure);
  yield put(Action.onRamp.support.PrepareOnRamp().pure);
}

function sendDepositSourceMetrics(){

  if(Metrika.alreadyReached(Goal.instaramp.Opened)){
    Metrika.removeReached(Goal.instaramp.Opened);
  }
  else if(Metrika.alreadyReached(Goal.moonpay.Opened)){
    Metrika.removeReached(Goal.moonpay.Opened);
    Metrika.reach(Goal.moonpay.SuccessDeposit);
    InvoiceStat.sendEvent('moonpay-deposit-added');
  }
  else {
    Metrika.reach(Goal.wallet.UnknownDepositSource);
  }

}


export default function* rootSaga(): Generator {

  yield takeEvery(Action.onRamp.Start.type,
    withErrorHandler(
      onStart
    ));

  yield takeEvery(Action.wallet.Logout.type,
    withErrorHandler(
      onWalletLogout
    ));

  yield takeEvery(Action.onRamp.InitBaseCurrency.type,
    withErrorHandler(
      initBaseCurrency
    ));

  yield takeEvery(Action.onRamp.SetBaseCurrency.type,
    withErrorHandler(
      setBaseCurrency
    ));

  yield takeEvery(Action.wallet.GotDeposit.type,
    withErrorHandler(
      sendDepositSourceMetrics
    ));

}
