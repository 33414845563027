import { Network, unknownNetwork } from '@/model/common/Network';
import { WalletAssets } from '@/model/wallet/assets/types';
import { TokenDef, Tokens } from '@/model/common/Assets';
import { InnerBinanceWallet } from '@/api/dapp/inner_binance';
import { BaseDapp } from '@/api/dapp/common';
import { Config } from '@/config';

const isProd = Config.IsProd;

const supportedTokensIds = isProd?
  Tokens.getNetworkTokens('BinanceMainNet').map(token => token.tokenId)
  : Tokens.getNetworkTokens('BinanceTestNet').map(token => token.tokenId);


export interface InnerDapp extends BaseDapp {

  getWalletApi(
    network: Network,
    privateKey: string,
  ): Promise<InnerWalletApi>;

  clearCache(): void;
}


export interface InnerWalletApi {

  readonly contractAddress: string;
  readonly clientAddress: string;
  readonly isMigrationRequired: boolean;

  getState(targetAssets: TokenDef[]): Promise<WalletAssets>;
}


export const InnerWalletProvider: InnerDapp = {

  filterCurrentValidTokens(tokens: TokenDef[]): TokenDef[]{
    const validTokens = tokens.filter(token => supportedTokensIds.includes(token.tokenId));
    return Tokens.selectOnesFromFamilies(validTokens);
  },

  async getWalletApi(
    network: Network,
    privateKey: string,
  ): Promise<InnerWalletApi> {

    if(network.includes('Binance')){
      return InnerBinanceWallet.getApi(network, privateKey);
    }

    throw unknownNetwork(network);
  },

  clearCache(){
    InnerBinanceWallet.clearCache();
  }



}
