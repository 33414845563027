import { call, put, select, takeEvery } from 'redux-saga/effects';
import { ActionWithPayload, withErrorHandler } from '@/utils/redux/action-creator';
import { Action } from '@/model/actions';
import { DelegatedPayment, queryDelegatePayStatus } from '@/api/wallet';
import { RootState } from '@/model/types';
import { isSameAddress } from '@/model/common/Blockchain';
import { BaseDapp, DappConnectResp, DappListener, ExternalDapp } from '@/api/dapp/common';
import { isProviderStateOutdated, WalletProviderStartError, WalletProviderType } from '@/model/wallet/provider/types';
import { sameToken, TokenDef, Tokens } from '@/model/common/Assets';
import { mapTokensToView, WalletAssets } from '@/model/wallet/assets/types';
import { Util } from '@/utils/util';
import { reloadViewAfterDAppEvent } from '@/model/dapp/util';
import { rootStore } from '@/hocs/withStore/configureStore';
import { queryOpenTestOnRamp } from '@/api/demo_on_ramp';
import { Currency } from '@/model/common/Money';
import { LSCache } from '@/utils/storage';
import { needRealOnRamp } from '@/model/onRamp/sagas';
import { Goal, Metrika } from '@/api/metrika';
import { OnRampProvider } from '@/model/onRamp/types';
import { Config } from '@/config';
import { BlockchainProvider } from '@/api/blockchain';

const log = Util.getLog('dapp/common/sagas');
const selectedTokenCacheKey = 'selected-pay-token';

export function isSelectedProvider(state: RootState, type: WalletProviderType){
  return state.walletProvider.type === type;
}

const waitPaymentTimeout = 3000;

export const DappSagas = {

  * selectPayToken(dapp: BaseDapp){

    const rootState = (yield select((state: RootState) => state)) as RootState;
    const {selectedToken: oldSelected} = rootState.walletProvider;

    // old selected token is valid
    if(oldSelected && dapp.filterCurrentValidTokens([oldSelected]).length > 0){
      return;
    }

    // update valid tokens
    const allPayTokens = Config.IsProd? [
        Tokens.getCurrencyToken('bUSDT'),
        Tokens.getCurrencyToken('bBUSD'),
      ]
      : [
        Tokens.getCurrencyToken('btMNXe'),
        Tokens.getCurrencyToken('btBUSD'),
      ]
    const filteredTokens = dapp.filterCurrentValidTokens(allPayTokens);
    const curValidTokens = Tokens.sort(filteredTokens);
    yield put(Action.walletProvider.SetPayTokens(curValidTokens).pure);

    // try to restore
    if(!oldSelected){
      const storedCurrency: Currency|undefined = LSCache.get(selectedTokenCacheKey);
      const storedToken = storedCurrency? Tokens.getCurrencyToken(storedCurrency) : undefined;
      if(storedToken && !!curValidTokens.find(token => isSameAddress(token.tokenId, storedToken.tokenId))){
        yield setSelectedToken(storedToken);
        return;
      }
    }


    // select token
    const selectedToken = curValidTokens.length > 0? curValidTokens[0] : undefined;

    if(selectedToken){
      yield setSelectedToken(selectedToken);
    } else {

      log.warn('cannot select token to pay', {allPayTokens});
      yield setSelectedToken(undefined);

      const msg:WalletProviderStartError = 'unsupported_token';
      throw new Error(msg);
    }
  },

  * connectAndSetProvider(dapp: ExternalDapp, type: WalletProviderType) {

    const rootState = (yield select((state: RootState) => state)) as RootState;
    const {stateId} = rootState.walletProvider;

    yield put(Action.walletProvider.SetStarting(true).pure);
    yield put(Action.wallet.SetLastRequestedProvider(type).pure);

    const connect = (yield call(dapp.connect)) as DappConnectResp;
    if(isProviderStateOutdated(stateId))
      return;

    if( connect.waitOtherOperation){
      sendWalletStartErrorMetrics('wait_other_operation');
      yield put(Action.walletProvider.SetStartError('wait_other_operation').pure);
    }
    else if( ! connect.address) {
      sendWalletStartErrorMetrics('access_denied');
      yield put(Action.walletProvider.SetStartError('access_denied').pure);
    }
    else if( ! connect.network){
      const startError = dapp.getStartError();
      sendWalletStartErrorMetrics(startError);
      yield put(Action.walletProvider.SetStartError(startError).pure);
    }
    else {
      yield put(Action.walletProvider.SetType(type).pure);
      yield put(Action.walletProvider.SetDapp(dapp).pure);
      yield updateDappTokenIfNeed();
    }

    yield put(Action.walletProvider.SetStarting(false).pure);
  },

  * reloadWallet(dapp: ExternalDapp, type: WalletProviderType){

    const rootState = (yield select((state: RootState) => state)) as RootState;
    if( ! isSelectedProvider(rootState, type))
      return;

    const address = dapp.getAddress();
    const {selectedToken} = rootState.walletProvider;

    if( !address || !selectedToken)
      return;

    const {network} = selectedToken;
    const blockchain = BlockchainProvider.getApi(network);

    try {

      yield put(Action.wallet.StartReloadLoading().pure);

      const assets = (yield call(
        blockchain.getWalletAssets,
        network,
        address,
        [selectedToken])) as WalletAssets;

      mapTokensToView(assets);

      yield put(Action.wallet.SetAddress(address).pure)
      yield put(Action.wallet.SetAssets(assets).pure);

      yield put(Action.walletRestore.SetSuccessProvider(type).pure);
      yield put(Action.walletRestore.SaveData().pure);

    }
    catch (e: any){
      yield DappSagas.onCannotReload(e);
    }
    finally {
      yield put(Action.wallet.SetLoading(false).pure);
    }
  },

  * onCannotReload(e: any){

    Metrika.reach(Goal.wallet.LoadBalanceError);

    const rootState = (yield select((state: RootState) => state)) as RootState;
    const hasWallet = !! rootState.wallet.address;

    // cannot reload balance on current wallet: not a big deal
    if( hasWallet){
      throw e;
    }

    // cannot enter to wallet: need to start enter again
    log.error('cannot open wallet first time: unknown balance', e);
    yield put(Action.walletProvider.SetStartError('cannot_load_first_balance').pure);
    yield put(Action.global.AddWarning({messageId: 'global_error.wallet.cannot_enter'}).pure);
  },

  * openOnRamp(
    dapp: ExternalDapp,
    type: WalletProviderType,
    selectedProvider?: OnRampProvider){

    const rootState = (yield select((state: RootState) => state)) as RootState;
    if( ! isSelectedProvider(rootState, type))
      return;

    const {selectedToken} = rootState.walletProvider;
    const address = dapp.getAddress();
    const network = dapp.getCurrentNetwork();

    if( !selectedToken || !address || !network)
      return;

    const useRealOnRamp = needRealOnRamp(rootState);

    if( ! useRealOnRamp) {
      // fake on-ramp
      let transferred = false;
      try {

        yield put(Action.wallet.SetLoading(true).pure);

        transferred = yield call(queryOpenTestOnRamp, {
          clientAddress: address,
          amount: 0.1,
          token: selectedToken,
        });
      } finally {

        yield put(Action.wallet.SetLoading(false).pure);
      }

      if (transferred) {
        yield put(Action.wallet.Reload().pure);
        yield put(Action.onRamp.support.SetStatus('Success').pure);
      }
    }
    else {
      // real on ramp
      yield put(Action.onRamp.Start({
        selectedProvider,
        address,
        network,
        value: {
          currency: selectedToken.currency,
          amount: 0,
        }
      }).pure);
    }
  },

  dappListener(dapp: ExternalDapp, type: WalletProviderType): DappListener{
    return {

      onAddressChanged(newAddress) {

        const {
          type: provider,
          starting,
        } = rootStore.getState().walletProvider;

        if(starting)
          return;

        if (provider !== 'none' && provider !== type) {
          return;
        }

        if (provider === type) {
          if(!newAddress){
            rootStore.dispatch(Action.wallet.Logout().pure);
          } else {
            rootStore.dispatch(Action.wallet.Reload().pure);
          }
        }

        reloadViewAfterDAppEvent();
      },

      onNetworkChanged(newNetwork){

        const {
          type: provider,
          starting
        } = rootStore.getState().walletProvider;

        if(starting)
          return;

        if (provider !== 'none' && provider !== type) {
          return;
        }

        // hide error
        if (provider === 'none') {
          rootStore.dispatch(Action.wallet.Logout().pure);
        }
        else if(provider === type){
          if(newNetwork){
            rootStore.dispatch(Action.dapp.common.ReloadSelectedToken().pure);
          } else {
            rootStore.dispatch(Action.wallet.Logout().pure);
            rootStore.dispatch(Action.walletProvider.SetStartError(dapp.getStartError()).pure);
          }
        }

        reloadViewAfterDAppEvent();
      }
    }
  }
}


function* selectTokenToPay(
  {payload: tokenToSelect}: ActionWithPayload<TokenDef>
){

  const rootState = (yield select((state: RootState) => state)) as RootState;
  const {dapp, selectedToken, payTokens} = rootState.walletProvider;
  const isAltCoin = tokenToSelect.altCoin;

  if( ! dapp || ! selectedToken)
    return;

  const {network} = tokenToSelect;

  if(network !== 'BinanceTestNet'
      && sameToken(selectedToken, tokenToSelect))
    return;

  // invalid token for current network
  if( ! isAltCoin && dapp.filterCurrentValidTokens([tokenToSelect]).length === 0)
    return;

  // unknown token for pay tokens
  if( ! isAltCoin && ! payTokens.find(token => isSameAddress(token.tokenId, tokenToSelect.tokenId)))
    return;

  yield setSelectedToken(tokenToSelect);
  yield put(Action.wallet.Reload().pure);
}


function* updateDappTokenIfNeed(){

  const rootState = (yield select((state: RootState) => state)) as RootState;
  const {dapp} = rootState.walletProvider;

  if(!dapp)
    return;

  try {

    yield DappSagas.selectPayToken(dapp);
    yield put(Action.wallet.Reload().pure);

  } catch (e: any){

    yield put(Action.wallet.Logout().pure);

    // show error if external dapp
    if((e.message as WalletProviderStartError) === 'unsupported_token'){
      yield put(Action.walletProvider.SetStartError('unsupported_token').pure);
    }
    else if((dapp as ExternalDapp).getStartError){
      const externalDapp = dapp as ExternalDapp;
      yield put(Action.walletProvider.SetStartError(externalDapp.getStartError()).pure);
    }

  }
}


function* setSelectedToken(token: TokenDef|undefined){

  if( ! token)
    return;

  yield put(Action.walletProvider.SetSelectedToken({ token }).pure);

  // store
  LSCache.set(selectedTokenCacheKey, token.currency);
}

function sendWalletStartErrorMetrics(error: WalletProviderStartError){
  switch (error) {
    case 'access_denied':
      Metrika.reach(Goal.externalWallet.AccessDenied);
      break;
    case 'wait_other_operation':
      Metrika.reach(Goal.externalWallet.WaitOther);
      break;
    case 'need_binance_smart_chain':
      Metrika.reach(Goal.externalWallet.NeedBsc);
      break;
    case 'unsupported_token':
      Metrika.reach(Goal.externalWallet.UnsupportedToken);
      break;
    case 'cannot_load_first_balance':
      Metrika.reach(Goal.externalWallet.CannotLoadBalance);
      break;
    default:
        break;
  }
}



export async function processDelegatePayResult(
  invoiceId: string,
  paymentResult: DelegatedPayment,
  logData: any,
): Promise<string> {

  let payment = paymentResult;
  while( ! payment.hash){

    log.info('wait delegate pay deployment...', {status: payment.status, ...logData});

    if(payment.status === 'Failed' || payment.status === 'Expired'){
      log.error('failed delegate pay status', {status: payment.status, ...logData});
      throw new Error('failed delegate pay');
    }

    if(payment.status === 'Succeeded'){
      log.error('empty hash for deployed payment', {status: payment.status, ...logData});
      throw new Error('empty hash for deployed payment');
    }

    await Util.timeout(waitPaymentTimeout);

    // get actual payment status
    try {
      payment = await queryDelegatePayStatus(invoiceId);
    } catch (e: any){
      const msg = e.message || e.toString();
      if(msg !== 'Network Error' && msg !== 'Request aborted')
        throw e
    }
  }

  return payment.hash;
}


export function getAltCoinPayAmountWithSafeDelta(payTokenAmount: number){
  return Math.round(payTokenAmount * 1.05)
}



export default function* rootSaga(): Generator {

  yield takeEvery(Action.walletProvider.SelectTokenToPay.type,
    withErrorHandler(
      selectTokenToPay
    ));

  yield takeEvery(Action.dapp.common.ReloadSelectedToken.type,
    withErrorHandler(
      updateDappTokenIfNeed
    ));

}
