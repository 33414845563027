import React from 'react';
import { Space } from '@/components';
import { CountdownCircle } from '@/components/common/CountdownCircle';
import { Link, Typography } from '@material-ui/core';
import { useI18n } from '@/i18n';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';


const styles = makeStyles((theme: Theme) => {

  return {
    root: {
      marginTop: '12px',
      justifyContent: 'center',
      alignContent: 'center',
      height: '24px',
    },
    text: {
      fontSize: '14px',
      lineHeight: '25px',
    }
  };
});

export interface ResendBlockProps {
  waitState: boolean,
  onWaitDone?: ()=>void,
  doResend?: ()=>void,
}

export function ResendBlock(
  {
    waitState,
    onWaitDone,
    doResend
  }: ResendBlockProps
){

  const i18n = useI18n();
  const classes = styles();

  return (

    <Space direction="horizontal" className={classes.root}>

      {waitState &&
        <>
          <CountdownCircle
            maxSec={30}
            onDone={()=>{
              onWaitDone?.();
            }}
          />

          <Typography color="textPrimary" className={classes.text}>
            {i18n.m('enter_by_email.resend_code')}
          </Typography>
        </>
      }
      {!waitState &&
        <Link
          className={classes.text}
          href="#"
          component="button"
          onClick={(event)=> {
            event.preventDefault();
            doResend?.();
          }}
          data-test="resend-email-code"
        >
          {i18n.m('enter_by_email.resend_code')}
        </Link>
      }

    </Space>
  )
}
