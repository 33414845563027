import { delay, put, putResolve, takeEvery } from 'redux-saga/effects';
import { Action as ReduxAction } from 'redux';

import { history } from '@/hocs/withStore/configureStore';

import { ActionWithPayload, withErrorHandler } from '@/utils/redux/action-creator';
import { Action } from '@/model/actions';
import { Util } from '@/utils/util';
import { Notification } from '@/model/global/types';
import { TransmitToActionPayload } from '@/model/global/actions';

const notificationDelay = 10000;

let locks = 0;
export const withGlobalLock = <T extends ReduxAction>(
  worker: (action: T) => unknown,
): ((action: T) => Generator<unknown, unknown, T>) =>
  function* runner(action: T): Generator<unknown, void, T> {
    locks++;
    yield putResolve(Action.global.SetLoading({ loading: true }).pure);
    try {
      yield worker(action);
    } finally {
      if (--locks === 0) {
        yield putResolve(Action.global.SetLoading({ loading: false }).pure);
      }
    }
  };

function transmitTo(
  { payload: { state, replaceHistory } }: ActionWithPayload<TransmitToActionPayload>
): void {
  const location = `/${state.toLowerCase()}`;
  if (history.location.pathname !== location) {
    replaceHistory ? history.replace(location) : history.push(location);
  }
}

function* addError({ payload: error }: ActionWithPayload<Notification>): Generator {
  const id = Util.uuid();
  yield put(Action.global.support.AddNotification({ id, type: 'error', ...error }).pure);
  yield delay(notificationDelay);
  yield put(Action.global.support.RemoveNotification({ id, type: 'error' }).pure);
}

function* addWarning({ payload: warning }: ActionWithPayload<Notification>): Generator {
  const id = Util.uuid();
  yield put(Action.global.support.AddNotification({ id, type: 'warning', ...warning }).pure);
  yield delay(notificationDelay);
  yield put(Action.global.support.RemoveNotification({ id, type: 'warning' }).pure);
}

function* addSuccess({ payload: error }: ActionWithPayload<Notification>): Generator {
  const id = Util.uuid();
  yield put(Action.global.support.AddNotification({ id, type: 'success', ...error }).pure);
  yield delay(notificationDelay);
  yield put(Action.global.support.RemoveNotification({ id, type: 'success' }).pure);
}

export default function* rootSaga(): Generator {
  yield takeEvery(Action.global.Transmit.type,
    withGlobalLock(
      withErrorHandler(
        transmitTo)));

  yield takeEvery(Action.global.AddError.type,
    withErrorHandler(
      addError));

  yield takeEvery(Action.global.AddWarning.type,
    withErrorHandler(
      addWarning));

  yield takeEvery(Action.global.AddSuccess.type,
    withErrorHandler(
      addSuccess));
}
