import { Util } from '@/utils/util';


const log = Util.getLog('confetti');


export function showConfetti(){
  if( ! (window as any).confetti){
    const script = document.createElement('script');
    script.onload = ()=> {
      run();
    };
    script.src = 'https://cdn.jsdelivr.net/npm/canvas-confetti@1.4.0/dist/confetti.browser.min.js';
    document.head.appendChild(script);
  } else {
    run();
  }

  function run(){
    try {
      (window as any).confetti();
    } catch (e){
      log.error('cannot show confetti', e);
    }
  }
}
