import { TorusLoginResponse } from '@toruslabs/torus-direct-web-sdk/types/src/handlers/interfaces';

export type TorusLoginType =
  'google'
  | 'email';

export interface TorusExtra {
  loginType: TorusLoginType,
}

export type TorusCred = TorusLoginResponse & TorusExtra;


export interface TorusState {
  loading: boolean,
  entering: boolean,
  cred: TorusCred|null|undefined,
  stateId: number,
  lastRequestedLoginType?: TorusLoginType,
}

export const initialState: TorusState = {
  loading: false,
  entering: false,
  cred: undefined,
  stateId: 0,
}
