import { InnerWalletApi } from '@/api/dapp/inner';
import { Binance } from '@/api/blockchain/binance';
import { TokenDef } from '@/model/common/Assets';
import { Network } from '@/model/common/Network';
import { getRecaptchaToken } from '@/api/support/recaptcha3';
import { queryPredictWalletAddress } from '@/api/wallet';

const apis = new Map<string, InnerWalletApi>();

function getApiKey(network: Network, privateKey: string) {
  return `${network}-${privateKey}`;
}

function newBinanceWalletApi(
  network: Network,
  clientAddress: string,
  contractAddress: string,
  isMigrationRequired: boolean,
): InnerWalletApi {

  return {

    contractAddress,
    clientAddress,
    isMigrationRequired,

    async getState(targetAssets: TokenDef[]) {
      return Binance.getWalletAssets(network, contractAddress, targetAssets);
    }
  }
}


export const InnerBinanceWallet = {

  clearCache(){
    apis.clear();
  },

  async getApi(
    network: Network,
    privateKey: string,
  ): Promise<InnerWalletApi> {

    const apiKey = getApiKey(network, privateKey);
    const oldApi = apis.get(apiKey);
    if(oldApi)
      return oldApi;

    const clientAddress = Binance.privateKeyToAccount(network, privateKey);
    const recaptchaToken = await getRecaptchaToken('predictAddress');
    const {
      address: contractAddress,
      isMigrationRequired
    } = await queryPredictWalletAddress({clientAddress, network}, recaptchaToken);

    const newApi = newBinanceWalletApi(network, clientAddress, contractAddress, isMigrationRequired);

    apis.set(apiKey, newApi);

    return newApi;
  }
}


