import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { CircularProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import { RootState } from '@/model/types';
import { Action } from '@/model/actions';
import { SingleTimeout } from '@/utils/SingleTimeout';
import { useI18n } from '@/i18n';
import { Space } from '@/components';
import { Color } from '@/components/Style';

const showRestoringProcessMinTime = 1000;


export function useNeedRestoringScreen(): boolean {

  const dispatch = useDispatch();

  const providerStartError = useSelector((state: RootState) => state.walletProvider.startError);
  const restoredStatus = useSelector((state: RootState) => state.walletRestore.status);
  const restoredProvider = useSelector((state: RootState) => state.walletRestore.data.provider);
  const showRestoringProgress = useSelector((state: RootState) => state.walletRestore.showRestoringProgress);

  const [loadingTimer] = useState(()=> new SingleTimeout(showRestoringProcessMinTime));

  const waitToRestoreOldLogin = !providerStartError
    && (restoredStatus === 'notLoaded' || restoredProvider !== 'none');

  // clean up
  useEffect(() => {
    return () => {

      loadingTimer.clearTimeout();

      // fix for screen exit without timer done
      Action.walletRestore.SetShowRestoringProgress(false)(dispatch);
    };
  }, [loadingTimer, dispatch]);

  // show restoring process for a while
  useLayoutEffect(()=>{
    if(waitToRestoreOldLogin){

      Action.walletRestore.SetShowRestoringProgress(true)(dispatch);

      loadingTimer.setTimeout(()=>{
        Action.walletRestore.SetShowRestoringProgress(false)(dispatch);
      });
    }
  }, [waitToRestoreOldLogin, loadingTimer, dispatch]);


  return waitToRestoreOldLogin || showRestoringProgress;
}


const styles = makeStyles((theme: Theme) => {

  return {
    restoringLoadingRoot: {
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '-60px',
      color: Color.text.Main,
    }
  };
});

export function RestoringScreen(){

  const classes = styles();
  const i18n = useI18n();

  return (
    <Space direction="vertical" className={classes.restoringLoadingRoot}>
      <CircularProgress color="inherit" />
      <Typography color="textSecondary">
        {i18n.m('wallet.entering')}
      </Typography>
    </Space>
  )
}
