import { actionCreator } from '@/utils/redux/action-creator';
import { WalletAssets } from '@/model/wallet/assets/types';
import { WalletProviderType } from '@/model/wallet/provider/types';
import { TokenDef } from '@/model/common/Assets';
import { WalletDialog } from '@/model/wallet/types';
import { OnRampProvider } from '@/model/onRamp/types';


const creator = actionCreator('WALLET');

export interface SetSelectedTokenReq {
  token: TokenDef,
}


export const WalletAction = {

  SetLoading:     creator.syncActionWithPayload<boolean>('SET_LOADING'),
  StartReloadLoading: creator.syncAction('START_RELOAD_LOADING'),
  SetAddress:     creator.syncActionWithPayload<string>('SET_ADDRESS'),
  SetAssets:      creator.syncActionWithPayload<WalletAssets>('SET_ASSETS'),
  Reload:         creator.syncAction('RELOAD'),
  HiddenReload:   creator.syncAction('HIDDEN_RELOAD'),
  Logout:         creator.syncAction('LOGOUT'),
  OpenOnRamp:     creator.syncActionWithPayload<OnRampProvider|undefined>('OPEN_ON_RAMP'),
  SetDialog:      creator.syncActionWithPayload<WalletDialog>('SET_DIALOG'),
  SetLastRequestedProvider:
    creator.syncActionWithPayload<WalletProviderType|undefined>('SET_LAST_REQUESTED_PROVIDER'),
  SetDelayedReloadRequest: creator.syncActionWithPayload<boolean>('SET_DELAYED_RELOAD_REQUEST'),
  GotDeposit:     creator.syncAction('GOT_DEPOSIT'),
  SetNeedShowMigrationScreen: creator.syncActionWithPayload<boolean>('SET_NEED_SHOW_MIGRATION_SCREEN'),
  SetMigrationScreenShown: creator.syncActionWithPayload<boolean>('SET_MIGRATION_SCREEN_SHOWN'),
};

