import React, { ReactElement } from 'react';
import { Button, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { makeCommonStyles, makeFullMessageStyles, Space } from '@/components';
import { ReactComponent as ErrorLogo } from '@/assets/ui/error-logo.svg';
import { RootState } from '@/model/types';
import { Util } from '@/utils/util';
import { useI18n } from '@/i18n';

const styles = makeStyles((theme: Theme) => {

  return {
    errorLogo: {
      marginTop: '40px',
      width: '120px',
      height: '140px',
    },
  };
});


export interface ErrorBlockProps {
  title: string|ReactElement,
  subTitle?: string|ReactElement,
  customLogo?: ReactElement,
  skipExitButton?: boolean,
}

const commonStyles = makeCommonStyles();
const fullMessageStyles = makeFullMessageStyles();

export function ErrorView(
  {
    title,
    customLogo,
    subTitle,
    skipExitButton,
  }: ErrorBlockProps
){

  const i18n = useI18n();
  const classes = styles();
  const commonClasses = commonStyles();
  const fullMessageClasses = fullMessageStyles();

  const failUrlVal = useSelector((state: RootState) => state.params['fail-url']);

  const failUrl = Util.parseUrl(failUrlVal);
  const failUrlExists = !!failUrl;

  return (
    <Space direction="vertical">

      <div className={clsx(fullMessageClasses.logo)}>
        {customLogo || <ErrorLogo className={classes.errorLogo}/>}
      </div>

      <Typography className={fullMessageClasses.title}>
        {title}
      </Typography>
      <Typography className={fullMessageClasses.subTitle}>
        {subTitle || i18n.m('global_error.default_sub_title')}
      </Typography>

      {!skipExitButton &&
        <>
          <div className={commonClasses.spaceBlock} />

          <Button
            variant="outlined"
            color="default"
            disabled={!failUrlExists}
            onClick={()=>{
            if(failUrl)
            window.location.href = failUrl.toString();
          }}
            data-test="back-to-merchant-fail"
            >
            {i18n.m('common.back_to_merchant')}
          </Button>
        </>
      }

    </Space>
  )
}
