import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import App from '@/App';
import { LSCache } from '@/utils/storage';
import { ImgToCacheBuffer, preloadImagesFromCache, storeImagesToPreload } from '@/api/support/image_preload';
import { Tokens } from '@/model/common/Assets';
import { canUseSentryLogs, Util } from '@/utils/util';
import { detectBrowser, isFirefox, setBrowserNameAsBodyClass } from '@/utils/browser-detect';
import { Config } from '@/config';
import { Net } from '@/utils/axios';
import { Goal, Metrika } from '@/api/metrika';

const log = Util.getLog('index');

log.info('browser', detectBrowser());
setBrowserNameAsBodyClass();

window.addEventListener('unhandledrejection', (event)=> {

  const reason = event.reason || '';

  // Firefox service worker registration bug in a strict mode
  if(reason.toString() === 'SecurityError: The operation is insecure.'
      && isFirefox()){
    log.error('Cannot register service worker', reason);
    return;
  }

  // unknown exception
  log.error('uncaughtException', reason);
});

// for non-localhost
if(Config.Environment !== 'local') {

  canUseSentryLogs(true);

  Sentry.init({
    dsn: 'https://44b6cf9a49aa4f0cb6b2d81d49c4d67b@o379421.ingest.sentry.io/5650322?stacktrace.app.packages=application&maxmessagelength=3000',
    environment: Config.Environment,
    release: Config.Version,
  });
}

LSCache.setBucket('-ncps-');
LSCache.setExpiryMilliseconds(1);
LSCache.flushExpired();

storeImagesToPreload(Tokens.all().map(token => token.logo));
storeImagesToPreload(ImgToCacheBuffer);
preloadImagesFromCache();

const rootEl = document.getElementById('root');
ReactDOM.render(<App />, rootEl);


// send goals about requests errors
Net.interceptors.response.use(undefined, (e: any)=>{

  const {status} = e;

  if( ! status)
    Metrika.reach(Goal.err.Network);
  else if(status === 400)
    Metrika.reach(Goal.err.BadData)
  else if(status === 401 || status === 403)
    Metrika.reach(Goal.err.Access);
  else if(status >= 500 && status <= 599)
    Metrika.reach(Goal.err.Server);

  // rethrow e;
  throw e;
})


// comment in for PWA with service worker in production mode
// registerServiceWorker();
