import { actionCreator } from '@/utils/redux/action-creator';


const creator = actionCreator('DAPP_METAMASK');



export const MetamaskAction = {

  SetMetamaskProvider: creator.asyncAction('SET_METAMASK_PROVIDER'),
  DoPayment:      creator.asyncAction('DO_PAYMENT'),
  AllowPayReq:    creator.asyncActionWithPayload<boolean, void>('ALLOW_PAY_REQ'),
  PayReq:         creator.asyncAction('PAY_REQ'),
  AddBinanceNetwork:     creator.asyncAction('ADD_BINANCE_NETWORK'),
};

