
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line import/no-named-default
import {default as expectedRound} from 'expected-round';
import { StringUtil } from '@/utils/string-util';
import { Util } from '@/utils/util';


export const MathUtil = {

  /**
   * see: https://stackoverflow.com/questions/11832914
   * <code>
   * example: round(1.005, -2) = 1.01
   * </code>
   */
  round(val: number, exponent: number): number {
    return expectedRound.round10(val, exponent);
  },

  /**
   * <pre>
   * "100.000,12" -> 10000.12
   * "100,000.12" -> 10000.12
   * </pre>
   */
  parseAnyFloatFormat(str: string, defValue: number): number{

    if(str.length === 0)
      return defValue;

    if(str === '.'
        || str === ','
        || str === '0.'
        || str === '0,')
      return 0;

    const dotIndex = str.lastIndexOf('.');
    const commaIndex = str.lastIndexOf(',');

    // dot format
    if(dotIndex > commaIndex){

      const norm = StringUtil.replaceAll(str, ',', '').trim();
      return Util.parseFloat(norm) || defValue;
    }
    // comma format
    else {

      const norm = StringUtil.replaceAll(str, '.', '')
        .replaceAll(',', '.')
        .trim();
      return Util.parseFloat(norm) || defValue;
    }
  }
}
