

export const LangKeys = <const>['ru', 'en', 'es'];
export type Lang = typeof LangKeys[number];

export const DefLang: Lang = 'en';


export function toLang(locale?: string):Lang {

  if(locale?.startsWith('ru'))
    return 'ru';

  if(locale?.startsWith('en'))
    return 'en';

  if(locale?.startsWith('es'))
    return 'es';

  return DefLang;
}
