
import { GlobalAction } from '@/model/global/actions';
import { ParamsAction } from '@/model/params/actions';
import { TorusAction } from '@/model/torus/actions';
import { InnerWalletAction } from '@/model/dapp/inner/actions';
import { WalletAction } from '@/model/wallet/actions';
import { WalletInitAction } from '@/model/walletInit/actions';
import { BinanceWalletAction } from '@/model/dapp/binanceWallet/actions';
import { WalletSignPayAction } from '@/model/wallet/signPay/actions';
import { WalletRestoreAction } from '@/model/wallet/restore/actions';
import { MetamaskAction } from '@/model/dapp/metamask/actions';
import { DappAction } from '@/model/dapp/common/actions';
import { OnRampAction } from '@/model/onRamp/actions';
import { AuthAction } from '@/model/auth/actions';
import { SwapAction } from '@/model/swap/actions';
import { RewardsAction } from '@/model/rewards/actions';
import { WalletProviderAction } from '@/model/wallet/provider/actions';
import { EmailEnterAction } from '@/model/emailEnter/actions';

export const Action = {

  global:         GlobalAction,
  params:         ParamsAction,
  torus:          TorusAction,
  wallet:         WalletAction,
  init:           WalletInitAction,
  walletProvider: WalletProviderAction,
  walletSignPay:  WalletSignPayAction,
  walletRestore:  WalletRestoreAction,
  onRamp:         OnRampAction,
  auth:           AuthAction,
  swap:           SwapAction,
  rewards:        RewardsAction,
  emailEnter:     EmailEnterAction,
  dapp: {
    common:         DappAction,
    inner:          InnerWalletAction,
    binanceWallet:  BinanceWalletAction,
    metamask:       MetamaskAction,
  }
};
