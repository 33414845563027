import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/model/types';
import { Util } from '@/utils/util';

const inFrame = window.self !== window.top;

// acting like in frame by url param: frame-mode=true
let frameEmulation = false;

export const isFrameMode = ()=>{
  return inFrame || frameEmulation;
}

export type MsgToParentType = 'status';

export interface MsgToParent {
  type: MsgToParentType,
  value?: any,
}

export const postMessageToParent = (msg: MsgToParent)=>{
  if(inFrame && window.parent){
    window.parent.postMessage(msg, '*');
  }
}

const withIFrameDetect = (Component: React.FC) => (props: any) => {

  const paramsLoaded = useSelector((state: RootState) => state.params.loaded);
  const frameMode = useSelector((state: RootState) => state.params['frame-mode']);
  const likeInFrame = Util.parseBool(frameMode, false);

  useEffect(()=>{
    if(paramsLoaded && likeInFrame){
      frameEmulation = true;
    }
  }, [paramsLoaded, likeInFrame]);

  useEffect(()=>{
    if(inFrame || likeInFrame){
      document.body.classList.add('in-frame');
    } else {
      document.body.classList.remove('in-frame');
    }
  }, [likeInFrame]);

  return (
    <>
      <Component {...props} />
    </>
  );
}


export default withIFrameDetect;