
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line import/no-named-default
import {default as HmacSHA256} from 'crypto-js/hmac-sha256';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line import/no-named-default
import {default as Hex} from 'crypto-js/enc-hex';

import { LSCache } from '@/utils/storage';
import { Util } from '@/utils/util';
import { OneMin } from '@/utils/date-util';
import { Net } from '@/utils/axios';
import { getLangFromLocalStore } from '@/api/lang';

const log = Util.getLog('InvoiceStat');
const sessionIdTtl = 10 * OneMin;

let enabled = false;

let sessionId: string;
let invoice: string;
let signKey: string;

let wallet: string|undefined;
let publicKey: string|undefined;
let client: string|undefined;


type InvoiceEventType =
  'wallet-opened'
  | 'instaramp-order-created'
  | 'instaramp-deposit-added'
  | 'moonpay-deposit-added'
  | 'payment-success';


interface EventData {
  event: InvoiceEventType,
  data?: string,
}

interface InvoiceEvent extends EventData {
  sessionId: string,
  invoice: string,
  time: number,
  client?: string,
  wallet?: string,
  publicKey?: string,
  url: string,
  lang: string|undefined,
}


function getOrCreateSessionId(){

  const restoredSessionId = LSCache.get('stat-sessionId');
  if( restoredSessionId) {
    refreshSessionIdCacheTtl();
    return restoredSessionId;
  }

  const newSessionId = Util.uuid();
  LSCache.set('stat-sessionId', newSessionId, sessionIdTtl);

  return newSessionId;
}

function refreshSessionIdCacheTtl(){
  LSCache.refreshTll('stat-sessionId', sessionIdTtl);
}

function sendAsyncEvent(eventType: InvoiceEventType, data?: string){

  if( ! canSendEvent())
    return;

  refreshSessionIdCacheTtl();

  const sendData: InvoiceEvent = {
    event: eventType,
    data,
    invoice,
    sessionId,
    client,
    publicKey,
    wallet,
    time: Date.now(),
    url: window.location.href,
    lang: getLangFromLocalStore(),
  };

  const body = JSON.stringify(sendData);
  const sign = HmacSHA256(body, signKey).toString(Hex);

  log.info('send stat', sendData.event);
  Net.post('/invoice/event', body, {
    headers: {
      'Content-Type': 'application/json',
      'x-api-sig': sign
    }
  }).catch(Util.onErr);
}


function canSendEvent(): boolean {
  return enabled;
}


export interface InvoiceStatInitData {
  invoice: string,
  signKey: string,
}

export const InvoiceStat = {

  init(data: InvoiceStatInitData){

    invoice = data.invoice;
    signKey = data.signKey;
    sessionId = getOrCreateSessionId();

    log.info('init', {sessionId});
    enabled = true;
  },

  logout(){
    log.info('logout');
    wallet = undefined;
    publicKey = undefined;
    client = undefined;
  },

  setClient(clientVal: string | undefined) {

    if(client === clientVal)
      return;

    log.info('setClient', clientVal);
    client = clientVal;
  },

  setPublicKey(publicKeyVal: string){

    if(publicKey === publicKeyVal)
      return;

    log.info('setPublicKey', publicKeyVal);
    publicKey = publicKeyVal;
  },

  setWallet(walletVal: string){

    if(wallet === walletVal)
      return;

    log.info('setWallet', walletVal);
    wallet = walletVal;

    // wait for other fields to set
    setTimeout(()=> {
      sendAsyncEvent('wallet-opened');
    }, 1000);
  },

  sendEvent(event: InvoiceEventType, data?: string){
    sendAsyncEvent(event, data);
  }
}
