import React, { useLayoutEffect, useMemo } from 'react';
import { IntlProvider } from 'react-intl';
import { flatten } from 'flat';
import 'moment/locale/ru';

import { useDispatch } from 'react-redux';
import { SpaceChar } from '@/components';
import { DefLang, toLang } from '@/model/common/Lang';
import { Action } from '@/model/actions';
import { LocalStorage } from '@/utils/storage';
import { AllMessages } from '@/i18n';

function tryLocale(locale: string|null|undefined): [string, Record<string, string>]|undefined {

  if( ! locale)
    return undefined;

  const localeKey = (locale || '').replace('-', '');
  const foundLocale = (AllMessages as any)[localeKey];
  return foundLocale && [locale, flatten(foundLocale)];
}

function parseLocale({ locale }: { locale?: string }): [string, Record<string, string>]|undefined {
  return tryLocale(locale)
      || tryLocale(LocalStorage.getItem('LANG'))
      || tryLocale(DefLang);
}

const withI18n = (Component: React.FC) => (props: any) => {

  const dispatch = useDispatch();

  const [locale, messages] = useMemo(()=> {
    const [initLocale, initMessages] = parseLocale(props) || [];
    return [initLocale, {
      // prevent span warning in browser console: "defaultRichTextElements" was specified but "message" was not pre-compiled."
      // why it works: log will be printed from create-intl.js if " typeof messages[Object.keys(messages)[0]] === 'string' "
      // so we define first key value as int
      _: 0 as any,
      ...initMessages,
    }];
  }, [props]);

  useLayoutEffect(()=>{

    const lang = toLang(locale);

    Action.global.SetLang(lang)(dispatch);

    document.body.classList.add(`lang-${lang}`);

  }, [locale, dispatch]);

  return (
    <IntlProvider
      locale={locale || DefLang}
      messages={messages}
      defaultRichTextElements={{
        b: (chunks) => <b>{chunks}</b>,
        br: () => <br />,
        p: (chunks) => <p>{chunks}</p>,
        s: ()=> <>{SpaceChar}</>,
      }}
    >
      <Component {...props} />
    </IntlProvider>
  );
};

export default withI18n;
