import React from 'react';
import { DefLang } from '@/model/common/Lang';
import { LocalStorage } from '@/utils/storage';

export const I18N_LOCAL_STORE_KEY = 'LANG';

function parseFromQuery(): string | undefined | null {
  return new URLSearchParams(window.location.search).get('lang');
}

function parseFromLocalStore(): string | undefined | null {
  return LocalStorage.getItem(I18N_LOCAL_STORE_KEY);
}

function parseFromNavigator(): string | undefined | null {

  const {language: lang} = window.navigator;

  if(lang.includes('ru'))
    return 'ru';

  if(lang.includes('en'))
    return 'en';

  return null;
}

const saveToLocalStore = (locale: string): void => {
  LocalStorage.setItem(I18N_LOCAL_STORE_KEY, locale);
};

export const withLocalization = (Component: React.FC) => (props: any) => {

  const locale = parseFromQuery()
      || parseFromLocalStore()
      || parseFromNavigator()
      || DefLang;

  saveToLocalStore(locale);

  return (
    <Component locale={locale} {...props} />
  );
};

export default withLocalization;
