import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { makeCommonStyles, Space } from '@/components';
import { HeaderWithMenuAndBack } from '@/components/wallet/common/HeaderWithMenuAndBack';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import { useI18n } from '@/i18n';
import { ModalDialog } from '@/components/common/ModalDialog';
import { Button, Link, TextField, Typography } from '@material-ui/core';
import { important } from '@/components/Style';
import { Action } from '@/model/actions';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/model/types';
import { ApiError } from '@/model/common/ApiError';
import { ResendBlock } from '@/components/wallet/panel/SelectWalletPanel/EnterByEmailModal/ResendBlock';


const styles = makeStyles((theme: Theme) => {

  return {
    dialog: {
      minHeight: '508px',
    },
    root: {
      padding: '24px',
      paddingBottom: '4px',
      flexGrow: 1,
      flexShrink: 1,
    },
    center: {
      justifyContent: 'center',
    },
    help: {
      fontSize: '12px',
      textAlign: 'center',
      marginTop: '-12px',
    },
    text: {
      fontSize: '14px',
    },
    description: {
      marginTop: '1px',
      marginBottom: important('22px'),
    }
  };
});

const EmailCheckRegexp = new RegExp('^\\S+@\\S+$'); // simple check (let backend check better)
const commonStyles = makeCommonStyles();

export interface EnterByEmailModalProps {
  onCancel: ()=>void,
  hideWrapper?: boolean,
}

export function EnterByEmailModal(
  {
    onCancel,
    hideWrapper
  }: EnterByEmailModalProps
){

  const dispatch = useDispatch();
  const i18n = useI18n();
  const classes = styles();
  const commonClasses = commonStyles();

  const [email, setEmail] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const [waitResendCode, setWaitResendCode] = useState<boolean>(true);
  const [hideEmailError, setHideEmailError] = useState<boolean>(false);
  const [hideCodeError, setHideCodeError] = useState<boolean>(false);

  const resetState = ()=> {
    setEmail('');
    setCode('');
    setWaitResendCode(true);
    setHideEmailError(false);
    setHideCodeError(false);
  };

  const step = useSelector((state: RootState) => state.emailEnter.step);
  const loading = useSelector((state: RootState) => state.emailEnter.loading);
  const errorCode = useSelector((state: RootState) => state.emailEnter.errorCode);

  const isEmailValid = useMemo(()=>{
    return EmailCheckRegexp.test(email);
  }, [email]);

  const isEnterCodeStep = step === 'enter_code';
  const isErrorStep = step === 'error';

  const isEmailEmpty = email.length === 0;
  const isCodeEmpty = isEnterCodeStep && code.length === 0;

  const isBadEmailError = ! hideEmailError && errorCode === ApiError.login.BadEmail;
  const isBadCodeError = ! hideCodeError && errorCode === ApiError.login.BadCode;

  let emailHelperText: string|undefined;
  if(isBadEmailError){
    emailHelperText = i18n.m('enter_by_email.email.bad_email');
  }

  let codeHelperText: string|undefined;
  if(isBadCodeError){
    codeHelperText = i18n.m('enter_by_email.code.bad_code');
  }

  const disableNextAction = isEmailEmpty || !isEmailValid || isCodeEmpty || loading;

  // close on error step
  useLayoutEffect(()=>{
    if(isErrorStep){
      onCancel();
    }
  }, [isErrorStep, onCancel]);

  const resendBlock = useMemo(()=>{
    return <ResendBlock
      waitState={waitResendCode}
      onWaitDone={()=>{
        setWaitResendCode(false);
      }}
      doResend={()=>{
        Action.emailEnter.StartWithEmail(email)(dispatch);
        setWaitResendCode(true);
      }}
    />
  }, [email, waitResendCode, dispatch]);

  const nextAction = useCallback(()=>{

    if(disableNextAction)
      return;

    if(step === 'enter_email'){
      setHideEmailError(false);
      Action.emailEnter.StartWithEmail(email)(dispatch);
    }
    else if(step === 'enter_code'){
      setHideCodeError(false);
      Action.emailEnter.SendCode(code)(dispatch);
    }
  }, [disableNextAction, step, email, code, dispatch]);

  return (
    <ModalDialog
      onClose={onCancel}
      hideWrapper={hideWrapper}
      className={classes.dialog}
      disabled={loading}
    >

      <Space direction="vertical" className={classes.root} size={2}>

        <HeaderWithMenuAndBack
          title={i18n.m('enter_by_email.title')}
          hideMenu
          onClick={onCancel}
          disabled={loading}
          titleClassName="email-enter-title"
        />

        <Typography color="textSecondary" className={classes.description}>
          {i18n.m('enter_by_email.description1')}
        </Typography>

        <TextField
          name="email"
          type="email"
          placeholder={i18n.m('enter_by_email.email.placeholder')}
          value={email}
          disabled={loading}
          InputProps={{
            readOnly: isEnterCodeStep,
          }}
          onChange={(event)=>{
            const normalValue = (event.target.value || '').trim();
            setEmail(normalValue);
            setHideEmailError(true);
          }}
          onKeyDown={(e)=>{
            if(e.key === 'Enter'){
              nextAction();
            }
          }}
          error={isBadEmailError}
          helperText={emailHelperText}
        />

        {step === 'enter_code' &&
          <>
            <TextField
              name="code"
              placeholder={i18n.m('enter_by_email.code.placeholder')}
              value={code}
              disabled={loading}
              onChange={(event)=>{
                const normalValue = (event.target.value || '').trim();
                setCode(normalValue);
                setHideCodeError(true);
              }}
              onKeyDown={(e)=>{
                if(e.key === 'Enter'){
                  nextAction();
                }
              }}
              inputMode="numeric"
              error={isBadCodeError}
              helperText={codeHelperText}
            />

            {resendBlock}

            <Typography color="textSecondary" className={classes.help}>
              {i18n.m('enter_by_email.code_help')}
            </Typography>

            <Space direction="horizontal" className={classes.center}>
              <Link
                className={classes.text}
                href="#"
                component="button"
                onClick={(event)=> {
                  event.preventDefault();
                  Action.emailEnter.Reset()(dispatch);
                  resetState();
                }}
                data-test="change-email"
              >
                {i18n.m('enter_by_email.change_email')}
              </Link>
            </Space>
          </>
        }

        <div className={commonClasses.spaceBlock}/>

        <Button
          disabled={disableNextAction}
          variant="outlined"
          color="default"
          onClick={nextAction}
          data-test="send-code-to-email"
        >
          {i18n.m('common.continue')}
        </Button>

      </Space>
    </ModalDialog>
  )
}
