import React, { PropsWithChildren } from 'react';
import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { ReactComponent as MenuIcon } from '@/assets/icons/global-menu.svg';
import { important } from '@/components/Style';
import { Action } from '@/model/actions';
import { Space } from '@/components';
import { Goal, Metrika } from '@/api/metrika';

const styles = makeStyles((theme: Theme) => {

  return {
    root: {
      justifyContent: 'space-between',
      marginBottom: important('17px'),
      flexShrink: 0,
    },
    wrapper: {
      width: '100%',
      overflow: 'hidden',
    },
    menuIcon: {
      width: '36px',
      height: '36px',
      padding: '9px',
      marginRight: '2px',
      marginTop: '3px',
    }
  }
});


export type HeaderWithMenuProps = PropsWithChildren<{
  className?: string,
  hideMenu?: boolean,
}>;

export function HeaderWithMenu(
  {
    children,
    className,
    hideMenu = false,
  }: HeaderWithMenuProps
){

  const dispatch = useDispatch();
  const classes = styles();

  return(
    <Space direction="horizontal" className={clsx(classes.root, className)}>

      <div className={classes.wrapper}>
        {children}
      </div>

      {!hideMenu &&
        <IconButton
          className={clsx(classes.menuIcon)}
          data-test="open-global-menu"
          onClick={() => {
            Metrika.reach(Goal.globalMenu.Show);
            Action.global.ShowGlobalMenu(true)(dispatch);
          }}
        >
          <MenuIcon />
        </IconButton>
      }
    </Space>
  )
}
