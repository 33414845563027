import { Currency } from '@/model/common/Money';
import { Config, Environment } from '@/config';
import { Network } from '@/model/common/Network';


export type InstarampStatus =
  'CREATED'
  | 'INPROGRESS'
  | 'PAYMENT_ACCEPTED'
  | 'SUCCEED'
  | 'FAILED'

const partnerIds: Record<Environment, string> = {
  prod:     '11',
  staging:  '14',
  dev:      '14',
  local:    '26',
}

export const instarampPartnerId = partnerIds[Config.Environment];

// for dev and stage env
export const instarampDemoEthAddress = '0x6a1a52302DDccb4b80e3f43c58738CB271452F6F'

export const InstarampCryptoPair: Partial<Record<Currency, string>> = {
  // test
  btMNXe: 'ETH/EUR',
  btBUSD: 'ETH/EUR', // for test popup mode in safari
  // prod
  bBUSD:  'BUSD/EUR',
  bUSDT:  'USDT/EUR',
}

export const InstarampSupportedTokens: Record<Network, Currency[]> = {
  BinanceMainNet: ['bBUSD', 'bUSDT'],
  BinanceTestNet: ['btMNXe', 'btBUSD'],
}

export function isInstarampSupported(currency: Currency|undefined): boolean{
  return Config.toggles['instaramp-show'] && !! InstarampCryptoPair[currency || 'UNKNOWN'];
}
