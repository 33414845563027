import * as Bowser from 'bowser';
import { StringUtil } from '@/utils/string-util';

export interface BrowserDetails {
  name?: string;
  version?: string;
  os?: OSDetails;
}

export interface OSDetails {
  name?: OSName;
  versionName?: string;
  version?: string;
}

export type OSName = 'iOS' | 'macOS' | 'Windows' | 'Linux';


let browserDetailsCache: BrowserDetails|undefined;

export function detectBrowser(): BrowserDetails {

  if(browserDetailsCache)
    return browserDetailsCache;

  const browser = Bowser.getParser(window.navigator.userAgent);
  const details = browser.getBrowser();
  const {name: rawName} = details;

  // normalize name if present
  browserDetailsCache = {
    ...details,
    name: rawName? StringUtil.replaceAll(rawName.toLowerCase().trim(), ' ', '') : undefined,
    os: browser.getOS() as any,
  };
  return browserDetailsCache;
}

export function setBrowserNameAsBodyClass(){

  const {name} = detectBrowser();
  if(name){
    document.body.classList.add(name);
  }
}

export function isMacOS(): boolean {
  return detectBrowser().os?.name === 'macOS';
}

export function isIOS(): boolean {
  return detectBrowser().os?.name === 'iOS';
}

export function isSafari(): boolean{
  return !! detectBrowser().name?.includes('safari');
}

/** Direct safari like or IOS */
export function isSafariLike(): boolean {
  return isSafari() || isIOS();
}

export function isFirefox(): boolean{
  return !! detectBrowser().name?.includes('firefox');
}

