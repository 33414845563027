import { takeEvery } from 'redux-saga/effects';
import { ActionWithPayload, withErrorHandler } from '@/utils/redux/action-creator';
import { Action } from '@/model/actions';
import { Config } from '@/config';
import { setRecaptchaBotToken } from '@/api/support/recaptcha3';
import { AppParams } from '@/model/params/types';


function handleParams(
  {payload: params}: ActionWithPayload<AppParams>
){

  const isProd = Config.IsProd;
  if(!isProd){
    setRecaptchaBotToken(params['bot-token']);
  }
}


export default function* rootSaga(): Generator {

  yield takeEvery(Action.params.SetAll.type,
    withErrorHandler(
      handleParams
    ));

}
