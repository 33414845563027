import { call, put, select, takeEvery } from 'redux-saga/effects';
import { Util } from '@/utils/util';
import { Action } from '@/model/actions';
import { ActionWithPayload, withErrorHandler, withSingleWorker } from '@/utils/redux/action-creator';
import { queryGetRewards, queryUseReward } from '@/api/rewards';
import { isRewardInProgress, Reward } from '@/model/rewards/types';
import { withRetry } from '@/utils/promise';
import { showConfetti } from '@/api/confetti';
import { RootState } from '@/model/types';
import { isSelectedProvider } from '@/model/dapp/common/sagas';
import { InnerWalletApi, InnerWalletProvider } from '@/api/dapp/inner';
import { OneMin, OneSec } from '@/utils/date-util';
import { Config } from '@/config';
import { Network } from '@/model/common/Network';

const log = Util.getLog('rewards/sagas');
const waitRewardTimeout = 3 * OneMin;
const nextWaitStep = 5 * OneSec;
const network: Network = Config.IsProd? 'BinanceMainNet' : 'BinanceTestNet';


export function* firstLoadRewards(){
  try {
    const name = 'initRewards'
    const maxCalls = 3;
    const list = (yield call(withRetry(queryGetRewards, {name, maxCalls}), network)) as Reward[];
    yield put(Action.rewards.support.SetList(list).pure);
  } catch (e){
    log.error('cannot get rewards', e);
    yield put(Action.rewards.support.SetList([]).pure);
  }
}


function* loadRewards(){
  try {

    const list = (yield call(queryGetRewards, network)) as Reward[];
    yield put(Action.rewards.support.SetList(list).pure);

  } catch (e){
    log.error('cannot get rewards', e);
  }
}

function* useReward({payload: reward}: ActionWithPayload<Reward>){

  const rootState = (yield select((state: RootState) => state)) as RootState;
  if( ! isSelectedProvider(rootState, 'inner'))
    return;

  const {cred} = rootState.torus;
  const {selectedToken} = rootState.walletProvider;
  const oldProcessing = rootState.rewards.processingRewardId;

  if( ! cred || ! selectedToken || oldProcessing)
    return;

  const {network} = selectedToken;


  const api = (yield call(InnerWalletProvider.getWalletApi, network, cred.privateKey)) as InnerWalletApi;
  const {clientAddress} = api;
  const {id} = reward;

  yield put(Action.rewards.support.SetProcessingReward(id).pure);
  try {

    yield call(queryUseReward, {id, clientAddress});
    yield waitRewardResult(id);

  } catch (e){

    log.error('cannot use reward', e);
    yield put(Action.global.AddError({messageId: 'rewards.fail'}).pure);

  } finally {
    yield put(Action.rewards.support.SetProcessingReward(undefined).pure);
  }
}

function* waitReward(
  {payload: id}: ActionWithPayload<string>
){

  const rootState = (yield select((state: RootState) => state)) as RootState;
  const oldProcessing = rootState.rewards.processingRewardId;

  // single wait logic
  if( oldProcessing)
    return;

  yield put(Action.rewards.support.SetProcessingReward(id).pure);
  try {
    yield waitRewardResult(id);
  } finally {
    yield put(Action.rewards.support.SetProcessingReward(undefined).pure);
  }
}

function* waitRewardResult(id: string){

  const stateId = (yield select((state: RootState) => state.rewards.processingRewardId)) as string;

  log.info('wait reward result', id);
  const timeoutTime = Date.now() + waitRewardTimeout;

  let lastState: Reward[]|undefined;

  while(Date.now() < timeoutTime){

    // waiting is outdated
    const curStateId = (yield select((state: RootState) => state.rewards.processingRewardId)) as string;
    if(curStateId !== stateId)
      break;

    try {
      lastState = (yield call(queryGetRewards, network)) as Reward[];
      const targetReward = lastState.find(r => r.id === id);

      // reward is unknown or got result
      if( ! targetReward || ! isRewardInProgress(targetReward)){
        break;
      }

    } catch (e){
      log.error('cannot get rewards update', e);
    }

    // wait to next check
    yield call(Util.timeout, nextWaitStep);
  }

  // waiting is outdated
  const curStateId = (yield select((state: RootState) => state.rewards.processingRewardId)) as string;
  if(curStateId !== stateId){
    log.warn('cancel wait reward result', id);
    return;
  }

  if( ! lastState){
    return;
  }

  // update model
  yield put(Action.rewards.support.SetList(lastState).pure);

  // check result
  const targetReward = lastState.find(r => r.id === id);

  // invalid result
  if( ! targetReward || targetReward.status !== 'Claimed'){
    log.error('invalid reward status', targetReward);
    yield put(Action.global.AddError({messageId: 'rewards.fail'}).pure);
  }
  // success result
  else {
    yield put(Action.wallet.Reload().pure);
    yield put(Action.global.AddSuccess({messageId: 'rewards.success'}).pure);
    showConfetti();
  }
}


function* onLogout(){
  yield put(Action.rewards.Reset().pure);
}



export default function* rootSaga(): Generator {

  yield takeEvery(Action.rewards.Load.type,
    withErrorHandler(
      withSingleWorker(
        loadRewards
    )));

  yield takeEvery(Action.rewards.UseReward.type,
    withErrorHandler(
      withSingleWorker(
        useReward
      )));

  yield takeEvery(Action.rewards.WaitReward.type,
    withErrorHandler(
      withSingleWorker(
        waitReward
      )));

  yield takeEvery(Action.wallet.Logout.type,
    withErrorHandler(
      onLogout
    ));

}
