import { Util } from '@/utils/util';
import { Binance } from '@/api/blockchain/binance';
import { IPancakePairABI, PancakeRouterABI, UniswapV2FactoryABI } from '@/api/abi';
import {
  checkSameNetwork,
  getAbsoluteTokenAmount,
  getAmountFromAbsolute,
  TokenBase, TokenDef,
  Tokens, TokenState,
} from '@/model/common/Assets';
import { BscMainnetTokens } from '@/model/common/BscTokens';
import { Network } from '@/model/common/Network';
import { SwapPath } from '@/model/swap/types';
import { isSameAddress, ZeroAddress } from '@/model/common/Blockchain';
import { toHexString } from '@/utils/crypto-util';


const log = Util.getLog('binance-swap');

const usdt = Tokens.getCurrencyToken('bUSDT');
const busd = Tokens.getCurrencyToken('bBUSD');
const wbnb = BscMainnetTokens.find(token => token.abbr === 'WBNB') as TokenBase;
const cake = BscMainnetTokens.find(token => token.abbr === 'CAKE') as TokenBase;
const btcb = BscMainnetTokens.find(token => token.abbr === 'BTCB') as TokenBase;
const ust = BscMainnetTokens.find(token => token.abbr === 'UST') as TokenBase;
const eth = BscMainnetTokens.find(token => token.abbr === 'ETH') as TokenBase;
const usdc = BscMainnetTokens.find(token => token.abbr === 'USDC') as TokenBase;

const baseTokens = [
  usdt,
  busd,
  wbnb,
  cake,
  btcb,
  ust,
  eth,
  usdc
];


const SwapRouterAddress: Record<Network, string> = {
  BinanceMainNet: '0x10ed43c718714eb63d5aa57b78b54704e256024e',
  BinanceTestNet: '0xb9D73284bE9380874163c7c986F8c1E91D84113c'
}

const SwapFactoryAddress: Record<Network, string> = {
  BinanceMainNet: '0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73',
  BinanceTestNet: '0x2F5B44ae09eeA403889c27Ad4FFBd5b40f9bB99e'
}


export interface PairReserves {
  tokenA: TokenState,
  tokenB: TokenState,
}

export async function getBinanceSwapReserves(tokenA: TokenDef, tokenB: TokenDef): Promise<PairReserves>{

  const network = checkSameNetwork(tokenA, tokenB);

  const web3 = Binance.web3(network);
  const factoryApi = new web3.eth.Contract(UniswapV2FactoryABI, SwapFactoryAddress[network]);
  const contractAddress = await factoryApi.methods.getPair(tokenA.tokenId, tokenB.tokenId).call();

  if(contractAddress === ZeroAddress){
    return {
      tokenA: {
        ...tokenA,
        balance: 0
      },
      tokenB: {
        ...tokenB,
        balance: 0
      }
    }
  }

  const pairApi = new web3.eth.Contract(IPancakePairABI, contractAddress);
  const token0Addr = await pairApi.methods.token0().call();
  const isTokenAFirst = isSameAddress(token0Addr.toString(), tokenA.tokenId);

  const {reserve0: r0, reserve1: r1} = await pairApi.methods.getReserves().call();

  return {
    tokenA: {
      ...tokenA,
      balance: isTokenAFirst? r0 : r1
    },
    tokenB: {
      ...tokenB,
      balance: isTokenAFirst? r1 : r0
    }
  }
}

export async function getBinanceSwapPath(
  fromToken: TokenBase,
  toToken: TokenBase,
  amountsExactOut: number): Promise<SwapPath>{

  const network = checkSameNetwork(fromToken, toToken);

  if(isSameAddress(fromToken.tokenId, toToken.tokenId))
    return {
      path: [fromToken],
      price: amountsExactOut,
    };

  const allPaths = [[fromToken, toToken]];

  // alternative paths
  if(network === 'BinanceMainNet'){
    baseTokens.forEach(token => {
      if(isSameAddress(token.tokenId, fromToken.tokenId)
        || isSameAddress(token.tokenId, toToken.tokenId))
        return;
      allPaths.push([fromToken, token, toToken])
    });
  }


  const tasks = allPaths.map(path => {
    return getBinanceSwapAmountIn(path, amountsExactOut)
      .catch(e => {

        const msg = e.message || '';
        if( ! msg.includes('execution reverted')){
          log.error('cannot get swap price', e);
        }

        // stub result
        return Number.MAX_VALUE;

      }).then(price => {
        const result: SwapPath = {
          price,
          path,
        }
        return result;
      });
  })

  // wait all results
  const results = await Promise.all(tasks);

  const best = results.reduce((prev, cur) => {
    const prevPrice = prev.price;
    const curPrice = cur.price;
    return prevPrice <= curPrice? prev : cur;
  }, {
    path: [],
    price: Number.MAX_VALUE
  });

  return best;
}

export async function getBinanceSwapAmountIn(path: TokenBase[], resultAmount: number): Promise<number>{

  if(path.length === 0)
    throw new Error('empty swap path');

  const tokenFrom = path[0];
  const tokenTo = path[path.length-1];

  if(isSameAddress(tokenFrom.tokenId, tokenTo.tokenId))
    return resultAmount;

  const network = checkSameNetwork(tokenFrom, tokenTo);

  const pathIds = path.map(token => token.tokenId);
  const resultStr = toHexString(getAbsoluteTokenAmount(resultAmount, tokenTo.decimals));

  const web3 = Binance.web3(network);
  const routerApi = new web3.eth.Contract(PancakeRouterABI, SwapRouterAddress[network])

  const [inAbsoluteAmount] = await routerApi.methods.getAmountsIn(resultStr, pathIds).call();
  return getAmountFromAbsolute(inAbsoluteAmount, tokenFrom.decimals);
}




