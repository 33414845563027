import { createReducer, createStateHandlers } from '@/utils/redux/reducer-util';
import { Action } from '@/model/actions';
import { initialState, WalletProviderState as S, WalletProviderType } from '@/model/wallet/provider/types';
import { ActionWithPayload } from '@/utils/redux/action-creator';
import { SetSelectedTokenReq } from '@/model/wallet/actions';

const { p2f, tf } = createStateHandlers<S>();

export const reducers = createReducer<S>(initialState, {

  // p2f<S['']>(''),

  [Action.walletProvider.SetPayTokens.type]: p2f<S['payTokens']>('payTokens'),
  [Action.walletProvider.UpdateStateId.type]: tf<S['stateId']>('stateId', Date.now()),
  [Action.walletProvider.SetDapp.type]: p2f<S['dapp']>('dapp'),
  [Action.walletProvider.SetStartError.type]: p2f<S['startError']>('startError'),
  [Action.walletProvider.SetStarting.type]: p2f<S['starting']>('starting'),

  [Action.walletProvider.SetSelectedToken.type](
    state: S, { payload }: ActionWithPayload<SetSelectedTokenReq>
  ): S {
    return {
      ...state,
      selectedToken: payload? payload.token : undefined,
    };
  },

  [Action.walletProvider.SetType.type](
    state: S, { payload }: ActionWithPayload<WalletProviderType>
  ): S {
    return {
      ...state,
      type: payload,
      startError: undefined,
    };
  },

  [Action.wallet.Logout.type](state: S): S {
    return {
      ...initialState,
      stateId: Date.now(),
    };
  },

});

export default reducers;
