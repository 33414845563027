import { Util } from '@/utils/util';


const log = Util.getLog('recaptcha3');

let botToken: string|undefined;

export function setRecaptchaBotToken(token: string|undefined){
  if(token){
    log.warn('using bot token', token);
  }
  botToken = token;
}

export async function getRecaptchaToken(action: string): Promise<string>{

  if(botToken)
    return botToken;

  const {grecaptcha, RecaptchaKey} = (window as any);

  return new Promise((resolve, reject) => {
    grecaptcha.ready(async ()=> {
      try {
        const token = await grecaptcha.execute(RecaptchaKey, {action});
        resolve(token);
      } catch (e: any){
        reject(e);
      }
    });
  });


}
