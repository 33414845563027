import { Util } from '@/utils/util';

const log = Util.getLog('ChildWindowListener');
const checkWinInterval = 800;

let proxyInited = false;
let windows: any[] = [];
const listeners: ChildWindowListener[] = [];

function initWindowProxy(){

  const winOpenFn = window.open;

  window.open = function onOpen(...args) {

    const win = winOpenFn.apply(this, args);
    if( ! win)
      return win;

    const timer = setInterval(()=> {
      if(win.closed) {

        clearInterval(timer);
        windows = windows.filter(some => some !== win);

        listeners.forEach(listener => {
          try {
            if(listener.onClosed)
              listener.onClosed(win);
          } catch (e: any){
            log.error('cannot call onClosed', e);
          }
        })

      }
    }, checkWinInterval);


    windows.push(win);

    listeners.forEach(listener => {
      try {
        if(listener.onOpened)
          listener.onOpened(win);
      } catch (e: any){
        log.error('cannot call onOpened', e);
      }
    })

    return win;
  };
}

export interface ChildWindowListener {
  onOpened?: (win: Window) => void;
  onClosed?: (win: Window) => void;
}

export const ChildWindows = {

  addListener(listener: ChildWindowListener){
    if(!proxyInited){
      initWindowProxy();
      proxyInited = true;
    }
    listeners.push(listener);
  }
}
