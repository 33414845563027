import React, { PropsWithChildren, useLayoutEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { createPortal } from 'react-dom';
import { makeCommonStyles } from '@/components';
import { important } from '@/components/Style';
import { usePortal } from '@/components/portal';
import { ReactComponent as CloseIcon } from '@/assets/icons/close.svg';
import { IconButton } from '@material-ui/core';


const commonStyles = makeCommonStyles();
const styles = makeStyles((theme: Theme) => {

  return {
    wrapper: {
      position: 'fixed',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: 'rgba(0,0,0,0.7)',
    },
    hideWrapper: {
      backgroundColor: 'rgba(0,0,0,0)',
    },
    dialog: {
      position: 'relative',
      maxWidth: '392px',
      width: '100%',
      backgroundColor: 'white',
      borderRadius: '12px',
      flexGrow: 2,
      margin: '0 auto',
      '&:not(.no-padding)': {
        paddingBottom: '20px',
      }
    },
    hideDialog: {
      display: 'none',
    },
    closeButton: {
      padding: 0,
      position: 'absolute',
      top: -40,
      right: -40,
      backgroundColor: important('rgba(0,0,0,0)'),
    },
    dialogContentWrapper: {
      display: 'flex',
      flexDirection: 'column',
      padding: '0px',
      width: '100%',
      height: '100%',
      overflow: 'auto',
      maxHeight: '91vh',
      '& > *:not(.modal-title)': {
        width: '100%',
        //maxWidth: '392px',
        margin: '0 auto',
      }
    },
    title: {
      flexGrow: 2,
      textAlign: 'center',
    },
    descriptionBlock: {
      paddingTop: '16px',
      paddingBottom: '22px',
    },
    expiredWarning: {
      marginTop: important('20px')
    },
  }
});


export type ModalDialogProps = PropsWithChildren<{
  className?: string,
  modalWrapperClassName?: string,
  mobileFullScreen?: boolean,
  showClose?: boolean,
  onClose?: ()=>void,
  noPadding?: boolean,
  hideDialog?: boolean,
  hideWrapper?: boolean,
  disabled?: boolean,
}>;


export function ModalDialog(
  {
    children,
    className,
    modalWrapperClassName,
    mobileFullScreen = true,
    showClose = true,
    onClose,
    noPadding = false,
    hideWrapper = false,
    hideDialog = false,
    disabled = false,
  }: ModalDialogProps
){

  const classes = styles();
  const commonClasses = commonStyles();
  const target = usePortal('modal-root');

  useLayoutEffect(()=>{

    document.body.classList.add('in-modal');

    return ()=>{
      document.body.classList.remove('in-modal');
    }
  }, [])

  return createPortal((
    <div className={clsx(
      classes.wrapper,
      'modal-wrapper',
      modalWrapperClassName,
      hideWrapper? classes.hideWrapper : undefined)}>

      <div className={clsx(
        commonClasses.spaceBlock,
        'modal-wrapper-space-1',
        mobileFullScreen? 'modal-wrapper-space' : undefined)}/>

      <div className={clsx(
        classes.dialog,
        'modal-dialog',
        className,
        mobileFullScreen? 'modal-dialog-full' : undefined,
        noPadding? 'no-padding' : undefined,
        hideDialog? classes.hideDialog : undefined,
      )}>

        {showClose &&
          <IconButton
            className={clsx(classes.closeButton, 'modal-dialog-close')}
            data-test="close-global-dialog"
            onClick={() => {
              if( ! disabled) {
                onClose?.();
              }
            }}
          >
            <CloseIcon />
          </IconButton>
        }

        <div className={clsx(classes.dialogContentWrapper, 'modal-content-wrapper')}>
          {children}
        </div>
      </div>

      <div className={clsx(
        commonClasses.spaceBlock,
        'modal-wrapper-space-2',
        mobileFullScreen? 'modal-wrapper-space' : undefined)}/>

    </div>
  ), target);
}
