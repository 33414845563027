import axios from 'axios';
import { Config } from '@/config';

export const Net = axios.create({
  baseURL: Config.ServerUrl,
  timeout: Config.FetchTimeout,
  withCredentials: true,
});

Net.interceptors.response.use(
  response => response.data,
  (e: any) => {

    const out: any = new Error();

    out.url = e.config?.url;
    out.method = e.request?.method;
    out.message = e.message;

    if(e.response){

      out.status = e.response.status;
      out.statusText = e.response.statusText;

      const data = e.response.data || {};
      Object.keys(data).forEach((key)=>{
        out[key] = data[key];
      });
    }

    throw out;
  },
);
