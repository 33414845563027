

export type Network =
  'BinanceMainNet'
  | 'BinanceTestNet';

const NetworkMap: Record<Network, boolean> = {
  BinanceMainNet: true,
  BinanceTestNet: true,
}

export function parseNetwork(val: string): Network|undefined {
  const candidate = val as Network;
  return NetworkMap[candidate]? candidate : undefined;
}

export function networkValue<T>(network: Network, map: Partial<Record<Network, T>>): T {
  const value = map[network];
  if(!value)
    throw unknownNetwork(network);
  return value;
}

export function unknownNetwork(val?: string){
  return new Error(val? `unknown network ${val}` : 'unknown network');
}