import { Button, Chip, Link, Typography } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { makeCommonStyles, makeMainStyles, Space, SpaceChar } from '@/components';
import { Action } from '@/model/actions';
import { RootState } from '@/model/types';
import { ReactComponent as ExitIcon } from '@/assets/icons/exit.svg';
import { ReactComponent as ExternalIcon } from '@/assets/icons/external-link.svg';
import { ReactComponent as GoogleIcon } from '@/assets/icons/google-icon.svg';
import { ReactComponent as EmailIcon } from '@/assets/icons/email-icon.svg';
import { important } from '@/components/Style';
import { EmptyRender } from '@/utils/react-util';
import { useI18n } from '@/i18n';
import { WalletIcon, WalletLabel, getNetworkTypeLabel, openAddress } from '@/components/wallet';
import { HeaderWithMenuAndBack } from '@/components/wallet/common/HeaderWithMenuAndBack';
import { AddressBlock } from '@/components/wallet/common/AddressBlock';

const commonStyles = makeCommonStyles();
const mainStyles = makeMainStyles();

const styles = makeStyles((theme: Theme) => {

  return {
    addressBlock: {
      marginTop: important('0px'),
      marginBottom: important('18px'),
    },
    providerLabel: {
      marginTop: '7px',
    },
    exitLabel: {
      marginLeft: '12px'
    },
  };
});



export function WalletDetailsPanel(){

  const i18n = useI18n();
  const dispatch = useDispatch();
  const classes = styles();
  const commonClasses = commonStyles();
  const mainClasses = mainStyles();

  const loading = useSelector((state: RootState) => state.wallet.loading);
  const providerType = useSelector((state: RootState) => state.walletProvider.type);
  const selectedToken = useSelector((state: RootState) => state.walletProvider.selectedToken);
  const address = useSelector((state: RootState) => state.wallet.address || '');
  const cred = useSelector((state: RootState) => state.torus.cred);

  if( ! address || !selectedToken)
    return <EmptyRender msg="WalletDetails" details={{address, selectedToken}}/>;

  const {network} = selectedToken;
  const networkLabel = getNetworkTypeLabel(network);

  const useGoogleIcon = providerType === 'inner' && cred?.loginType === 'google';
  const useMailIcon = providerType === 'inner' && cred?.loginType === 'email';
  const useWalletIcon = ! useGoogleIcon && ! useMailIcon;

  return (
    <Space direction="vertical">

      <HeaderWithMenuAndBack
        title={i18n.m('wallet_details.title')}
      />

      <Typography className={clsx(mainClasses.smallLabel, classes.providerLabel)}>
        {i18n.m('wallet_details.label.wallet_type')}
      </Typography>

      <Space
        direction="vertical"
        className={clsx(mainClasses.addressBlock, classes.addressBlock)}
        size={2}
      >

        <Space direction="horizontal" size={1} className={clsx(commonClasses.alignItemsCenter)}>

          {useGoogleIcon && <GoogleIcon className={commonClasses.icon20} />}
          {useMailIcon && <EmailIcon className={commonClasses.icon20} />}
          {useWalletIcon && <img className={commonClasses.icon24} src={WalletIcon[providerType]} alt="" />}

          <Typography color="textPrimary">
            {useGoogleIcon && 'Google'}
            {useMailIcon && 'Email'}
            {useWalletIcon && WalletLabel(i18n, providerType)}
          </Typography>

          <div className={commonClasses.spaceBlock}/>

          <Chip
            label={networkLabel}
            color="primary"
            variant="outlined"
            size="small"
            data-test="test-net-flag"/>

        </Space>

        <AddressBlock address={address}/>

      </Space>

      <div>
        <Link
          component="button"
          variant="body2"
          data-test="open-address"
          onClick={()=>{
            openAddress(address, network);
          }}>
          <ExternalIcon className={commonClasses.icon14}/>
          {SpaceChar}
          {SpaceChar}
          {SpaceChar}
          {i18n.m('common.open_explorer')}
        </Link>
      </div>

      <div className={commonClasses.spaceBlock}/>

      <Button
        variant="outlined"
        type="submit"
        onClick={() => {
          Action.wallet.Logout()(dispatch);
        }}
        disabled={loading}
        data-test="wallet-logout"
      >
        <ExitIcon className={commonClasses.icon18}/>
        <span className={clsx(mainClasses.iconButtonLabel, classes.exitLabel)}>
          {i18n.m('wallet_details.logout')}
        </span>
      </Button>

    </Space>
  );
}
