import { toAltCoin, TokenBase, TokenDef, Tokens } from '@/model/common/Assets';
import { Network } from '@/model/common/Network';


// top from https://coinmarketcap.com/ru/view/binance-smart-chain/
const mainnetTokens: TokenBase[] = [
  {
    network: 'BinanceMainNet',
    tokenId: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
    abbr: 'ETH',
    fullName: 'Ethereum',
    logo: 'https://tokens.pancakeswap.finance/images/0x2170Ed0880ac9A755fd29B2688956BD959F933F8.png',
    decimals: 18,
    globalOrder: 0,
  },
  {
    network: 'BinanceMainNet',
    tokenId: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    abbr: 'WBNB',
    logo: 'https://tokens.pancakeswap.finance/images/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c.png',
    decimals: 18,
    globalOrder: 1,
  },
  {
    network: 'BinanceMainNet',
    tokenId: '0x55d398326f99059fF775485246999027B3197955',
    abbr: 'USDT',
    fullName: 'Tether',
    logo: 'https://tokens.pancakeswap.finance/images/0x55d398326f99059fF775485246999027B3197955.png',
    decimals: 18,
    globalOrder: 0,
  },
  {
    network: 'BinanceMainNet',
    tokenId: '0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47',
    abbr: 'ADA',
    fullName: 'Cardano',
    logo: 'https://tokens.pancakeswap.finance/images/0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47.png',
    decimals: 18,
    globalOrder: 1,
  },
  {
    network: 'BinanceMainNet',
    tokenId: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
    abbr: 'USDC',
    logo: 'https://tokens.pancakeswap.finance/images/0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d.png',
    decimals: 18,
    globalOrder: 0,
  },
  {
    network: 'BinanceMainNet',
    tokenId: '0xbA2aE424d960c26247Dd6c32edC70B295c744C43',
    abbr: 'DOGE',
    fullName: 'Dogecoin',
    logo: 'https://tokens.pancakeswap.finance/images/0xbA2aE424d960c26247Dd6c32edC70B295c744C43.png',
    decimals: 8
  },
  {
    network: 'BinanceMainNet',
    tokenId: '0x7083609fCE4d1d8Dc0C979AAb8c869Ea2C873402',
    abbr: 'DOT',
    fullName: 'Polkadot',
    logo: 'https://tokens.pancakeswap.finance/images/0x7083609fCE4d1d8Dc0C979AAb8c869Ea2C873402.png',
    decimals: 18
  },
  {
    network: 'BinanceMainNet',
    tokenId: '0xBf5140A22578168FD562DCcF235E5D43A02ce9B1',
    abbr: 'UNI',
    fullName: 'Uniswap',
    logo: 'https://tokens.pancakeswap.finance/images/0xBf5140A22578168FD562DCcF235E5D43A02ce9B1.png',
    decimals: 18
  },
  {
    network: 'BinanceMainNet',
    tokenId: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    abbr: 'BUSD',
    fullName: 'Binance USD',
    logo: 'https://tokens.pancakeswap.finance/images/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56.png',
    decimals: 18,
    globalOrder: 0,
  },
  {
    network: 'BinanceMainNet',
    tokenId: '0xF8A0BF9cF54Bb92F17374d9e9A321E6a111a51bD',
    abbr: 'LINK',
    fullName: 'Chainlink',
    logo: 'https://tokens.pancakeswap.finance/images/0xF8A0BF9cF54Bb92F17374d9e9A321E6a111a51bD.png',
    decimals: 18
  },
  {
    network: 'BinanceMainNet',
    tokenId: '0x4338665CBB7B2485A8855A139b75D5e34AB0DB94',
    abbr: 'LTC',
    fullName: 'Litecoin',
    logo: 'https://tokens.pancakeswap.finance/images/0x4338665CBB7B2485A8855A139b75D5e34AB0DB94.png',
    decimals: 18
  },
  {
    network: 'BinanceMainNet',
    tokenId: '0x8fF795a6F4D97E7887C79beA79aba5cc76444aDf',
    abbr: 'BCH',
    fullName: 'Bitcoin Cash',
    logo: 'https://tokens.pancakeswap.finance/images/0x8fF795a6F4D97E7887C79beA79aba5cc76444aDf.png',
    decimals: 18
  },
  {
    network: 'BinanceMainNet',
    tokenId: '0x0Eb3a705fc54725037CC9e008bDede697f62F335',
    abbr: 'ATOM',
    fullName: 'Cosmos',
    logo: 'https://tokens.pancakeswap.finance/images/0x0Eb3a705fc54725037CC9e008bDede697f62F335.png',
    decimals: 18
  },
  {
    network: 'BinanceMainNet',
    tokenId: '0x0D8Ce2A99Bb6e3B7Db580eD848240e4a0F9aE153',
    abbr: 'FIL',
    fullName: 'Filecoin',
    logo: 'https://tokens.pancakeswap.finance/images/0x0D8Ce2A99Bb6e3B7Db580eD848240e4a0F9aE153.png',
    decimals: 18
  },
  {
    network: 'BinanceMainNet',
    tokenId: '0x85EAC5Ac2F758618dFa09bDbe0cf174e7d574D5B',
    abbr: 'TRX',
    fullName: 'TRON',
    logo: 'https://tokens.pancakeswap.finance/images/0x85EAC5Ac2F758618dFa09bDbe0cf174e7d574D5B.png',
    decimals: 18
  },
  {
    network: 'BinanceMainNet',
    tokenId: '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3',
    abbr: 'DAI',
    logo: 'https://tokens.pancakeswap.finance/images/0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3.png',
    decimals: 18
  },
  {
    network: 'BinanceMainNet',
    tokenId: '0x16939ef78684453bfDFb47825F8a5F714f12623a',
    abbr: 'XTZ',
    fullName: 'Tezos',
    logo: 'https://tokens.pancakeswap.finance/images/0x16939ef78684453bfDFb47825F8a5F714f12623a.png',
    decimals: 18
  },
  {
    network: 'BinanceMainNet',
    tokenId: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
    abbr: 'BTCB',
    logo: 'https://tokens.pancakeswap.finance/images/0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c.png',
    decimals: 18
  },
  {
    network: 'BinanceMainNet',
    tokenId: '0xbF7c81FFF98BbE61B40Ed186e4AfD6DDd01337fe',
    abbr: 'EGLD',
    fullName: 'Elrond',
    logo: 'https://tokens.pancakeswap.finance/images/0xbF7c81FFF98BbE61B40Ed186e4AfD6DDd01337fe.png',
    decimals: 18
  },
  {
    network: 'BinanceMainNet',
    tokenId: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
    abbr: 'CAKE',
    fullName: 'PancakeSwap',
    logo: 'https://tokens.pancakeswap.finance/images/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82.png',
    decimals: 18
  },
  {
    network: 'BinanceMainNet',
    tokenId: '0x56b6fB708fC5732DEC1Afc8D8556423A2EDcCbD6',
    abbr: 'EOS',
    logo: 'https://tokens.pancakeswap.finance/images/0x56b6fB708fC5732DEC1Afc8D8556423A2EDcCbD6.png',
    decimals: 18
  },
  {
    network: 'BinanceMainNet',
    tokenId: '0x23396cF899Ca06c4472205fC903bDB4de249D6fC',
    abbr: 'UST',
    fullName: 'TerraUSD',
    logo: 'https://tokens.pancakeswap.finance/images/0x23396cF899Ca06c4472205fC903bDB4de249D6fC.png',
    decimals: 18
  },
  {
    network: 'BinanceMainNet',
    tokenId: '0x8595F9dA7b868b1822194fAEd312235E43007b49',
    abbr: 'BTT',
    logo: 'https://tokens.pancakeswap.finance/images/0x8595F9dA7b868b1822194fAEd312235E43007b49.png',
    decimals: 18
  },
  {
    network: 'BinanceMainNet',
    tokenId: '0x03fF0ff224f904be3118461335064bB48Df47938',
    abbr: 'ONE',
    fullName: 'Harmony',
    logo: 'https://tokens.pancakeswap.finance/images/0x03fF0ff224f904be3118461335064bB48Df47938.png',
    decimals: 18
  },
  {
    network: 'BinanceMainNet',
    tokenId: '0x52CE071Bd9b1C4B00A0b92D298c512478CaD67e8',
    abbr: 'COMP',
    fullName: 'Compound',
    logo: 'https://tokens.pancakeswap.finance/images/0x52CE071Bd9b1C4B00A0b92D298c512478CaD67e8.png',
    decimals: 18
  },
  {
    network: 'BinanceMainNet',
    tokenId: '0x1Ba42e5193dfA8B03D15dd1B86a3113bbBEF8Eeb',
    abbr: 'ZEC',
    fullName: 'Zcash',
    logo: 'https://tokens.pancakeswap.finance/images/0x1Ba42e5193dfA8B03D15dd1B86a3113bbBEF8Eeb.png',
    decimals: 18
  },
  {
    network: 'BinanceMainNet',
    tokenId: '0x14016E85a25aeb13065688cAFB43044C2ef86784',
    abbr: 'TUSD',
    fullName: 'TrueUSD',
    logo: 'https://tokens.pancakeswap.finance/images/0x14016E85a25aeb13065688cAFB43044C2ef86784.png',
    decimals: 18
  },
  {
    network: 'BinanceMainNet',
    tokenId: '0x947950BcC74888a40Ffa2593C5798F11Fc9124C4',
    abbr: 'SUSHI',
    fullName: 'SushiSwap',
    logo: 'https://tokens.pancakeswap.finance/images/0x947950BcC74888a40Ffa2593C5798F11Fc9124C4.png',
    decimals: 18
  },
  {
    network: 'BinanceMainNet',
    tokenId: '0xb86AbCb37C3A4B64f74f59301AFF131a1BEcC787',
    abbr: 'ZIL',
    logo: 'https://tokens.pancakeswap.finance/images/0xb86AbCb37C3A4B64f74f59301AFF131a1BEcC787.png',
    decimals: 12
  },
  {
    network: 'BinanceMainNet',
    tokenId: '0x88f1A5ae2A3BF98AEAF342D26B30a79438c9142e',
    abbr: 'YFI',
    fullName: 'yearn.finance',
    logo: 'https://tokens.pancakeswap.finance/images/0x88f1A5ae2A3BF98AEAF342D26B30a79438c9142e.png',
    decimals: 18
  },
];

export const BscMainnetTokens: TokenDef[] = mainnetTokens.map(base => toAltCoin(base));

// In testnet for all tokens using MNXe or BUSD token addresses
const btMnxe = Tokens.getCurrencyToken('btMNXe');
const btBusd = Tokens.getCurrencyToken('btBUSD');

export const BscTestnetFakeTokens: TokenDef[] = BscMainnetTokens.map(token => {

  // using 50/50 chance for normal mnxe-busd test swap
  const isMnxE = Math.random() < 0.5;

  return {
    ...token,
    network: 'BinanceTestNet',
    tokenId: isMnxE? btMnxe.tokenId : btBusd.tokenId,
    decimals: isMnxE? btMnxe.decimals : btBusd.decimals,
    currency: 'UNKNOWN',
  }
})


export function getBscAltTokensToPay(network: Network, skipTokens: TokenBase[]): TokenDef[] {
  if(network === 'BinanceMainNet'){
    // filter by token ids
    const filterIds = skipTokens.map(token => token.tokenId);
    return BscMainnetTokens
      .filter(token => ! filterIds.includes(token.tokenId));
  }
  else if(network === 'BinanceTestNet'){
    // filter by token abbr! (because we use Fake Alt tokens in test net)
    const filterAbbr = skipTokens.map(token => token.abbr);
    return BscTestnetFakeTokens
      .filter(token => ! filterAbbr.includes(token.abbr));
  }
  else {
    return [];
  }
}
