import { actionCreator } from '@/utils/redux/action-creator';
import { InitErrorType } from '@/model/walletInit/types';


const creator = actionCreator('WALLET_INIT');

export const WalletInitAction = {
  LoadData:      creator.asyncAction('LOAD_DATA'),
  SetLoaded:     creator.syncAction('SET_LOADED'),
  SetLoadTries:  creator.syncActionWithPayload<number>('SET_LOAD_TRIES'),
  SetErrorType:  creator.syncActionWithPayload<InitErrorType>('SET_ERROR_TYPE'),
};
