import { TokenDef } from '@/model/common/Assets';
import { networkValue } from '@/model/common/Network';


export interface OpenOnRampReq {
  clientAddress: string,
  amount: number,
  token: TokenDef,
}

let result = false;

window.addEventListener('message', (event)=>{
  if(event.data === 'on-ramp-success'){
    result = true;
  }
});

export async function queryOpenTestOnRamp(
  {
    clientAddress,
    amount,
    token,
  }: OpenOnRampReq
){

  return new Promise((resolve, reject) => {

    result = false;

    const type = networkValue(token.network, {
      BinanceTestNet: 'binance',
    });

    const win = window.open(
      // eslint-disable-next-line max-len
      `./on-ramp-demo.html?net=${type}&testnet=${true}&amount=${amount}&currency=${token.abbr}&decimals=${token.decimals}&token=${token.tokenId}&client=${clientAddress}`,
      'OnRamp Demo Page',
      'directories=0,titlebar=0,toolbar=0,location=0,status=0,menubar=0,width=600,height=400') as any;

    if( ! win) {
      reject(new Error('cannot open popup'));
      return;
    }

    win.onbeforeunload = ()=>{
      resolve(result);
    };
  });
}
