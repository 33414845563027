import queryString from 'query-string';
import { Util } from '@/utils/util';
import { AppParams } from '@/model/params/types';
import { ModalQueryFlag } from '@/model/common/Modal';
import { LSCache } from '@/utils/storage';

const cacheKey = 'appParams';

export function queryGetAppParams(): AppParams {

  const {search} = window.location;
  let params;

  if( ! Util.isBlank(search) && search !== ModalQueryFlag){

    // use url params
    params = queryString.parse(search) || {};

    // and store them
    const toStore: AppParams = ({
      ...params
    }) as any;

    querySaveAppParams(toStore);

  } else {
    // use stored params
    params = LSCache.get(cacheKey) || {};
  }

  params.loaded = true;

  return params;
}

export function querySaveAppParams(toStore: AppParams){

  // filter storing data
  delete toStore.loaded;

  LSCache.set(cacheKey, toStore);
}
