import { IconButton, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { makeCommonStyles, makeMainStyles, Space } from '@/components';
import { Action } from '@/model/actions';
import { RootState } from '@/model/types';
import { WalletIcon, copyAddressToClipboard } from '@/components/wallet';
import { useI18n } from '@/i18n';
import { ReactComponent as DotsIcon } from '@/assets/icons/vertical-dots.svg';
import { important } from '@/components/Style';

const styles = makeStyles((theme: Theme) => {

  return {
    root: {
      alignItems: 'center',
      marginTop: '10px',
    },
    titleBase: {
      lineHeight: '20px',
    },
    walletLabel: {
      marginRight: important('4px'),
    },
    button: {
      padding: '0',
      width: '36px',
      height: '36px',
      marginRight: '2px',
    }
  };
});

const commonStyles = makeCommonStyles();
const mainStyles = makeMainStyles();


export function WalletAddressPanel(){

  const dispatch = useDispatch();
  const i18n = useI18n();
  const classes = styles();
  const commonClasses = commonStyles();
  const mainClasses = mainStyles();

  const providerType = useSelector((state: RootState) => state.walletProvider.type);
  const address = useSelector((state: RootState) => state.wallet.address);

  const walletLabel = useMemo(()=>{
    switch (providerType){
      case 'inner': return i18n.m('wallet.label');
      case 'metamask': return i18n.m('wallet.metamask');
      case 'binanceWallet': return i18n.m('wallet.binance_wallet');
      case 'demoWallet': return i18n.m('wallet.demo_wallet');
      default: return '';
    }
  }, [providerType, i18n]);

  const addressLabel = address? `${address.substring(0, 5)}...${address.substring(address.length-5)}` : '';

  return (
    <Space direction="horizontal" className={classes.root}>

      <img src={WalletIcon[providerType]} alt="" className={clsx(commonClasses.icon24)}/>

      <Typography color="textSecondary" className={clsx(classes.titleBase, classes.walletLabel)}>
        {`${walletLabel}:`}
      </Typography>

      <Typography
        color="textPrimary"
        data-test="copy-address-direct"
        title={i18n.m('wallet.address_do_copy')}
        className={clsx(mainClasses.linkText, classes.titleBase)}
        onClick={()=>{
          return copyAddressToClipboard(address || '');
        }}
      >
        {addressLabel}
      </Typography>

      <div className={commonClasses.spaceBlock}/>

      <IconButton
        className={clsx(classes.button)}
        data-test="wallet-details-button"
        onClick={()=>{
          Action.wallet.SetDialog('walletDetails')(dispatch);
        }}
      >
        <DotsIcon />
      </IconButton>
    </Space>
  )
}
