import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Action } from '@/model/actions';
import { AppParams } from '@/model/params/types';
import { queryGetAppParams } from '@/api/app_params';


const withAppParams = (Component: React.FC) => (props: any) => {

  const dispatch = useDispatch();

  useEffect(()=>{
    const params: AppParams = queryGetAppParams();
    Action.params.SetAll(params)(dispatch);
  }, [dispatch]);

  return (
    <>
      <Component {...props} />
    </>
  );
}

export default withAppParams;
