import { Util } from '@/utils/util';

const log = Util.getLog('utils/promise');

// from: https://medium.com/@95yashsharma/polyfill-for-promise-allsettled-965f9f2a003
// support for old browsers
export function initPolyfillForPromiseAllSettled(){

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if(Promise.allSettled)
    return;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  Promise.allSettled = function impl(promises: Promise<any>[]){
    const mappedPromises = promises.map((p: Promise<any>) => {
      return p
        .then((value) => {
          return {
            status: 'fulfilled',
            value,
          };
        })
        .catch((reason) => {
          return {
            status: 'rejected',
            reason,
          };
        });
    });
    return Promise.all(mappedPromises);
  }
}

export function withErrLog<T>(promise: Promise<T>, ...errors: any[]): Promise<T> {
  return new Promise<T>((resolve, reject) => {
    promise
      .then(result => resolve(result))
      .catch(e => {
        log.error(...errors, e);
        reject(e);
      });
  });
}


interface SingleCall {
  listenersCount: number,
  promise: Promise<any>
}

const singleCalls = new Map<string, SingleCall>();

/**
 * All next callers will be wait first result
 */
export function withSingleCall<T>(callId: string, callCandidate: ()=> Promise<T>): Promise<T> {


  let call = singleCalls.get(callId);
  if(!call){

    log.info('SingleCall', `"${callId}"`, 'start single call');

    const callInst = {
      // external promise wrapper
      promise: new Promise<T>((resolve, reject) => {
        callCandidate()
          .then(result => resolve(result))
          .catch(e => reject(e))
          .finally(()=> {
            log.info('SingleCall', `"${callId}"`, 'end single call', { listeners: callInst.listenersCount });
            singleCalls.delete(callId);
          });
      }),
      listenersCount: 1,
    };

    call = callInst;
    singleCalls.set(callId, call);

  } else {

    call.listenersCount++;
    log.info('SingleCall', `"${callId}"`, 'add listener to call', {listeners: call.listenersCount});
  }

  return call.promise;
}

export interface WithTimeoutProps {
  callId: string,
  timeout: number,
}


export function withTimeout<T>({callId, timeout}: WithTimeoutProps, call: ()=>Promise<T>): Promise<T> {
  return Promise.race<T>([
    call(),
    new Promise<T>((resolve, reject) => {
      setTimeout(()=>{
        reject(new Error(`Timeout "${callId}": ${timeout}ms`));
      }, timeout);
    }),
  ]);
}

export interface WithRetryProps {
  maxCalls?: number,
  retryTimeout?: number,
  name?: string,
}

export function withRetry<T>(
  fn: (...args: any[])=>Promise<T>,
  props?: WithRetryProps
): (...args: any[])=>Promise<T> {

  const maxCalls = props?.maxCalls && props.maxCalls > 1? props.maxCalls : 1;
  const timeout = props?.retryTimeout || 2000;
  const name = props?.name || '';

  return async (...args: any[]) => {

    let call = 0;
    let lastError;

    while(call < maxCalls){
      try {
        return await fn(...args);
      } catch (e){
        call++;
        if(call < maxCalls){
          log.warn(`Cannot invoke [${name}]. Retry after ${timeout}...`);
          await Util.timeout(timeout);
        }
        lastError = e;
      }
    }

    // no more retries
    throw lastError;
  }
}
