import { createReducer, createStateHandlers } from '@/utils/redux/reducer-util';
import { Action } from '@/model/actions';
import { initialState, SwapState as S } from '@/model/swap/types';

const { p2f } = createStateHandlers<S>();

export const reducers = createReducer<S>(initialState, {

  // p2f<S['']>(''),

  [Action.swap.SetSelectedAlt.type]: p2f<S['selectedAlt']>('selectedAlt'),
  [Action.swap.support.SetSwapPathReq.type]: p2f<S['swapPathReq']>('swapPathReq'),
  [Action.swap.support.SetSwapPath.type]: p2f<S['swapPath']>('swapPath'),
  [Action.swap.support.SetRateState.type]: p2f<S['rateState']>('rateState'),

  [Action.swap.ResetRate.type](
    state: S
  ): S {
    return {
      ...initialState,
      selectedAlt: state.selectedAlt,
    };
  },


});

export default reducers;
