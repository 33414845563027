import { createReducer, createStateHandlers } from '@/utils/redux/reducer-util';
import { Action } from '@/model/actions';
import { initialState, EmailEnterState as S} from '@/model/emailEnter/types';

const { p2f } = createStateHandlers<S>();

export const reducers = createReducer<S>(initialState, {

  // p2f<S['']>(''),

  [Action.emailEnter.support.SetEmail.type]: p2f<S['email']>('email'),
  [Action.emailEnter.support.SetStep.type]: p2f<S['step']>('step'),
  [Action.emailEnter.support.SetLoading.type]: p2f<S['loading']>('loading'),
  [Action.emailEnter.support.SetErrorCode.type]: p2f<S['errorCode']>('errorCode'),

  [Action.emailEnter.Reset.type]():S {
    return {
      ...initialState
    }
  },

  [Action.wallet.Logout.type]():S {
    return {
      ...initialState
    }
  },

  [Action.torus.LoginByTokenError.type]():S {
    return {
      ...initialState
    }
  },

});

export default reducers;
