import { createReducer, createStateHandlers } from '@/utils/redux/reducer-util';
import { Action } from '@/model/actions';
import { ActionWithPayload } from '@/utils/redux/action-creator';
import { initialState, OnRampOperation, OnRampReq, OnRampShowMode, OnRampState as S} from './types';

const { p2f } = createStateHandlers<S>();


export const reducers = createReducer<S>(initialState, {

  // p2f<S['']>(''),

  [Action.onRamp.support.SetLastProcessingOperation.type]: p2f<S['lastProcessingOperation']>('lastProcessingOperation'),
  [Action.onRamp.support.SetFrameUrl.type]: p2f<S['frameUrl']>('frameUrl'),
  [Action.onRamp.support.SetCryptoAmount.type]: p2f<S['cryptoAmount']>('cryptoAmount'),
  [Action.onRamp.support.SetCryptoCurrency.type]: p2f<S['cryptoCurrency']>('cryptoCurrency'),
  [Action.onRamp.support.SetBaseAmount.type]: p2f<S['baseAmount']>('baseAmount'),
  [Action.onRamp.support.SetBaseCurrency.type]: p2f<S['baseCurrency']>('baseCurrency'),
  [Action.onRamp.support.SetStatus.type]: p2f<S['status']>('status'),

  [Action.onRamp.Reset.type](
    state: S
  ): S {

    return {
      ...initialState,
      lastProcessingOperation: state.lastProcessingOperation,
    };
  },

  [Action.onRamp.SetOperationStatus.type](
    state: S, { payload }: ActionWithPayload<OnRampOperation|undefined>
  ): S {

    // skip same operation status
    const oldOperation = state.operation;
    if(oldOperation
      && payload
      && oldOperation.id === payload.id
      && oldOperation.status === payload.status) {
      return state;
    }

    return {
      ...state,
      operation: payload,
    };
  },

  [Action.onRamp.SetProvider.type](
    state: S, { payload }: ActionWithPayload<OnRampShowMode>
  ): S {

    return {
      ...state,
      provider: payload.provider,
      showMode: payload.mode,
    };
  },

  [Action.onRamp.Start.type](
    state: S, { payload }: ActionWithPayload<OnRampReq>
  ): S {

    return {
      ...state,
      status: 'Preparing',
      req: payload,
    };
  },
});

export default reducers;
