import { StringUtil } from '@/utils/string-util';
import { Util } from '@/utils/util';

declare global {
  interface Window {
    ENV: any;
  }
}

const env = window.ENV || {};
const serverUrl = process.env.REACT_APP_SERVER_URL || env.REACT_APP_SERVER_URL || 'https://ncps-api.dev.mnxsc.tech';

export type Environment = 'local' | 'dev' | 'staging' | 'prod';

const environmentMap: Record<Environment, boolean> = {
  local: true,
  dev: true,
  staging: true,
  prod: true,
}

let envParam = process.env.REACT_APP_ENVIRONMENT_NAME || env.ENVIRONMENT_NAME;
if(envParam === 'sp-prod')
  envParam = 'prod';

const envVal = Util.parseStringType<Environment>(environmentMap, envParam, 'dev');

export type Toggles = Record<ToggleKey, boolean>;

export type ToggleKey =
  | 'moonpay-show'
  | 'moonpay-priority'
  | 'instaramp-show'
  | 'instaramp-priority'
  | 'instaramp-safari-special'
  | 'instaramp-show-help'
  | 'login-by-gmail'
  | 'login-by-mail'
  | 'login-by-metamask'
  | 'login-by-binance-wallet'
  | 'maintenance-mode'
  | 'show-alts-inner-wallet'
  | 'show-alts-external-wallet'
  | 'show-select-deposit-type';

export const ToggleDefVal: Record<ToggleKey, boolean> = {

  // before on-ramp
  'show-select-deposit-type': false,

  // moonpay
  'moonpay-show': true,
  'moonpay-priority': false,

  // instaramp
  'instaramp-show': true,
  'instaramp-priority': true,
  'instaramp-show-help': true,
  'instaramp-safari-special':  false,

  // login
  'login-by-gmail': true,
  'login-by-mail': true,
  'login-by-metamask': true,
  'login-by-binance-wallet': true,

  // show alt coins
  'show-alts-inner-wallet': false,
  'show-alts-external-wallet': false,

  // other
  'maintenance-mode': false,
};

export const Config = {
  Environment: envVal,
  IsProd: envVal === 'prod',
  Version: env.VERSION || '-',
  ServerUrl:  StringUtil.removeEnd(serverUrl, '/'),
  FetchTimeout: env.REACT_APP_FETCH_TIMEOUT || 30000,
  SupportEmail: env.REACT_SUPPORT_EMAIL || process.env.REACT_SUPPORT_EMAIL || 'support@smartypay.io',
  toggles: {
    ...ToggleDefVal
  },
  wallet: {
    SetDelayedReloadTimeout: 10000
  },
};
