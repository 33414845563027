import TorusSdk, { Auth0ClientOptions, LOGIN_TYPE } from '@toruslabs/torus-direct-web-sdk';
import { DirectWebSDKArgs, TorusLoginResponse } from '@toruslabs/torus-direct-web-sdk/types/src/handlers/interfaces';
import { TorusLoginType } from '@/model/torus/types';
import { Config, Environment } from '@/config';
import { Util } from '@/utils/util';
import { Lang } from '@/model/common/Lang';

const log = Util.getLog('torus');

interface TorusCred {
  clientId: string,
  verifier: string,
  typeOfLogin: LOGIN_TYPE,
  jwtParams: Auth0ClientOptions,
}


const testCred: Record<TorusLoginType, TorusCred> = {
  google: {
    clientId: '317903361145-bvpsegm8mu6nd0ovpsl0sluktn13ahab.apps.googleusercontent.com',
    verifier: 'smarty-pay-v1-dev',
    typeOfLogin: 'google',
    jwtParams: {} as Auth0ClientOptions,
  },
  email: {
    clientId: '7TuxmG8ET7L3M0m4y0uNK4qYukQ1LtKp',
    verifier: 'smarty-pay-v1-dev-auth0',
    typeOfLogin: 'jwt',
    jwtParams: {
      domain: 'https://sp-dev.eu.auth0.com',
      verifierIdField: 'email',
      isVerifierIdCaseSensitive: false,
    }
  },
}

const prodCred: Record<TorusLoginType, TorusCred> = {
  google: {
    clientId: '437286239451-rgacv3jbq35agif5mad4qfl41mkpcvjm.apps.googleusercontent.com',
    verifier: 'smarty-pay-v1-prod',
    typeOfLogin: 'google',
    jwtParams: {} as Auth0ClientOptions,
  },
  email: {
    clientId: 'MCICdnL6LFPIfpUktP5GNuecMfmCGGLo',
    verifier: 'smarty-pay-v1-prod-auth0',
    typeOfLogin: 'jwt',
    jwtParams: {
      domain: 'https://sp-prod.eu.auth0.com',
      verifierIdField: 'email',
      isVerifierIdCaseSensitive: false,
    }
  },
}


const TorusCreds: Record<Environment, Record<TorusLoginType, TorusCred>> = {
  prod: prodCred,
  staging: testCred,
  dev: testCred,
  local: testCred,
}

const TorusTestNet: Record<Environment, boolean> = {
  prod: false,
  staging: true,
  dev: true,
  local: true,
}


export interface InitTorusParams {
  testnet: boolean
}

let torus: TorusSdk;

export async function initTorus(){

  if(torus)
    return;

  const params: DirectWebSDKArgs = {
    baseUrl: `${window.location.origin}/serviceworker`,
    enableLogging: false,
    network: 'mainnet',
  }

  const isTestNet = TorusTestNet[Config.Environment];
  if(isTestNet){
    params.enableLogging = true;
    params.network = 'testnet';
  }

  const torusInst = new TorusSdk(params);
  await torusInst.init({ skipSw: false });

  log.info('torus initiated');
  torus = torusInst;
}

export async function queryStartLogin(loginType: TorusLoginType, lang: Lang): Promise<TorusLoginResponse>{

  // try to init again if it was an error before
  if(!torus)
    await initTorus();

  const cred = TorusCreds[Config.Environment]
  const { typeOfLogin, clientId, verifier, jwtParams} = cred[loginType];
  const loginDetails = await torus.triggerLogin({
    typeOfLogin,
    verifier,
    clientId,
    jwtParams: {
      ...jwtParams,
      ui_locales: lang,
    },
  });

  return loginDetails;
}

export async function queryLoginByToken(
  loginType: TorusLoginType,
  email: string,
  token: string
): Promise<TorusLoginResponse> {

  // try to init again if it was an error before
  if(!torus)
    await initTorus();

  const cred = TorusCreds[Config.Environment]
  const { verifier } = cred[loginType];
  const verifierId = email;
  const torusKey = await torus.getTorusKey(verifier, verifierId, {verifier_id: verifierId},  token);

  return {
    ...torusKey,
    userInfo: {
      idToken: token
    } as any
  };
}
