import { Net } from '@/utils/axios';
import { Config, Environment } from '@/config';
import { getAuthorizationHeader } from '@/api/auth';

const localUrlSuffix1 = 'one';
const localUrlSuffix2 = 'ta.ru';

export const InstarampUrls: Record<Environment, string> = {
  prod:     'https://instaramp.com',
  staging:  'https://demo.instaramp.com',
  dev:      'https://demo.instaramp.com',
  // hide "m o n e t a" in text
  local:    `https://mtrade-dev-app.service.local.m${localUrlSuffix1}${localUrlSuffix2}`,
}


export type InstarampLang = 'RU' | 'EN';

export interface SignDataReq {
  partnerId: string;
  currencyPair: string;
  cryptoAmount: string;
  recipientAddress: string;
  lang: InstarampLang;
  transactionId?: string; // for instaramp api 1.3.5
}

export interface SignDataResp {
  signature: string;
  email: string;
}


export type InstarampCallbackStatus = 'Succeed' | 'Failed';

// for tests only!
export interface InstarampCallbackData {
  id: string,
  invoiceId: string,
  status: InstarampCallbackStatus,
  walletAddress: string,
  cryptoAmount: number,
  code: string,
}

export async function querySignParams(req: SignDataReq): Promise<SignDataResp> {
  const authHeader = await getAuthorizationHeader();
  const resp: SignDataResp = await Net.post('/instaramp/sign-params',
    req,
    {
    headers: {
      ...authHeader,
    }
  });

  return resp;
}

// for tests only
export async function querySendInstarampCallback(req: InstarampCallbackData): Promise<any> {
  return Net.post('/instaramp/callback', req);
}

export async function queryInstarampLogout(): Promise<any> {
  return Net.get(`${InstarampUrls[Config.Environment]}/api/user/logout`, {
    timeout: 3000
  });
}
