import { createBrowserHistory } from 'history';
import { Action, applyMiddleware, createStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';

import { RootState } from '@/model/types';
import { rootReducer, rootSaga } from '@/model';

export const history = createBrowserHistory();

const dev = false//process.env.NODE_ENV === 'development';
const sagaMiddleware = createSagaMiddleware();

let middleware = dev ? applyMiddleware(sagaMiddleware, createLogger()) : applyMiddleware(sagaMiddleware);

if (dev) {
  middleware = composeWithDevTools(middleware);
}

export const rootStore: Store<RootState, Action> = createStore(rootReducer(), middleware);
sagaMiddleware.run(rootSaga);
