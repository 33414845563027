import Web3 from 'web3';
import { WalletAssets } from '@/model/wallet/assets/types';
import { Erc20ABI } from '@/api/abi';
import { toHexString } from '@/utils/crypto-util';
import {
  ContractTxInfo,
  DefaultCheckStep,
  DefaultWaitConfirmations, DefaultWaitOnZeroConfirmsTimeout,
  DefaultWaitTxInfo,
  TxInfo,
  TxInfoReq,
} from '@/model/common/Blockchain';
import { Util } from '@/utils/util';
import { EmptyTokenId, TokenDef, Tokens, TokenState } from '@/model/common/Assets';
import { Network, networkValue, unknownNetwork } from '@/model/common/Network';
import {
  BaseBlockchainApi,
  ProcessingInfo,
  ProcessingVersion,
  StubAddress,
  Web3CommonTimeout, Web3Urls,
} from '@/api/blockchain/common';
import { withErrLog, withTimeout } from '@/utils/promise';
import { DynamicHttpProviderHandler, DynamicHttpProvider } from '@/utils/web3/DynamicHttpProvider';
import { Config } from '@/config';
import { addAuthListener, getAuthorizationHeader, hasAuthToken } from '@/api/auth';

const log = Util.getLog('blockchain/binance');
const bnbToken = Tokens.getCurrencyToken('bBNB');

const processingTest: Record<ProcessingVersion, ProcessingInfo> = {
  10: {
    processing: '0xa298b8ac361D4C0FB4aAB38B5AaE7966029A06A9',
    router: '0x70610215E89448a6719b65d710Cb705B007269a5',
  },
  9: {
    processing: '0x3f545C695570149e803AbF008fa22385D9626aD3',
    router: '0x6eC534917B9210c4393fc3b6B710e4E4114381b9',
  },
  7: {
    processing: StubAddress,
    router: StubAddress,
  },
  6: {
    processing: '0x855daC252F2C107665C4F8D4362539222b2ED7Dd',
    router: '0x5B795dE9A1Db7C683C749d2e179dffc64aD74372',
  },
}
const processingMain: Record<ProcessingVersion, ProcessingInfo> = {
  10: {
    processing: '0x25bcb1744D0Abe04d8014CeF5D6B24Cc8085D4eb',
    router: '0xad149CeAacA3aEb8FfA81D8eFDf699C9516d73b9',
  },
  9: {
    processing: '0x9c8d936d20D4C3127AbD142F2D4ED1b4201E9F0c',
    router: '0x3D2A72A8C712825Bb203c70E60b767e1f8273fBA',
  },
  7: {
    processing: '0xEf748EEB7971c17a6c1334C44f6698f447E4df56',
    router: '0x352b569eaf743581fA99C0cF6d57420923A68eC5',
  },
  6: {
    processing: StubAddress,
    router: StubAddress,
  },
}

export const DappProcessingVersion: Record<Network, ProcessingVersion> = {
  BinanceTestNet: 10,
  BinanceMainNet: 10,
}

const testChainId = '0x61' // 97
const mainChainId = '0x38' // 56

const staticApis: {
  binanceMain?: Web3,
  binanceTest?: Web3,
} = {};


const defaultWeb3Urls: Web3Urls = {
  mainPublic: 'https://bsc-dataseed1.binance.org',
  testPublic: 'https://data-seed-prebsc-1-s1.binance.org:8545',
  canUseMainProxy: false,
  canUseTestProxy: false,
}

let web3Urls: Web3Urls = {
  ...defaultWeb3Urls
};

addAuthListener({
  onAuthUpdated(){
    const hasAuth = hasAuthToken();
    const useProxy = web3Urls.canUseTestProxy || web3Urls.canUseMainProxy;

    // reset invalid proxy providers if auth is gone
    if( ! hasAuth && useProxy){
      clearWeb3StaticCache();
    }
  }
});

export function setBinanceWeb3Urls(urls: Web3Urls){
  log.info('set web3 urls', urls);
  web3Urls = {
    ...urls
  }
}

export function getBinancePublicRpc(network: Network): string {

  if(network === 'BinanceMainNet')
    return web3Urls.mainPublic;

  if(network === 'BinanceTestNet')
    return web3Urls.testPublic;

  throw new Error(`unknown network: ${network}`);
}

const providerHandler: DynamicHttpProviderHandler = {
  async getRequestHeaders() {
    return (await getAuthorizationHeader()) as Record<string, string>;
  }
};


function getWeb3Static(network: Network): Web3 {

  if(network === 'BinanceMainNet'){
    if( ! staticApis.binanceMain) {

      const hasAuth = hasAuthToken();

      // can use proxy
      if( hasAuth && web3Urls.canUseMainProxy){
        const proxyUrl = `${Config.ServerUrl}/bsc/rpc/main`;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        staticApis.binanceMain = new Web3(new DynamicHttpProvider(proxyUrl, providerHandler));
      }
      // use public url
      else {
        staticApis.binanceMain = new Web3(web3Urls.mainPublic);
      }
    }
    return staticApis.binanceMain;
  }

  if(network === 'BinanceTestNet'){
    if( ! staticApis.binanceTest) {

      const hasAuth = hasAuthToken();

      // can use proxy
      if( hasAuth && web3Urls.canUseTestProxy){
        const proxyUrl = `${Config.ServerUrl}/bsc/rpc/test`;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        staticApis.binanceTest = new Web3(new DynamicHttpProvider(proxyUrl, providerHandler));
      }
      // use public url
      else {
        staticApis.binanceTest = new Web3(web3Urls.testPublic);
      }
    }
    return staticApis.binanceTest;
  }

  throw unknownNetwork(network);
}

function clearWeb3StaticCache(){
  log.info('clear web3 static cache');
  staticApis.binanceMain = undefined;
  staticApis.binanceTest = undefined;
}


async function getTxInfo(
  waitContractAddress: boolean,
  {
    network,
    txId,
    waitTimeout = DefaultWaitTxInfo,
    waitConfirmations = DefaultWaitConfirmations,
    waitOnZeroConfirmsTimeout = DefaultWaitOnZeroConfirmsTimeout,
    checkStep = DefaultCheckStep,
  }: TxInfoReq
): Promise<ContractTxInfo>{

  const web3 = getWeb3Static(network);

  const timeoutTime = Date.now() + waitTimeout;
  const zeroConfirmsTimeoutTime = Date.now() + waitOnZeroConfirmsTimeout;

  let cachedReceipt: any;
  let cachedTx: any;

  let confirmations = 0;

  // check until timeout
  while(Date.now() < timeoutTime){

    // node is probably down, so no need to wait more
    if(confirmations === 0 && Date.now() > zeroConfirmsTimeoutTime){
      log.warn('zero confirms timeout', {txId});
      break;
    }

    try {

      const [trx, receipt] = await Promise.all([
        cachedTx? Promise.resolve(cachedTx) : web3.eth.getTransaction(txId),
        cachedReceipt? Promise.resolve(cachedReceipt) : web3.eth.getTransactionReceipt(txId),
      ]);

      if(!trx || !receipt)
        throw new Error('Transaction not found');

      if( ! waitContractAddress || receipt.contractAddress) {
        // no need to get receipt again (because "status" field won't change)
        cachedReceipt = receipt;
      }

      // no need to get tx again (because "blockNumber" field won't change)
      if(trx.blockNumber && trx.blockNumber > 0){
        cachedTx = trx;
      }

      const currentBlock = await web3.eth.getBlockNumber();
      const success = receipt.status;
      confirmations = trx.blockNumber === null ? 0 : currentBlock - trx.blockNumber;

      if( !success || confirmations >= waitConfirmations){

        const level = success? 'info' : 'error';
        const revertReason = success? undefined : await getRevertReason(web3, txId);
        const txMsg = success? 'success tx' : 'error tx'
        log[level](txMsg, {
          txId,
          success,
          ...(success? {} : {revertReason, receipt, trx}),
          confirmations,
          ...(receipt.contractAddress?
            {contract: receipt.contractAddress}
            : {}),
        });

        return {
          confirmations,
          success,
          contractAddress: receipt.contractAddress,
          ...(revertReason? {error: revertReason} : {}),
        };
      }

    } catch (e: any){

      const msg = e.message || e.toString();

      // unexpected error
      if(msg !== 'Transaction not found'
        && msg !== 'receipt is null'
        && msg !== 'Network Error'
        && msg !== 'Request aborted') {
        throw e
      }
      else {
        log.warn('error on get tx info', {e});
      }
    }

    // wait for next call
    log.info('wait more confirmations for tx...', {confirmations, checkStep, txId});
    await Util.timeout(checkStep);
  }

  // timeout error
  return {
    success: false,
    error: 'timeout',
    confirmations,
    isTimeout: true,
  }
}


// from https://ethereum.stackexchange.com/a/84549
async function getRevertReason(web3: Web3, txHash: string) {

  try {
    const tx: any = await web3.eth.getTransaction(txHash);
    const result = await web3.eth.call(tx, tx.blockNumber);
    if(!result)
      return 'empty reason';

    const data = result.startsWith('0x') ? result : `0x${result}`;
    if (data.substr(138)) {
      return web3.utils.toAscii(data.substr(138));
    } else {
      return `unknown encoded data: ${result}`;
    }
  } catch (e: any){

    const reasonPrefix = 'Returned error:';
    const msg: string|undefined = e.message;
    if(msg && msg.startsWith(reasonPrefix))
      return msg.substr(reasonPrefix.length).trim();

    log.error('cannot get tx revert reason', {e});
    return 'unknown';
  }
}


export interface DataToSign {
  nonce: string,
  to: string,
  from: string,
  data: string,
  gas: string,
  gasPrice: string,
  chainId: string,
}

interface BinanceApi extends BaseBlockchainApi {

  prepareToSign(
    network: Network,
    contractId: string,
    abi: any,
    from: string,
    getMethodCall: (methods: any)=> any,
  ): Promise<DataToSign>,

  findChainId(network: Network): string|undefined,

  web3(network: Network): Web3,
}

export const Binance: BinanceApi = {

  web3(network): Web3 {
    return getWeb3Static(network);
  },

  findChainId(network: Network): string|undefined {
    if(network === 'BinanceMainNet')
      return mainChainId;
    if(network === 'BinanceTestNet')
      return testChainId;
    return undefined;
  },

  findNetwork(chainId: string | undefined): Network | undefined {
    if(chainId === mainChainId)
      return 'BinanceMainNet';
    if(chainId === testChainId)
      return 'BinanceTestNet';
    return undefined;
  },

  privateKeyToAccount(network: Network, privateKey: string) {
    const web3 = getWeb3Static(network);
    const account = web3.eth.accounts.privateKeyToAccount(privateKey);
    return account.address;
  },

  processingInfo(network: Network, version: ProcessingVersion){
    return networkValue(network, {
      BinanceMainNet: processingMain[version],
      BinanceTestNet: processingTest[version],
    });
  },

  async getAllowance(
    network: Network,
    tokenId: string,
    ownerAddress: string,
    spenderAddress: string,
  ): Promise<number> {

    const web3 = getWeb3Static(network);

    const contract = new web3.eth.Contract(Erc20ABI, tokenId);
    const allowance = await contract.methods.allowance(ownerAddress, spenderAddress).call();
    const allowanceVal = Number.parseInt(allowance, 10) || 0;

    return allowanceVal;
  },

  getTxUrl(txId: string, network: Network){
    return networkValue(network, {
      BinanceMainNet: `https://bscscan.com/tx/${txId}`,
      BinanceTestNet: `https://testnet.bscscan.com/tx/${txId}`,
    });
  },

  getAddressUrl(address: string, network: Network) {
    return networkValue(network, {
      BinanceMainNet: `https://bscscan.com/address/${address}`,
      BinanceTestNet: `https://testnet.bscscan.com/address/${address}`,
    });
  },

  async getWalletAssets(
    network: Network,
    address: string,
    targetAssets: TokenDef[]
  ): Promise<WalletAssets>{

    return withTimeout({
      callId: `getWalletAssets ${network} ${address}`,
      timeout: Web3CommonTimeout,
    }, async ()=>{

      const web3 = getWeb3Static(network);
      const requests = targetAssets.map(asset => {
        if(asset.tokenId !== EmptyTokenId){
          const contract = new web3.eth.Contract(Erc20ABI, asset.tokenId);
          return (contract.methods.balanceOf(address).call() as Promise<any>).catch(e => {
            log.error('cannot get balance for token', address, e);
            return 0;
          });
        } else {
          return Promise.resolve(0);
        }
      })

      const mainBalance = parseFloat(await web3.eth.getBalance(address));
      const assetsBalances = await Promise.all(requests);

      const assets: TokenState[] = [];

      targetAssets.forEach((assetDef, index)=>{

        const balance = assetsBalances[index];

        assets.push({
          ...assetDef,
          balance,
          network,
        })
      });

      return {
        mainAsset: {
          ...bnbToken,
          balance: mainBalance,
        },
        otherAssets: assets,
      }
    });
  },


  async prepareToSign(
    network: Network,
    contractId: string,
    abi: any,
    from: string,
    getMethodCall: (methods: any)=> any,
  ): Promise<DataToSign> {

    const web3 = getWeb3Static(network);

    const contract = new web3.eth.Contract(abi, contractId);
    const method = getMethodCall(contract.methods);
    const data = method.encodeABI();
    const errLogData =  {from, network, contractId, data};

    const [gasLimit, nonce, gasPrice] = await Promise.all([
      withErrLog<number>(method.estimateGas({from}), 'cannot estimateGas', errLogData),
      withErrLog(web3.eth.getTransactionCount(from), 'cannot getNonce', errLogData),
      withErrLog(web3.eth.getGasPrice(), 'cannot getGasPrice', errLogData),
    ]);

    const chainId = networkValue(network, {
      BinanceMainNet: mainChainId,
      BinanceTestNet: testChainId,
    });

    log.info('data to sign', {
      data,
      from,
      chainId,
      contractId,
      nonce,
      gasLimit,
      gasPrice
    });

    return {
      data,
      from,
      chainId,
      nonce: toHexString(nonce),
      to: contractId,
      gas: toHexString(gasLimit),
      gasPrice: toHexString(parseFloat(gasPrice)),
    };
  },

  async getTxInfo(req: TxInfoReq): Promise<TxInfo>{
    return getTxInfo(false, req);
  },

  getContactTxInfo(req: TxInfoReq): Promise<ContractTxInfo> {
    return getTxInfo(true, req);
  },

  async hasContract(network: Network, address: string) {
    const web3 = getWeb3Static(network);
    const code = await web3.eth.getCode(address);
    return !!code && code !== '0x';
  }
}






